if ($('#modelContacts').length){
  $('#modelContactsLi').addClass('current-page');

$('.ajax_send_model_question').click(function() {
    var preloader = $('.preloader');
    preloader.fadeIn();

    var questionTo = $('.select-departament option:selected').val();
    var question   = $('.ajax_model_question').val();

    if (question === '') {
      $.toast({
        heading:            '',
        text:               'Введите вопрос',
        showHideTransition: 'fade',
        icon:               'error'
      });

      preloader.fadeOut('slow', function() {});

      return false;
    }

    var data = new FormData();
    data.append('questionTo', questionTo);
    data.append('question', question);

    $.ajax({
      url         : '/user/ajaxaddnewmodelquestion',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      processData : false,
      contentType : false,
      success     : function(respond) {
        if (typeof respond.error === 'undefined') {
          $('.preloader').fadeOut('slow', function() {});
          $.toast({
            heading:            'Success',
            text:               'Вопрос успешно отправлен',
            showHideTransition: 'slide',
            icon:               'success'
          });
        } else {
          console.log('ОШИБКА: ' + respond.error);
        }
      },
      error: function(jqXHR, status) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      }
    });
});
}
