console.log("Script loaded");
if ($("#superuserFinance").length){
console.log("SuperuserFinance found");





  function showBeganIntervalDate() {
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const beganOfPeriod = $("#period-select-date-styler .sel").text();
    const beganIntervalDay = beganOfPeriod.split('-');
    const beganIntervalMonth = beganOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    } else {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
      $(".began-period").css({'display': 'block', 'margin-bottom': '5px'});
    }
  }

  function showEndIntervalDate(){
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const endOfPeriod = $("#period-select-date-second-styler .sel").text();
    const endIntervalDay = endOfPeriod.split('-');
    const endIntervalMonth = endOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
    } else {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
    }
  }

  $("#period-inteval").change(function(){
    if ($("#period-inteval").prop("checked")){
      $(".superuser-finance__period-select-second").css("display", "flex");
      $(".superuser-finance__period-select").css("height", "140px");
      $(".end-period").css("display", "inline-block");
      $(".superuser-finance__top").css("height", "200px");
      showBeganIntervalDate();
      showEndIntervalDate();
    }else {
      $(".superuser-finance__period-select-second").css("display", "none");
      $(".superuser-finance__period-select").css("height", "81px");
      $(".end-period").css("display", "none");
      $(".superuser-finance__top").css("height", "140px");
    }
  });

  $("#period-select-date").change(function(){
    if ($("#period-inteval").prop("checked")){
      showBeganIntervalDate();
    } else {
      const beganOfPeriod = $("#period-select-date-styler .sel").text();
      $(".began-period").text(beganOfPeriod + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    }
  });

  $("#period-select-date-second").change(function(){
    showEndIntervalDate();
  });

  $('.superuser-finance__period-select-year').change(function(){
    showBeganIntervalDate();
    showEndIntervalDate();
  });

  $('#superuserFinanceLi').addClass('current-page');




  $(".superuser-finance__plan-btn").click(function(){
    $(".modal-box.show-plan").show();
  });

  $(".superuser-finance__csv").click(function(){
    if ($('#period-inteval').is(':checked')){
        $.toast({
          heading: '',
          text: 'CSV файл формируется за 2 недельный период, выберите период',
          showHideTransition: 'fade',
          icon: 'error'
        });
    }else {
      $(".modal-box.show-csv").show();
    }
  });

  function clickDownload() {
    $("#click_me_download_zip").ек();
  }

  $('.ajax_create_csv_file').click(function() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var day = currentDate.getDate().toString().padStart(2, '0');
    var lastDay = new Date(year, currentDate.getMonth() + 1, 0).getDate();
    
    // Если мы в первой половине месяца
    var from, to;
    if (day <= 15) {
        from = `${year}-${month}-01`;
        to = `${year}-${month}-15`;
    } else {
        from = `${year}-${month}-16`;
        to = `${year}-${month}-${lastDay}`;
    }
    
    var data = new FormData();
    data.append( 'from', from );
    data.append( 'to', to );

    $.ajax({
      url         : '/superuser/createcsvfile',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      processData : false,
      contentType : false,
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
            respond = '/'+respond;
            $('#click_me_download_zip').prop("href", respond).trigger( "click" );
            window.location.replace(respond);
        } else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      }
    });
  });


  $(".superuser-finance__course").click(function(){
    $(".modal-box.show-course").show();
  });


  $(".superuser-finance__add-pay").click(function(){
    $(".modal-box.show-pay-add").show();
  });

  $('.ajax_add_model_payment').click(function(){
    let modelId = Number($('.ajax_add_payment_model_id').val());
    if (!modelId) {
        $.toast({
            heading: '',
            text: 'Введите ID модели',
            showHideTransition: 'fade',
            icon: 'error'
        });
        return false;
    }

    let payBonPen = $('#select-period-reason-payment').val();
    let date = '';
    let reason = '';
    
    // В зависимости от типа собираем данные
    if (payBonPen == 'bonus' || payBonPen == 'penalty' || payBonPen == 'service') {
        date = $('.ajax_add_payment_date').val(); 
        reason = $('.ajax_add_payment_reason').val();
        if (date == '' || reason == '') {
            $.toast({
              heading: '',
              text: 'Заполните все поля',
              showHideTransition: 'fade',
              icon: 'error'
            });
            return false;
        }
    }
    if (payBonPen == 'payment') {
        reason = $('.ajax_add_payment_date').val();
        if (reason == '') {
            $.toast({
              heading: '',
              text: 'Укажите дату',
              showHideTransition: 'fade',
              icon: 'error'
            });
            return false;
        }
    }
    
    var sum = Number($('.ajax_add_payment_sum').val());
    if (!sum) {
        $.toast({
          heading: '',
          text: 'Укажите сумму',
          showHideTransition: 'fade',
          icon: 'error'
        });
        return false;
    }

    // Определяем период - берем только текущий месяц
    let now = new Date();
    let from = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-01`;
    let to = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()).padStart(2, '0')}`;
    
    var data = new FormData();
    data.append('payBonPen', payBonPen);
    data.append('reason', reason); 
    data.append('date', date);
    data.append('modelId', modelId); 
    data.append('sum', sum);
    data.append('from', from);
    data.append('to', to);

    $.ajax({
      url: '/superuser/ajaxcreatenewpayment',
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: function(respond, status, jqXHR) {
        if(typeof respond.error === 'undefined') {
          $.toast({
            heading: 'Success',
            text: 'Выплата успешно добавлена',
            showHideTransition: 'slide',
            icon: 'success'
          });
          $('.close-modal').trigger('click');
        } else {
          console.log('ОШИБКА: ' + respond.error);
        }
      },
      error: function(jqXHR, status, errorThrown) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      }
    });
  });
  $(".modal-lvl-pay-edit").click(function(){
    if ($(".lvl-pay__table tr.active").length){
      $(".lvl-pay__table tr.active").addClass("edit");
      $(".lvl-pay__table tr.active").prop("contenteditable", true);
    }
  });

   $(".ajax_save_exchange_rate").click(function(){
    var value = $('.ajax_save_exchange_value').val();
    var data = new FormData();
    data.append( 'value', value );

    //console.log(typeof value);

    $.ajax({
      url         : '/superuser/ajaxchangeexchangerate',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
           $('.modal-course-now').text('1$ = ' + value +'р');
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      }

      });
  });


  $(".modal-lvl-pay-delete").click(function(){
    if ($(".lvl-pay__table tr.active").length){
      var level = $('.lvl-pay__table tr.active').children('.ajax_send_data_level').html();
      var data = new FormData();
      data.append( 'level', level );

      $.ajax({
      url         : '/superuser/ajaxdeletepaylevel',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          $('.lvl-pay__table tr.active').remove();
          rowCount();
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Возможно удаление только последнего уровня',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }

      });
    }
  });


  $(".modal-lvl-pay-save").click(function(){
    if ($(".lvl-pay__table tr.active").length){
      var level = $('.lvl-pay__table tr.active').children('.ajax_send_data_level').html();
      var from = $('.lvl-pay__table tr.active').children('.ajax_send_data_from').html();
      var to = $('.lvl-pay__table tr.active').children('.ajax_send_data_to').html();
      var model_cef = $('.lvl-pay__table tr.active').children('.ajax_send_data_model_cef').html();
      var manager_cef = $('.lvl-pay__table tr.active').children('.ajax_send_data_manager_cef').html();

      var data = new FormData();
      data.append( 'level', level );
      data.append( 'amount_from', from );
      data.append( 'amount_to', to );
      data.append( 'model_cef', model_cef );
      data.append( 'manager_cef', manager_cef );


      $.ajax({
      url         : '/superuser/ajaxchangepaylevel',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          console.log(respond);
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      }

      });


      $(".lvl-pay__table tr.active").removeClass("edit");
      $(".lvl-pay__table tr.active").prop("contenteditable", false);
      $(".lvl-pay__table tr.active").removeClass("active");
    }
  });
  function rowCount(){
    $(".lvl-pay__table tbody tr").each(function(elem){
      $(this).find("td:first-child").text(elem + 1);
    });
  }
  rowCount();

  $(".modal-lvl-pay-add").click(function(){
    $(".lvl-pay__table tbody").append('<tr class="new-tr"><td class="ajax_send_data_level"></td><td class="ajax_send_data_from">-</td><td class="ajax_send_data_to">-</td><td class="ajax_send_data_model_cef">-</td><td class="ajax_send_data_manager_cef">-</td></tr>');
    rowCount();
  });

  $(".lvl-pay__table tr").click(function(){
    if ($(".lvl-pay__table tr").hasClass("edit")){
      return false;
    }else if ($(this).hasClass("active")){
      $(".lvl-pay__table tr").removeClass("active");
      $(this).removeClass("active");
    }else{
      $(".lvl-pay__table tr").removeClass("active");
      $(this).addClass("active");
    }
  });
  $('body').on('click', '.lvl-pay__table .new-tr', function(){
    if ($(".lvl-pay__table tr").hasClass("edit")){
      return false;
    }else if ($(this).hasClass("active")){
      $(".lvl-pay__table tr").removeClass("active");
      $(this).removeClass("active");
    }else{
      $(".lvl-pay__table tr").removeClass("active");
      $(this).addClass("active");
    }
  });
  $(".superuser-finance__lvl-pay").click(function(){
    $(".modal-box.lvl-pay").show();
  })


$('.ajax_add_model_payment').click(function(){
    let modelId = Number($('.ajax_add_payment_model_id').val());
    if (!modelId) {
        $.toast({
            heading: '',
            text: 'Введите ID модели',
            showHideTransition: 'fade',
            icon: 'error'
        });
        return false;
    }
    var period = $('#period-select-date option:selected').attr('name');
    var year = $('#select-period-year option:selected').val();
    var periodArr = period.split('.');
    var from = year +'-'+periodArr[0]+'-'+periodArr[1];
    var to = year +'-'+periodArr[0]+'-'+periodArr[2];
    //let modelPaidout = $(".superuser-finance__table-table tbody tr.active").find(".superuser-finance__table-paidout").text();
    //if (modelPaidout === '-') {
    //  modelPaidout = 0;
    //}
    let payBonPen = $('#select-period-reason-payment').val();
    let date = '';
    let reason = '';
//    let comment='';
    if (payBonPen == 'bonus' || payBonPen == 'penalty' || payBonPen == 'service') {
      date = $('.ajax_add_payment_date').val();
      reason = $('.ajax_add_payment_reason').val();
//	reason = $('.ajax_add_payment_date').val();
//      comment = $('.ajax_add_payment_reason').val();
      if (date == '' || reason == '') {
        $.toast({
          heading: '',
          text: 'Заполните все поля',
          showHideTransition: 'fade',
          icon: 'error'
        });
        return false;
      }
    }
    if (payBonPen == 'payment') {
      reason = $('.ajax_add_payment_date').val();
    }
    var sum = Number($('.ajax_add_payment_sum').val());//Number(modelPaidout) + Number($('.ajax_add_payment_sum').val());
    var data = new FormData();
    data.append( 'payBonPen', payBonPen );
    data.append( 'reason', reason );
    data.append( 'date', date );
    data.append( 'modelId', modelId );
    data.append( 'sum', sum );
    data.append( 'from', from );
    data.append( 'to', to );
  //  data.append( 'comment', comment);

    $.ajax({
    url         : '/superuser/ajaxcreatenewpayment',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        //console.log(respond);
        $.toast({
          heading: 'Success',
          text: 'Done',
          showHideTransition: 'slide',
          icon: 'success'
        });
//        if (payBonPen == 'bonus') {
//          sum = 0 - sum;
//        }

        	$('.close-modal').trigger('click');
      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });

});

$('.clear_payment_input').click(function(){
  $('.ajax_add_payment_sum').val('');
});

$('.ajax_update_database_payments').click(function() {
  var period = $('#period-select-date option:selected').attr('name');
  var year = $('#select-period-year option:selected').val();
  var periodArr = period.split('.');
  var from = year +'-'+periodArr[0]+'-'+periodArr[1];
  var to = year +'-'+periodArr[0]+'-'+periodArr[2];
  var data = new FormData();
  data.append( 'from', from );
  data.append( 'to', to );
  $.ajax({
    url         : '/superuser/ajaxupdatepaymentsdatabase',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        //console.log(respond);
        $.toast({
          heading: 'Success',
          text: 'Выплаты успешно добавлены',
          showHideTransition: 'slide',
          icon: 'success'
        });
        setTimeout(function(){
            window.location.reload();
          }, 3000);

      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });
});

$('.select-reason').on('change', function() {
  if (this.value ==  'bonus' || this.value == 'penalty' || this.value == 'service') {
    $('.bonus_or_penalty_wrap').show();
  }else {
    $('.bonus_or_penalty_wrap').hide();
  }
});

  $('.ajax_creata_new_model_plan_finance_page').click(function(){
  var id = $(".superuser-finance__table-table tbody tr.active").attr('name');
  var period = $('#period-select-date-plan option:selected').attr('name');
  var date_array = period.split('.');
  var date = date_array[0]+'-'+date_array[1];
  var cash = $('.ajax_set_plan_model_cash').val();
  var year = $('#select-period-year-second-plan option:selected').val();
  var data = new FormData();
  if (cash == '') {
    $.toast({
      heading: '',
      text: 'Введите план',
      showHideTransition: 'fade',
      icon: 'error'
    });
    return false;
  }
  data.append( 'id', id );
  data.append( 'date', date );
  data.append('cash', cash);
  data.append('year', year);
    $.ajax({
      url         : '/manager/ajaxsetmodelplanbyid',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          //console.log(respond);
          $.toast({
            heading: 'Success',
            text: 'План для модели успешно создан',
            showHideTransition: 'slide',
            icon: 'success'
          });
          setPlanOnClient(year, date, cash, respond);
          $('.model_plan_cash_value').text(cash);
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Модели с таким именем и фамилией не найдено',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });

});


}
