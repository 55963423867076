if ($("#firsttab").length){
  //show model name if checked pare
  function changeNameTitle(modelFirstName, modelSecondName, nameTitle, numberModel){
    if ((modelFirstName.val().length > 0) || (modelSecondName.val().length > 0)) {
      const nameFirstModel = modelFirstName.val();
      const secondNameFirstmodel = modelSecondName.val();
      nameTitle.html(nameFirstModel + " " + secondNameFirstmodel);
    } else nameTitle.html("Модель " + numberModel);
  }

  const firstModelFirstName = $("#firsttabform-first_model_first_name");
  const firstModelSecondName = $("#firsttabform-first_model_second_name");
  const secondModelFirstName = $("#firsttabform-second_model_first_name");
  const secondModelSecondName = $("#firsttabform-second_model_second_name");
  const firstModelBornData = $('#firsttabform-first_model_born_data');
  const secondModelBornData = $('#firsttabform-second_model_born_data');
  const isPair = $('#firsttabform-is_pair');
  const phoneNumber = $('#firsttabform-phone');
  const email = $('#firsttabform-email');
  const firstModelTitle = $(".name_first_model");
  const secondModelTitle = $(".name_second_model");
  const submitButton = $('.firsttab-btn-next');

  function showSecondModel() {
      $('.first_model_data').css({'width': '50%', 'padding-right' : '13px'});
      $('.second_model_data').css({'width': '50%', 'padding-left' : '13px'});
      $("#firsttabform-second_model_first_name").prop('required',true);
      $("#firsttabform-second_model_second_name").prop('required',true);
      $("#firsttabform-second_model_born_data").prop('required',true);
      $('.second_model_data').show();
      $(".model_data_wrap").addClass("model_pair");
      $("#login-form").css("margin-top", "4px");
      changeNameTitle(firstModelFirstName, firstModelSecondName, firstModelTitle, 1);
      changeNameTitle(secondModelFirstName, secondModelSecondName, secondModelTitle, 2);
  }

  function hideSecondModel() {
    $('.first_model_data').css({'width': '100%'});
    $("#firsttabform-second_model_first_name").prop('required',false);
    $("#firsttabform-second_model_second_name").prop('required',false);
    $("#firsttabform-second_model_born_data").prop('required',false);
    $(".model_data_wrap").removeClass("model_pair");
    $(".name_first_model").css("display", "none");
    $(".login_margin#login-form").css("margin-top", "-4px");
    $('.second_model_data').hide();
  }

  (function() {
    $('#firsttabform-is_pair').on('click', function(){
    const visible = $('.second_model_data').css('display');
      if (visible === 'none') {
        showSecondModel();
      }else {
        hideSecondModel();
      }
    });

    if ($('.pair-checked').length === 1) {
      showSecondModel();
      $(".field-firsttabform-is_pair input").prop("checked", true);
    }else {
      hideSecondModel();
      $(".field-firsttabform-is_pair input").prop("checked", false);
    }
  })();

  $("#firsttabform-first_model_first_name").change(function() {
    changeNameTitle(firstModelFirstName, firstModelSecondName, firstModelTitle, 1);
  });

  $("#firsttabform-first_model_second_name").change(function() {
    changeNameTitle(firstModelFirstName, firstModelSecondName, firstModelTitle, 1);
  });

  $("#firsttabform-second_model_first_name").change(function() {
    changeNameTitle(secondModelFirstName, secondModelSecondName, secondModelTitle, 2);
  });

  $("#firsttabform-second_model_second_name").change(function() {
    changeNameTitle(secondModelFirstName, secondModelSecondName, secondModelTitle, 2);
  });

  $(".single_box").click(function(){
    const blockWithRadio = $(this);
    $(".single_box input:radio").prop("checked", false);
    $(".single_box .jq-radio").removeClass("checked");
    $(blockWithRadio).find("input:radio").prop("checked", true);
    $(blockWithRadio).find(".jq-radio").addClass("checked");
  });

  $(".single_box-second-model").click(function(){
    const blockWithRadio = $(this);
    $(".single_box-second-model input:radio").prop("checked", false);
    $(".single_box-second-model .jq-radio").removeClass("checked");
    $(blockWithRadio).find("input:radio").prop("checked", true);
    $(blockWithRadio).find(".jq-radio").addClass("checked");
  });

  $(".model_data_wrap input").blur(function(){
    const input = $(this);
    const blockWithInput = $(this).parent().parent();
    const inputName = blockWithInput.find("label").text();
    const errorBlock = blockWithInput.find(".help-block-error");
    if(input.val().length === 0 || input.val() === "__.__.____"){
      input.addClass('error');
      errorBlock.text("Необходимо заполнить поле " + "\'" + inputName + "\'");
    } else {
      input.removeClass('error');
      errorBlock.text("");
    }
    errorBlock.css("msrgin-top", "-29px");
  });

  submitButton.click(() => {
    const preloader = $(".preloader");
    const form = $('#login-form');
    const isSecondModelDataFilled = secondModelFirstName.val() !== ''
      && secondModelSecondName.val() !== ''
      && secondModelBornData.val() !== '';
    const isAllFieldsFilled = firstModelFirstName.val() !== ''
      && firstModelSecondName.val() !== ''
      && firstModelBornData.val() !== ''
      && phoneNumber.val() !== ''
      && email.val() !== ''
      && (isPair[0].checked ? isSecondModelDataFilled : true);

    if (isAllFieldsFilled) {
      preloader.fadeIn();
    }
  });
}

