if ($('#managerDepartmentreport').length) {
    $(".constuctor-btn").click(function() {
        $(".superuser-managers__constructor-block").show();
    });
    $(".close-constructor-block").click(function() {
        $(".superuser-managers__constructor-block").hide();
    });

    $('#departmentReport').addClass('current-page');

    //manager period
    function showBeganIntervalDate() {
        const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
        const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

        const beganOfPeriod = $("#period-select-date-styler .sel").text();
        const beganIntervalDay = beganOfPeriod.split('-');
        const beganIntervalMonth = beganOfPeriod.slice(5);

        if (beganIntervalYear === endIntervalYear) {
            $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
            $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
        } else {
            $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
            $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
            $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
        }
    }

    function showEndIntervalDate(){
        const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
        const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

        const endOfPeriod = $("#period-select-date-second-styler .sel").text();
        const endIntervalDay = endOfPeriod.split('-');
        const endIntervalMonth = endOfPeriod.slice(5);

        if (beganIntervalYear === endIntervalYear) {
            $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
        } else {
            $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
        }
    }



    $("#period-inteval").change(function(){
        if ($("#period-inteval").prop("checked")){
            $(".superuser-managers__period-select-second").css("display", "flex");
            $(".superuser-managers__period-select").css("height", "140px");
            $(".end-period").css("display", "inline-block");
            $(".superuser-managers__top").css("height", "200px");
            showBeganIntervalDate();
            showEndIntervalDate();
        }else {
            $(".superuser-managers__period-select-second").css("display", "none");
            $(".superuser-managers__period-select").css("height", "81px");
            $(".end-period").css("display", "none");
            $(".superuser-managers__top").css("height", "140px");
        }
    });

    $("#period-select-date").change(function(){
        if ($("#period-inteval").prop("checked")){
            showBeganIntervalDate();
        } else {
            const beganOfPeriod = $("#period-select-date-styler .sel").text();
            $(".began-period").text(beganOfPeriod + "\u00A0");
            $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
        }
    });

    $("#period-select-date-second").change(function(){
        showEndIntervalDate();
    });

    $('.superuser-managers__period-select-year').change(function(){
        showBeganIntervalDate();
        showEndIntervalDate();
    });

    $(document).ready(setTimeout(function(){
        getAjaxDepartmentReportTribesData();
    }, 1000));

    $(".ajax_get_tribes_data").click(function(){
        getAjaxDepartmentReportTribesData();
    });

    function getAjaxDepartmentReportTribesData() {
        $(".preloader").fadeIn();
        if ($('#period-inteval').is(':checked')){
            var monthDayStart = $("#period-select-date option:selected").attr('name');
            var yearStart = $("#select-period-year option:selected").val();
            var array = monthDayStart.split('.');
            from = yearStart + '-' + array[0] + '-' + array[1];
            var monthDayFinish = $("#period-select-date-second option:selected").attr('name');
            var yearFinish = $("#select-period-year-second option:selected").val();
            var array = monthDayFinish.split('.');
            to = yearFinish + '-' + array[0] + '-' + array[2];
        } else {
            var monthDay = $("#period-select-date option:selected").attr('name');
            var year = $("#select-period-year option:selected").val();
            var array = monthDay.split('.');
            from = year + '-' + array[0] + '-' + array[1];
            to = year + '-' + array[0] + '-' + array[2];
        }
        var ids = [];
        var i =0;
        $('.get_tribe_id_here').each(function( index ) {
            ids[i] =  Number($( this ).attr('name'));
            i++;
        });
        var data = new FormData();
        data.append( 'from', from );
        data.append( 'to', to );
        data.append('ids', ids);
        $.ajax({
            url         : '/manager/ajaxgetdepartmentreporttribesdata',
            type        : 'POST', // важно!
            data        : data,
            cache       : false,
            dataType    : 'json',
            // отключаем обработку передаваемых данных, пусть передаются как есть
            processData : false,
            // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
            contentType : false,
            // функция успешного ответа сервера
            success     : function( respond, status, jqXHR ){
                $('.preloader').fadeOut('slow', function() {});
                if( typeof respond.error === 'undefined' ){
                    fillTribesTableForSuperuser(respond);
                }
                // ошибка
                else {
                    console.log('ОШИБКА: ' + respond.error );
                }
            },
            // функция ошибки ответа сервера
            error: function( jqXHR, status, errorThrown ){
                $('.preloader').fadeOut('slow', function() {});
                console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
                $.toast({
                    heading: '',
                    text: 'За данный период нет данных',
                    showHideTransition: 'fade',
                    icon: 'error'
                });

            }
        });
    }

    function fillTribesTableForSuperuser(respond) {
        let resultIndex = respond.length - 1;
        for (let index = 0; index <= resultIndex; index++) {
            let tr = (index !== resultIndex)
                ? $('#superuser-tribe-' + respond[index].id)
                : $('#superuser-tribes__total-line')
            ;

            tr.children('.superuser-tribes__table-all_models_amount').text(respond[index]['all_models_amount']);
            tr.children('.superuser-tribes__table-active_models_amount').text(respond[index]['active_models_amount']);
            tr.children('.superuser-tribes__table-all_models_online_hours').text(respond[index]['all_models_online_hours']);
            tr.children('.superuser-tribes__table-all_models_cash').text(respond[index]['all_models_cash'].toFixed(2));
            tr.children('.superuser-tribes__table-tribe_cash').text(respond[index]['tribe_cash'].toFixed(2));

            for (let kpi = 1; kpi <= 5; kpi++) {
                tr.children('.superuser-tribes__table-kpi' + kpi).text(respond[index]['kpi_' + kpi].toFixed(2));
            }
            tr.children('.superuser-tribes__table-kpi6').text(respond[index]['kpi_6']);
        }
    }
}
