function setElementVisibility(el, isVisible = true) {
  if (!el) {
    return;
  }

  el.style.display = isVisible ? 'block' : 'none';
}

function changeImageSource(img, src) {
  if (!img) {
    return;
  }

  img.src = src;
}

function getPhotoSourceByCountryAndDoctype(country, doc, slot) {
  const imageDir = '/public/img/';
  const countries = {
    ru: 'россия',
    ua: 'украина',
    by: 'беларусь',
    kz: 'казахстан'
  };

  const configuredDoctypes = {
    ru: {
      passport: 'passport',
      foreignPassport: 'foreign-passport',
      driverLicense: 'driver-license'
    },
    ua: {
      passport: 'passport',
      foreignPassport: 'foreign-passport',
      driverLicense: 'driver-license',
      identification: 'identification'
    },
    by: {
      passport: 'passport'
    },
    kz: {
      identification: 'identification'
    }
  };

  let countryCode;
  if (Object.values(countries).includes(country)) {
    countryCode = Object.keys(countries).find(key => countries[key] === country);

    return `${imageDir}doc_${countryCode}_${doc}_prev_${slot}.png`;
  }

  return `${imageDir}doc_ua_${doc}_prev_${slot}.png`;
}

$(document).ready(() => {
  if ($("#workcab").length) {

    $(".js-close-popup").click(function () {
      var popup = $(".popup");
      popup.hide();
      popup.parent().hide();
    });

    $("#open-manual-1").click(function () {
      $(".overlay-popup").css("display", "flex");
      $(".overlay-popup").css("height", "flex");
      $("#popup-manual-site-1").show();
    });
    $("#open-manual-2").click(function () {
      $("#popup-manual-site-2").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-3").click(function () {
      $("#popup-manual-site-3").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-4").click(function () {
      $("#popup-manual-site-4").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-5").click(function () {
      $("#popup-manual-site-5").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-6").click(function () {
      $("#popup-manual-site-6").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-7").click(function () {
      $("#popup-manual-site-7").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-8").click(function () {
      $("#popup-manual-site-8").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-9").click(function () {
      $("#popup-manual-site-9").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-10").click(function () {
      $("#popup-manual-site-10").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-11").click(function () {
      $("#popup-manual-site-11").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-12").click(function () {
      $("#popup-manual-site-12").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-13").click(function () {
      $("#popup-manual-site-13").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-manual-14").click(function () {
      $("#popup-manual-site-14").show();
      $(".overlay-popup").css("display", "flex");
    });

    $("#open-about-1").click(function () {
      $("#popup-about-site-1").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-2").click(function () {
      $("#popup-about-site-2").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-3").click(function () {
      $("#popup-about-site-3").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-4").click(function () {
      $("#popup-about-site-4").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-5").click(function () {
      $("#popup-about-site-5").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-6").click(function () {
      $("#popup-about-site-6").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-7").click(function () {
      $("#popup-about-site-7").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-8").click(function () {
      $("#popup-about-site-8").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-9").click(function () {
      $("#popup-about-site-9").show();
      $(".overlay-popup").css("display", "flex");
    });
    $("#open-about-10").click(function () {
      $("#popup-about-site-10").show();
      $(".overlay-popup").css("display", "flex");
    });
     $("#open-about-11").click(function () {
      $("#popup-about-site-11").show();
      $(".overlay-popup").css("display", "flex");
    });
     $("#open-about-12").click(function () {
      $("#popup-about-site-12").show();
      $(".overlay-popup").css("display", "flex");
    });
     $("#open-about-13").click(function () {
      $("#popup-about-site-13").show();
      $(".overlay-popup").css("display", "flex");
    });
     $("#open-about-14").click(function () {
      $("#popup-about-site-14").show();
      $(".overlay-popup").css("display", "flex");
    });

    $("#open-programs").click(function () {
      $("#popup-programs").show();
      $(".overlay-popup").css("display", "flex");
    });

    $("#open-obs").click(function () {
      $("#popup-obs").show();
      $(".overlay-popup").css("display", "flex");
    });

    $("#open-comp-help").click(function () {
      $("#popup-comp-help").show();
      $(".overlay-popup").css("display", "flex");
      // $('#compHelpSelectTime').next().children(':first').html('Время').css('display', 'none');
      // $('#compHelpSelectDate').next().children(':first').html('Дата').css('color', '#888;');
      $(".help-block-error").hide();
      //выбор времени и даты
      var curDate = new Date();
      curDate.setDate(curDate.getDate() - 2);
      var options = Array.from($("#compHelpSelectDate").children());
      options.forEach(function (elem) {
        var temp = curDate.setDate(curDate.getDate() + 1);
        $(elem).html(
            curDate.getDate() +
            "." +
            (curDate.getMonth() + 1) +
            "." +
            curDate.getFullYear()
        );
      });
      var optionJQ = Array.from(
          $("#compHelpSelectDate").next().next().children(":first").children()
      );
      for (var i = 0; i < optionJQ.length; i++) {
        $(optionJQ[i]).html($(options[i]).html());
      }
    });

    $(".js-apply-to-site").click(function () {
      $(".popup-apply-to-site").show();
      $(".overlay-popup").css("display", "flex");
    });

    $("#workMenuItem").addClass("current-page");

    $(".popup-about-close").click(function () {
      $(".overlay-popup ").hide();
      $(".overlay-popup").children().hide();
    });

    //правка отображения окон
    $(
        (function () {
          if ($(".popup-apply-to-site").css("display") === "block") {
            $(".overlay-popup").css("display", "flex");
          }

          if ($(".program-block").children().length < 4) {
            $("#popup-programs").css("height", "400px");
          } else {
            $("#popup-programs").css("height", "690px");
          }
        })()
    );
    //очистка формы
    $("#popup-comp-help").on("click", ".js-close-popup", function () {
      $("#login-form").trigger("reset");
    });
    //selects
    $(".popup-comp-help__time-wrap").on(
        "click",
        "#compHelpSelectDate-styler .jq-selectbox__dropdown",
        function (e) {
          $(this).prev().children(":first").css("color", "#30173D");
          $("#pchelpform-date").val($(e.target).html().substr(0, 10));
          $(".popup-comp-help__date-wrap").children(":last").hide();
        }
    );
    $(".popup-comp-help__time-wrap").on(
        "click",
        "#compHelpSelectTime-styler .jq-selectbox__dropdown",
        function (e) {
          $(this).prev().children(":first").css("color", "#30173D");
          $("#pchelpform-time").val($(e.target).html().substr(0, 5));
          $(".popup-comp-help__clock-wrap").children(":last").hide();
        }
    );

    $(".workcab__begin-btn").click(function () {
      const parentBlock = $(this).parent();
      const blockModalWindow = parentBlock.find(".worckab__begin-modal");
      blockModalWindow.css("display", "flex");
    });

    $(".begin-work-close").click(function () {
      $(this).parent().hide();
    });

    $(".ajax_add_new_site").click(function () {
      var site_id = $(this).attr("name");
      var data = new FormData();
      data.append("site_id", site_id);
      $.ajax({
        url: "/user/ajaxcreatenewtaskaddmodelsite",
        type: "POST", // важно!
        data: data,
        cache: false,
        dataType: "json",
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData: false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType: false,
        // функция успешного ответа сервера
        success: function (respond, status, jqXHR) {
          if (typeof respond.error === "undefined") {
            $.toast({
              heading: "Success",
              text: "Заявка отправлена",
              showHideTransition: "slide",
              icon: "success",
            });
            var site_name = "";
            if (site_id == "1") site_name = "JAS";
            if (site_id == "2") site_name = "CHA";
            if (site_id == "3") site_name = "MYF";
            if (site_id == "4") site_name = "BON";
            if (site_id == "5") site_name = "IML";
            if (site_id == "6") site_name = "STR";
            if (site_id == "7") site_name = "CAM4";
            if (site_id == "8") site_name = "MYF";
            if (site_id == "9") site_name = "SOD";
          }
          // ошибка
          else {
            console.log("ОШИБКА: " + respond.error);
          }
        },
        // функция ошибки ответа сервера
        error: function (jqXHR, status, errorThrown) {
          console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
          //$('.modal-label').text('Менеджер еще не одобрил заявку');
          $.toast({
            heading: "",
            text: "Менеджер еще не одобрил заявку",
            showHideTransition: "fade",
            icon: "error",
          });
          //alert('За данный период нет данных');
        },
      });
    });

    $(".user-workcab_obs-save").click(function () {
      var arr_stream = {};
      $('input[name="usert-site1-key"]').each(function () {
        arr_stream[$(this).data("site")] = $(this).val();
      });
      var data = new FormData();
      //console.log(JSON.stringify(arr_stream));
      data.append("arr_stream", JSON.stringify(arr_stream));
      $.ajax({
        url: "/user/ajaxupdatemodelstream",
        type: "POST", // важно!
        data: data,
        cache: false,
        dataType: "json",
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData: false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType: false,
        // функция успешного ответа сервера
        success: function (respond, status, jqXHR) {
          if (typeof respond.error === "undefined") {
            $.toast({
              heading: "Success",
              text: "Изменения внесены",
              showHideTransition: "slide",
              icon: "success",
            });
          }
          // ошибка
          else {
            console.log("ОШИБКА: " + respond.error);
          }
        },
        // функция ошибки ответа сервера
        error: function (jqXHR, status, errorThrown) {
          console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
          //$('.modal-label').text('Менеджер еще не одобрил заявку');
          $.toast({
            heading: "",
            text: "Ошибка сервера",
            showHideTransition: "fade",
            icon: "error",
          });
        },
      });
    });

    $(".work-with-sites__stream-key").click(function () {
      var key = $(".worckab__begin-modal-key>span.span-key");
      var data = new FormData();
      //    data.append( 'arr_stream', JSON.stringify(arr_stream ));
      $.ajax({
        url: "/user/ajaxupdatemodelstreamkey",
        type: "POST", // важно!
        data: data,
        cache: false,
        dataType: "json",
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData: false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType: false,
        // функция успешного ответа сервера
        success: function (respond, status, jqXHR) {
          if (typeof respond.error === "undefined") {
            key.html("");
            key.html(respond);
            $.toast({
              heading: "Success",
              text: "Изменения внесены",
              showHideTransition: "slide",
              icon: "success",
            });
          }
          // ошибка
          else {
            console.log("ОШИБКА: " + respond.error);
          }
        },
        // функция ошибки ответа сервера
        error: function (jqXHR, status, errorThrown) {
          console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
          //$('.modal-label').text('Менеджер еще не одобрил заявку');
          $.toast({
            heading: "",
            text: "Ошибка сервера",
            showHideTransition: "fade",
            icon: "error",
          });
        },
      });
    });


    $.ajax({
      url : '/user/ajaxgetfullaccessstatus',
      type        : 'GET',
      cache       : false,
      processData : false,
      contentType : false,
      success     : function (respond) {
        var timerstreamId = setInterval(function () {
          data = 1;
          $.ajax({
            url: "/user/ajaxcheckactivestreamonline",
            type: "POST",
            data: data,
            cache: false,
            dataType: "json",
            // отключаем обработку передаваемых данных, пусть передаются как есть
            processData: false,
            // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
            contentType: false,
            // функция успешного ответа сервера
            success: function (respond, status, jqXHR) {
              if (typeof respond.error === "undefined") {
//          console.log(respond);
                if(respond.online){
                  $('input[type="checkbox"]').parent().parent('.work-with-sites__checkbox').addClass("disabled");
                  $('button.user-workcab_obs-save').hide();
//		$('button.user-workcab_obs-save').addClass("disabled");
                  $('a.work-with-sites__stream-key').hide();
                }
                else{
                  $('input[type="checkbox"]').parent().parent('.work-with-sites__checkbox').removeClass("disabled");
                  $('button.user-workcab_obs-save').show();
//		$('button.user-workcab_obs-save').removeClass("disabled");
                  $('a.work-with-sites__stream-key').show();
                }
              }
              // ошибка
              else {
                console.log("ОШИБКА: " + respond.error);
              }
            },
            // функция ошибки ответа сервера
            error: function (jqXHR, status, errorThrown) {
              console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
            },
          });
        }, 10000);
      }
    });

    //Каждые 30 секунд посылаем запрос для проверки добавлен ли сайт у модели
    // скрипт возвращает массив активированных сайтов у модели
    var timerId = setInterval(function () {
      data = 1;
      $.ajax({
        url: "/user/ajaxcheckactivesiteloop",
        type: "POST",
        data: data,
        cache: false,
        dataType: "json",
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData: false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType: false,
        // функция успешного ответа сервера
        success: function (respond, status, jqXHR) {
          if (typeof respond.error === "undefined") {
            //       console.log(respond);
            changeSiteButton(respond);
          }
          // ошибка
          else {
            console.log("ОШИБКА: " + respond.error);
          }
        },
        // функция ошибки ответа сервера
        error: function (jqXHR, status, errorThrown) {
          console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
        },
      });
    }, 30000);

    // через 5 часов остановить повторы
    setTimeout(function () {
      clearInterval(timerId);
    }, 60000 * 60 * 5);

    function changeSiteButton(respond) {
      for (var i = 0; i <= respond.length - 1; i++) {
        var site_id = respond[i].site_id;
        $("#start_work_site_id_" + site_id).show();
        $("#add_site_site_id_" + site_id).hide();
        //console.log(respond[i].login.indexOf('+'));
        if (respond[i].login.indexOf("+") > 1) {
          //$('.wrap_model_data').html('');
          $("#workcab_begin_id_" + site_id)
              .children(".wrap_model_data")
              .html("");
          let loginArr = respond[i].login.split("+");
          let passwordArr = respond[i].password.split("+");
          for (var j = 0; j < loginArr.length; j++) {
            $("#workcab_begin_id_" + site_id)
                .children(".wrap_model_data")
                .append(
                    '<div class="worckab__begin-modal-login">Логин:<span class="ym-hide-content">' +
                    loginArr[j] +
                    '</span></div><div class="worckab__begin-modal-password">Пароль:<span class="ym-hide-content">' +
                    passwordArr[j] +
                    "</span></div>"
                );
          }
        } else {
          $("#workcab_begin_id_" + site_id)
              .children(".wrap_model_data")
              .children(".worckab__begin-modal-login")
              .children("span")
              .text(respond[i].login);
          $("#workcab_begin_id_" + site_id)
              .children(".wrap_model_data")
              .children(".worckab__begin-modal-password")
              .children("span")
              .text(respond[i].password);
        }
      }
    }

    $(document).ready(function () {
      $('input[type="checkbox"]').click(function () {
        var is_stream_active;
        var check = $(this);
        if ($(this).prop("checked") == true) {
          is_stream_active = 0;
        } else if ($(this).prop("checked") == false) {
          is_stream_active = 1;
        }
        console.log("site:" + $(this).data("tag"));
        console.log(is_stream_active);

        var site_id = $(this).data("tag");
        var data = new FormData();
        data.append("site_id", site_id);
        data.append("is_stream_active", is_stream_active);
        $.ajax({
          url: "/user/ajaxchangestatusstream",
          type: "POST", // важно!
          data: data,
          cache: false,
          dataType: "json",
          processData: false,
          contentType: false,
          success: function (respond, status, jqXHR) {
            if (typeof respond.error === "undefined") {
              $.toast({
                heading: "Success",
                text: "Статус изменен",
                showHideTransition: "slide",
                icon: "success",
              });
            }
            // ошибка
            else {
              if(is_stream_active == 0 ) check.prop('checked', true); else check.prop('checked', false);
//		check.parent().parent('.work-with-sites__checkbox').addClass("disabled");
              check.trigger("change");
              console.log("Ошибка: " + respond.error);
              $.toast({
                heading: "Error",
                text: "Ошибка: " + respond.error,
                showHideTransition: "fade",
                icon: "error",
              });
            }
          },
          error: function (jqXHR, status, errorThrown) {
            if(is_stream_active == 0 )check.prop('checked', true); else check.prop('checked', false);
            check.trigger("change");
            console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
            $.toast({
              heading: "",
              text: "Ошибка",
              showHideTransition: "fade",
              icon: "error",
            });
          },
        });
      });
    });

    $(document).mouseup(function (e) {
      var container = $(".modal-container");
      if (container.has(e.target).length === 0) {
        container.hide();
        $(".overlay-popup").css("display", "none");
      }
    });

    //webcam photo
    $('.js-web-photo').click(function() {

      $('.modal-snapshot').show();
      startWebCam();
    });

  }
})

$(document).ready(function() {
  const equipmentResponse = $("#equipment-response");
  const equipmentOptions = $("#equipment-options");
  const initialMessage = $("#initial-message");
  const equipmentSubOptions = $("#equipment-sub-options");
  const errorMessage = 'Произошла ошибка, попробуйте позже';

  $(".equipment-choice").on("click", async function() {
    const equipmentValue = $(this).data("equipment");
    let message = "";

    initialMessage.hide();
    equipmentOptions.hide();
    equipmentSubOptions.hide();
    equipmentResponse.hide();

    switch (equipmentValue) {
      case 1:
      case 2:
        message = "Пожалуйста, подождите";
        if (await sendEquipmentData(equipmentValue) === false) {
          message = errorMessage;
        } else {
          location.reload();
        }
        break;
      case 3:
        localStorage.setItem('originalEquipment', equipmentValue);
        equipmentSubOptions.show();
        break;
      case 4:
        localStorage.setItem('originalEquipment', equipmentValue);
        equipmentSubOptions.show();
        break;
      case 5:
        message = "Отличное решение! Информация передана вашему персональному менеджеру и специалистам технической поддержки.";
        if (await sendEquipmentData('1') === false) {
          message = errorMessage;
        } else {
          location.reload();
        }
        break;
      case 6:
        message = "Информация передана вашему персональному менеджеру и специалистам технической поддержки";
        const originalEquipment = localStorage.getItem('originalEquipment');
        if (await sendEquipmentData(originalEquipment) === false) {
          message = errorMessage;
        } else {
          location.reload();
        }
        break;
      default:
        message = "Неизвестное значение оборудования.";
    }

    equipmentResponse.html(message).show();
  });
});

async function sendEquipmentData(equipmentValue) {
  try {
    const response = await $.ajax({
      url: "/user/ajaxsavedevice",
      type: "POST",
      cache: false,
      dataType: "json",
      contentType: 'application/json',
      data: JSON.stringify({ equipment: parseInt(equipmentValue) })
    });
    return response.status === 'success';
  } catch (error) {
    console.error("Ошибка AJAX запроса: ", error);
    return false;
  }
}

const sendVerifyData = async (verifyValue) => {
  try {
    const response = await $.ajax({
      url: "/user/ajaxsaveverify",
      type: "POST",
      cache: false,
      dataType: "json",
      contentType: 'application/json',
      data: JSON.stringify({ verify: parseInt(verifyValue) })
    });

    console.log(response.status);
    return response.status === 'success';
  } catch (error) {
    console.error("Ошибка AJAX запроса: ", error);
    return false;
  }
}

$(document).ready(function() {
  const verifyAgeResponse = $("#verify-age-response");

  $(".verify-age-choice-button").on("click", async function() {
    $(this).attr("disabled", true);
    const equipmentValue = $(this).data("verify");
    let message = "";
    let errorMessage = 'Произошла ошибка, попробуйте позже';

    verifyAgeResponse.hide();

    switch (equipmentValue) {
      case 1:
        message = "Пожалуйста, подождите";
        if (await sendVerifyData(equipmentValue) === false) {
          $(this).attr("disabled", false);
          message = errorMessage;
        } else {
          console.log(equipmentValue);
          location.reload();
        }
        break;
      case 2:
        message = "Пожалуйста, подождите";
        if (await sendVerifyData(equipmentValue) === false) {
          $(this).attr("disabled", false);
          message = errorMessage;
        } else {
          location.reload();
        }
        break;
      default:
        $(this).attr("disabled", false);
        message = "Неизвестное значение верификации.";
    }

    verifyAgeResponse.html(message).show();
  });
});
