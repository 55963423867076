function showExcludeReasonBox(model_id) {
    $('.exclude-reason-box').show();
    $('.modal-exclude-btn')[0].setAttribute('id', model_id);
    $('#exclusion_model_id')[0].innerHTML = model_id;
}

function monitoringExcludeModel() {
    var model_id = $('.modal-exclude-btn')[0].id;
    var reason   = $('#exclusion_reason').val().trim();

    if (reason.length < 1) {
        $('.error_exclusion_message').show();
    } else {
        $.ajax({
            url    : '/manager/ajaxmonitoringexcludemodel',
            type   : 'POST',
            data   : {model_id: model_id, reason: reason},
            success: function(response){
                $('#model-monitoring-' + model_id).closest('tr').remove();
                $('.exclude-reason-box').hide();
            },
            error: function(jqXHR, status ){
                console.log(status, jqXHR );
            }
        });
    }
}

$(document).ready(function() {
    $(document).on('click', '.save-comment-btn', function() {
        console.log('Button clicked');
        var modelId = $(this).data('model-id');
        console.log('ModelId:', modelId);

        var commentElement = $(this).closest('tr').find('.comment-input');

        var comment = commentElement.val().trim();
        console.log('Trimmed comment:', comment);

        if (comment === '') {
            alert('Комментарий не может быть пустым');
            return;
        }

        $.ajax({
            url: '/manager/ajaxsavemonitoringcomment',
            method: 'POST',
            data: {
                modelId: modelId,
                comment: comment
            },
            success: function(response) {
                if (response.success) {
                    alert('Комментарий сохранен');
                } else {
                    alert('Ошибка: ' + response.message);
                }
            },
            error: function() {
                alert('Произошла ошибка при сохранении комментария');
            }
        });
    });
});