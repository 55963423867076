if ($("#superadmin-work-program").length){



  $('.ajax_superadmin_set_program_photo').change(function() {
    files = this.files;
    position = $(this).prop('name');
    var parent = $(this).parent().parent();
    if( typeof files == 'undefined' ) return;
    var data = new FormData();

    $.each( files, function( key, value ){
      data.append( key, value );
    });

    data.append( 'position', position );
    //console.log(data);
    $.ajax({
    url         : '/admin/sites/ajaxsuperadminuploadsitephoto',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        src = respond;
        var from = src.search("upload");
        var to = src.length;
        newsrc = src.substring(from,to);
        newsrc = '\\'+newsrc;
        //console.log(parent);
        parent.find('img').prop('src', newsrc);
      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });
  });



}