$('.login_custom_script').keyup(function(e) {
	if (e.keyCode == 8) {
		return false;
	}else {
		if ($(this).val().match(/[a-z]/)) {
			let val = $(this).val().replace('+','');
			val = val.replace('(','');
			val = val.replace(')', '');
			val = val.replace(' ', '');
			$(this).val(val);
		}
		if ($(this).val().length == 2) {
			if ($(this).val()=='+7') {
				$(this).val('+7(')
			}
		}
		if ($(this).val().length == 1) {
			let val = $(this).val();
			if (val == 7) {
				$(this).val('+7(');
			}else if(val == 8) {
				$(this).val('+7(');

			}else if(val == 9) {
				$(this).val('+7(9');
			}
		}else {
			let firstVal = $(this).val()[0];
			let secondVal = $(this).val()[1];
			if (firstVal == '+' && secondVal=='7' && $(this).val().length == '6') {
				let setVal = $(this).val() + ') ';
				$(this).val(setVal);
			}
			if (firstVal == '+' && secondVal=='7' && $(this).val().length == '11') {
				let setVal = $(this).val() + '-';
				$(this).val(setVal);
			}
			if (firstVal == '+' && secondVal=='7' && $(this).val().length == '14') {
				let setVal = $(this).val() + '-';
				$(this).val(setVal);
			}
			if (firstVal == '+' && secondVal=='7' && $(this).val().length > 17) {
				$(this).val($(this).val().substr(0, 17));
			}
	
/*			if (firstVal == '8' && $(this).val().length == '5') {
				let setVal = $(this).val() + ') ';
				$(this).val(setVal);
			}
			if (firstVal == '8' && $(this).val().length == '10') {
				let setVal = $(this).val() + '-';
				$(this).val(setVal);
			}
			if (firstVal == '8' && $(this).val().length == '13') {
				let setVal = $(this).val() + '-';
				$(this).val(setVal);
			}
			if (firstVal == '8' && $(this).val().length >= 16) {
				$(this).val($(this).val().substr(0, 16));
			}
*/

		}

	}
});