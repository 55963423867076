const turnOffAutoVerifButton = $('.ajax-turnoff-autoverif-button');

function handleTurnOfAutoVerifAjaxOnClick() {

  $.ajax({
    url: '/manager/ajaxturnoffautoverification',
    type: 'POST',
    contentType: 'application/json',
    success: function(response) {
      if (response.success) {

        verifModelsSuccessToast(response.message);
      } else {
        verifModelsErrorToast(response.message);
      }
    },
    error: function(jqXHR, textStatus) {
      const status = jqXHR.status;
      const errorMessages = {
        0: 'Нет подключения к серверу',
        404: 'Запрашиваемый URL не найден',
        500: 'Внутренняя ошибка сервера'
      };
      const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
      verifModelsErrorToast(message);
    }
  });
}

turnOffAutoVerifButton.on('click', function () {
  handleTurnOfAutoVerifAjaxOnClick();
  location.reload()
});

function verifModelsSuccessToast(message) {
  $.toast({
    heading: 'Успешно',
    text: message,
    showHideTransition: 'slide',
    icon: 'success'
  });
}

function verifModelsErrorToast(message) {
  $.toast({
    heading: 'Ошибка',
    text: message,
    showHideTransition: 'fade',
    icon: 'error'
  });
}