$(document).ready(function() {
    /* TODO: не используемая функция, узнать зачем */
/*    function showNoteModal(modelId) {
        $('.get_model_coach_note_model_id_here').text(modelId);

        var data = new FormData();
        data.append('userid', modelId);
        $.ajax({
            url         : '/manager/ajaxgetmodelcoachnote',
            type        : 'POST',
            data        : data,
            cache       : false,
            dataType    : 'json',
            processData : false,
            contentType : false,
            success     : function(respond) {
                if (typeof respond.error === 'undefined') {
                    $('#modal-change-model-coach-note').val(respond);

                    $.toast({
                        heading:            'Success',
                        text:               'Данные получены',
                        showHideTransition: 'slide',
                        icon:               'success'
                    });
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function(jqXHR, status) {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading:            '',
                    text:               'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon:               'error'
                });
            }
        });

        $('.modal-model-name').text(modelId);
        $('.modal-box-model-coach-note').toggle();
    }*/

    $('.ajax_save_model_coach_note').click(function() {
        let modelId = $('.get_model_coach_note_model_id_here').text();
        let text    = $('#modal-change-model-coach-note').val();

        let data = new FormData();
        data.append('user_id', modelId);
        data.append('text', text);

        $.ajax({
            url         : '/manager/ajaxsavemodelcoachnote',
            type        : 'POST',
            data        : data,
            cache       : false,
            dataType    : 'json',
            processData : false,
            contentType : false,
            success     : function(respond) {
                if (typeof respond.error === 'undefined') {
                    $.toast({
                        heading:            'Success',
                        text:               'Данные сохранены',
                        showHideTransition: 'slide',
                        icon:               'success'
                    });

                    $('.modal-box-model-coach-note').hide();
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function(jqXHR, status) {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading:            '',
                    text:               'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon:               'error'
                });
            }
        });
    });

    $(".ajax_get_models_coach_period_data").click(function() {
        getAjaxCoachPeriodDataForTable();
    });

    function getAjaxCoachPeriodDataForTable() {
        var monthDay = $("#period-select-date option:selected").attr('name');
        var year = $("#select-period-year option:selected").val();
        var array = monthDay.split('.');
        var from = year + '-' + array[0] + '-' + array[1];
        var to = year + '-' + array[0] + '-' + array[2];

        var data = new FormData();
        data.append( 'from', from );
        data.append( 'to', to );

        $.ajax({
            url         : '/manager/ajaxcoachinggetfilterdata',
            type        : 'POST', // важно!
            data        : data,
            cache       : false,
            dataType    : 'json',
            // отключаем обработку передаваемых данных, пусть передаются как есть
            processData : false,
            // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
            contentType : false,
            // функция успешного ответа сервера
            success     : function( respond){
                if( typeof respond.error === 'undefined' ){
                    fillTheCoachTable(respond);
                }
                // ошибка
                else {
                    console.log('ОШИБКА: ' + respond.error );
                    $('.preloader').fadeOut('slow', function() {});
                }
            },
            // функция ошибки ответа сервера
            error: function( jqXHR, status ){
                console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
                $('.preloader').fadeOut('slow', function() {});
            }
        });
    }

    function fillTheCoachTable(response) {
        const $cphLastPeriodElements = $('.manager-main__table-cph_last_period');
        const $cashLastPeriodElements = $('.manager-main__table-cash_last_period');
        const $hoursLastPeriodElements = $('.manager-main__table-hours_last_period');
        const $modelIdElements = $('.manager-main__table-model-id');

        // Создаем объект для быстрого доступа к моделям по ID
        const responseMap = {};
        response.forEach(item => {
            responseMap[item.id] = item;
        });

        // Итерируемся по всем модельным элементам
        $modelIdElements.each((index, element) => {
            const modelId = Number($(element).data('modelId'));
            const modelData = responseMap[modelId];

            if (modelData) {
                $cphLastPeriodElements.eq(index).html(modelData.cphForLastPeriod).css('color', modelData.cphForLastPeriodColor);
                $cashLastPeriodElements.eq(index).html(modelData.cashLastPeriod).css('color', modelData.cashLastPeriodColor);
                $hoursLastPeriodElements.eq(index).html(modelData.timeLastPeriod).css('color', modelData.timeLastPeriodColor);
            }
        });
    }

    function saveComment(commentValue, modelId) {
        const data = new FormData();
        data.append('comment', commentValue);
        data.append('model_id', modelId);

        $.ajax({
            url: '/manager/ajaxcoachcommentsave',
            type: 'POST',
            data: data,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: function(respond) {
                if (typeof respond.error === 'undefined') {
                    $.toast({
                        heading: 'Success',
                        text: 'Данные сохранены',
                        showHideTransition: 'slide',
                        icon: 'success'
                    });
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function(jqXHR, status) {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading: '',
                    text: 'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
            }
        });
    }

    $('.submitCommentBtn').on('click', function() {
        const inputValue = $(this).prev().val().replace(/"/g, '\\"');
        const modelId = $(this).next().data('comment_model-id');

        saveComment(inputValue, modelId);
    });

    $('.btn-hide-freeze').click(function() {
        $(this).closest('tr').slideUp();
    });

    /* TODO: не используемая функция, узнать зачем */
/*    function hideCoachFreezeModel(modelId) {
        var data = new FormData();
        data.append('model_id', modelId);

        $.ajax({
            url         : '/manager/ajaxcoachhidefreezemodel',
            type        : 'POST',
            data        : data,
            cache       : false,
            dataType    : 'json',
            processData : false,
            contentType : false,
            success     : function(respond) {
                if (typeof respond.error === 'undefined') {
                    $.toast({
                        heading:            'Success',
                        text:               'Данные сохранены',
                        showHideTransition: 'slide',
                        icon:               'success'
                    });

                    $('.modal-box-model-coach-note').hide();
                    window.location.reload();
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function(jqXHR, status) {
                console.log('ОШИБКА да да сука AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading:            '',
                    text:               'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon:               'error'
                });
            }
        });
    }*/

    /* TODO: не используемая функция, узнать зачем */
/*    function excludeModel(modelId, permanently) {
        let data = new FormData();
        data.append('model_id', modelId);
        if (permanently) {
            data.append('permanently', permanently);
        }

        $.ajax({
            url         : '/manager/ajaxcoachingexcludemodel',
            type        : 'POST',
            data        : data,
            cache       : false,
            dataType    : 'json',
            processData : false,
            contentType : false,
            success     : function(respond) {
                if (typeof respond.error === 'undefined') {
                    $.toast({
                        heading:            'Success',
                        text:               'Данные сохранены',
                        showHideTransition: 'slide',
                        icon:               'success'
                    });

                    $('.modal-box-model-coach-note').hide();
                    window.location.reload();
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function(jqXHR, status) {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading:            '',
                    text:               'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon:               'error'
                });
            }
        });
    }*/
});