if ($("#superadmin-create-instruction").length){

  $(function(){
    fill_inputs();
  });


  function fill_inputs() {
    var actionNumber = $('#action_number option:selected').text();
    var stepNumber = $('#step_number option:selected').text();
    var site_id = $('.site_id').html();
    /*
    // Полученние данных по шагам с клиента
    var stepBlockNumber = parseInt(stepNumber)+1;
    var blockNumber = parseInt(actionNumber)+1;
    var actionText = $('.popup__manual-content-wrap .steps-block:nth-child('+blockNumber+')').children().html();
    var stepText = $('.popup__manual-content-wrap .steps-block:nth-child('+blockNumber+')').children('.step-block:nth-child('+stepBlockNumber+')').children().children('p').html();
    var stepPhotoSrc = $('.popup__manual-content-wrap .steps-block:nth-child('+blockNumber+')').children('.step-block:nth-child('+stepBlockNumber+')').children('div:nth-child(2)').children('img').prop('src');
    */
    // Получение данных по шагам с сервера
    var data = new FormData();
    data.append( 'site_id', site_id );
    data.append( 'actionNumber', actionNumber );
    data.append( 'stepNumber', stepNumber );
    $.ajax({
    url         : '/admin/instructions/ajaxsuperadmingetstepdata',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        console.log(respond);
        $('.textarea_action_description').val(respond.action_description);
        $('.textarea_step_description').val(respond.step_description);
        $('#step_photo_image').prop('src', respond.step_photo_url);
        if (respond.step_photo_url === undefined) {
          src = '/upload/photo-default.png';
          $('#step_photo_image').prop('src', src);
        }
      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });
  }

  $('#action_number').change(function() {
    fill_inputs();
  });

  $('#step_number').change(function() {
    fill_inputs();
  });


  var files;
  $('input[type=file]').on('change', function(){
    files = this.files;
  });

  $('.ajax_create_steps').click(function() {
    var site_id = $('.site_id').html();
    var actionNumber = $('#action_number option:selected').text();
    var actionDescription = $('.textarea_action_description').val();
    var stepNumber = $('#step_number option:selected').text();
    var stepDescription = $('.textarea_step_description').val();
    //if( typeof files == 'undefined' ) return;
    var data = new FormData();

    $.each( files, function( key, value ){
      data.append( key, value );
    });

    data.append( 'site_id', site_id );
    data.append( 'actionNumber', actionNumber );
    data.append( 'actionDescription', actionDescription );
    data.append( 'stepNumber', stepNumber );
    data.append( 'stepDescription', stepDescription );
    //console.log(data);
    $.ajax({
    url         : '/admin/instructions/ajaxsuperadmincreatenewinstruction',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        $.toast({
          heading: 'Success',
          text: 'Шаг успешно создан',
          showHideTransition: 'slide',
          icon: 'success'
        });
        location.reload();


      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });
  });

$('.ajax_delete_steps').click(function() {
    var site_id = $('.site_id').html();
    var actionNumber = $('#action_number option:selected').text();
    var stepNumber = $('#step_number option:selected').text();
    //if( typeof files == 'undefined' ) return;
    var data = new FormData();

    data.append( 'site_id', site_id );
    data.append( 'actionNumber', actionNumber );
    data.append( 'stepNumber', stepNumber );
    //console.log(data);
    $.ajax({
    url         : '/admin/instructions/ajaxsuperadmindeleteinstruction',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        console.log(respond);
        location.reload();


      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });
});



}