document.addEventListener("DOMContentLoaded", function () {

  const messenger = document.getElementById("messenger");
  const contact = document.getElementById("contact");
  const paymentSystem = document.getElementById("paymentSystem");
  const currency = document.getElementById("currency");
  const cardNumber = document.getElementById("cardNumber");
  const cardName = document.getElementById("cardName");
  const wallet = document.getElementById("wallet");
  const tablemodelPeriod = this.getElementById('tablemodel-period');

  const windowInnerWidth = document.documentElement.clientWidth;
  const scrollbarWidth = parseInt(window.innerWidth) - parseInt(document.documentElement.clientWidth);
  const bodyElementHTML = document.getElementsByTagName("body")[0];
  const clipboardModalBackground = document.getElementsByClassName("clipboardModalBackground")[0];
  const clipboardModalActive = document.getElementsByClassName("clipboardModalActive")[0];
  const balanceModalBackground = document.getElementsByClassName("balanceModalBackground")[0];
  const balanceModalActive = document.getElementsByClassName("balanceModalActive")[0];
  const balanceModalCloseButton = document.getElementsByClassName("balanceModalClose")[0];
  const referralLinks = $(".referral-link__text");
  const balanceLink = document.getElementById("balance-link");
  const postbackBtnUrlSubmit = $('.postback__btn-url-submit');

  const cpaLandingLink = $('#cpa-landing-link');
  const cpaTelegramLink = $('#cpa-telegram-link');
  const revshareLandingLink = $('#revshare-landing-link');
  const revshareTelegramLink = $('#revshare-telegram-link');

  const cpaRevshareLandingLink = $('#cpa-revshare-landing-link');
  const cpaRevshareTelegramLink = $('#cpa-revshare-telegram-link');

  function updateReferralLinkDisplay() {
    const type = $('input[name="type"]:checked').val();
    const platform = $('input[name="platform"]:checked').val();

    cpaLandingLink.hide();
    cpaTelegramLink.hide();
    revshareLandingLink.hide();
    revshareTelegramLink.hide();

    if (type === 'cpa' && platform === 'landing') {
      cpaLandingLink.show();
    } else if (type === 'cpa' && platform === 'telegram') {
      cpaTelegramLink.show();
    } else if (type === 'revshare' && platform === 'landing') {
      revshareLandingLink.show();
    } else if (type === 'revshare' && platform === 'telegram') {
      revshareTelegramLink.show();
    }
  }

  function updatePrivilegedReferralLinkDisplay() {
    const platform = $('input[name="cpa-revshare-platform"]:checked').val();

    cpaRevshareLandingLink.hide();
    cpaRevshareTelegramLink.hide();

    if (platform === 'landing') {
      cpaRevshareLandingLink.show();
    }

    if (platform === 'telegram') {
      cpaRevshareTelegramLink.show();
    }
  }

  $('.affiliate-cpa-revshare-radio-link').change(updatePrivilegedReferralLinkDisplay);
  $('.affiliate-radio-link').change(updateReferralLinkDisplay);

  bodyMargin();

  function bodyMargin() {
    bodyElementHTML.style.marginRight = "-" + scrollbarWidth + "px";
  }

  function clipboardModalShow() {
    if (windowInnerWidth >= 1366) {
      bodyMargin();
    }

    clipboardModalBackground.style.display = "block";
    clipboardModalActive.style.left = "calc(50% - " + (175 - scrollbarWidth / 2) + "px)";

    setTimeout(() => {
      clipboardModalBackground.style.display = "none";
      if (windowInnerWidth >= 1366) {
        bodyMargin();
      }
    }, 700);
  }

  function balanceModalShow() {
    if (windowInnerWidth >= 1366) {
      bodyMargin();
    }
    bodyElementHTML.style.overflowY = "hidden";
    balanceModalBackground.style.display = "block";
    balanceModalActive.style.left = "calc(50% - " + (300 - scrollbarWidth / 2) + "px)";
  }

  function balanceModalClose() {
    if (windowInnerWidth >= 1366) {
      bodyMargin();
    }

    balanceModalBackground.style.display = "none";
    bodyElementHTML.style.overflowY = "auto";
  }

  referralLinks.on('click', function(e) {
    e.preventDefault();

    clipboardModalShow();

    const textToCopy = $(this).text();
    navigator.clipboard.writeText(textToCopy).catch(function (err) {
      console.error("Ошибка при копировании текста: ", err);
    });
  })

  if (balanceLink !== null) {
    balanceLink.addEventListener('click', function (e) {
      e.preventDefault();

      balanceModalShow();
    });
  }

  if (balanceModalCloseButton !== null && typeof balanceModalCloseButton !== 'undefined') {
    balanceModalCloseButton.addEventListener("click", function () {
      balanceModalClose();
    });
  }

  if (messenger !== null && contact !== null) {

    function messengerLabel() {
      let label = null;

      if (messenger.value === 'whatsapp') {
        label = 'Ваш ватсап';
      }

      if (messenger.value === 'telegram') {
        label = 'Ваш телеграм';
      }

      if (messenger.value === 'viber') {
        label = 'Ваш вайбер';
      }

      contact.previousElementSibling.innerText = label;
    }

    messenger.onchange = () => {
      messengerLabel();
    }

    messengerLabel();
  }

  window.onload = function() {
    if (paymentSystem && currency && cardName && cardNumber && wallet) {
      function paymentFields(init = false) {
        if (paymentSystem.value === 'usdt_trc20') {
          currency.parentElement.parentElement.style.display = 'none';
          cardNumber.parentElement.style.display = 'none';
          cardName.parentElement.style.display = 'none';
          wallet.parentElement.style.display = 'block';
          currency.value = 'USD';
          cardNumber.value = 'none';
          cardName.value = 'none';
          wallet.value = wallet.value && wallet.value !== 'none' ? wallet.value : null;
        }
        if (paymentSystem.value === 'bank_card') {
          wallet.parentElement.style.display = 'none';
          cardNumber.parentElement.style.display = 'block';
          cardName.parentElement.style.display = 'block';
          currency.parentElement.parentElement.style.display = 'block';
          currency.parentElement.querySelector('.jq-selectbox__dropdown').style.width = '100%';
          wallet.value = 'none';
          currency.value = init ? currency.value : 'USD';
          cardNumber.value = init ? cardNumber.value : null;
          cardName.value = init ? cardName.value : null;
        }
      }
      paymentSystem.onchange = () => {
        paymentFields();
      }
      paymentFields(true);
    }
  }

  if (tablemodelPeriod !== null && windowInnerWidth < 640) {
    tablemodelPeriod.onchange = function () {
      document.getElementById('period-form').submit();
    }
  }

  function savePostbackUrl(inputValue) {
    const data = new FormData();
    data.append('postbackUrl', inputValue);

    $.ajax({
      url: '/affiliate/ajaxsavepostbackurl',
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: function(respond) {
        if (typeof respond.error === 'undefined') {
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        } else {
          console.log('ОШИБКА: ' + respond.error);
        }
      },
      error: function(jqXHR, status) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  }

  postbackBtnUrlSubmit.on('click', function(evt) {
    evt.preventDefault();
    const inputValue = $(this).prev().val().replace(/"/g, '\\"');

    savePostbackUrl(inputValue);
  });
});
