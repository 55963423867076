if ($("#modelfinance").length && $('.finance__chart-pay').length){
  (function(event) {
    var firstLevelTo = $('#table_pay_level_to span:nth-child(1)').html();
    var secondLevelFrom = $('#table_pay_level_from span:nth-child(2)').html();
    var secondLevelTo = $('#table_pay_level_to span:nth-child(2)').html();
    var secondLevel = secondLevelFrom/2 + secondLevelTo/2;
    var thirdLevelFrom = $('#table_pay_level_from span:nth-child(3)').html();
    var firstLevelModelCef = $('#table_pay_level_model_cef span:nth-child(1)').html();
    var secondLevelModelCef = $('#table_pay_level_model_cef span:nth-child(2)').html();
    var thirdLevelModelCef = $('#table_pay_level_model_cef span:nth-child(3)').html();
    const densityCanvas = document.getElementById("densityChart");
     Chart.defaults.global.defaultFontFamily = "PFDinTextProReg";
    Chart.defaults.global.defaultFontSize = 16;
     const modelStar = {
      label: /* firstLevelModelCef*100 +  */'Модель-звезда до '+firstLevelTo+'$',
      data: [firstLevelTo],
      backgroundColor: '#30173d',
      borderWidth: 0,
      barThickness: 50
    };
     const topModel = {
      label:'Топ-модель от '+secondLevelFrom+'$ до '+secondLevelTo+'$',
      data: [secondLevel],
      backgroundColor: '#f4bc00',
      borderWidth: 0,
    };
    const superModel= {
      label:'Супер-модель от '+thirdLevelFrom+'$',
      data: [thirdLevelFrom],
      backgroundColor: '#db046b',
      borderWidth: 0,
    };
     const planetData = {
      labels: ["Текущий уровень выплат"],
      datasets: [modelStar, topModel, superModel]
    };
     const chartOptions = {
      scales: {
        yAxes: [{
          ticks: {
              beginAtZero: true,
              display: false
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        }],
        xAxes: [{
          display: false,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
         },
         barPercentage: 0.9,
         categoryPercentage: 0.5
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        mode: false,
        callbacks: {
          label: function() {},
          title: function() {}
        }
      },
      scaleShowLabels : false,
    };
     const barChart = new Chart(densityCanvas, {
      type: 'bar',
      data: planetData,
      options: chartOptions
    });
    document.getElementById('legend').innerHTML = barChart.generateLegend();
  })();
}
if ($("#modelfinance").length){
  //end chart script
   //chart lines script
  (function(event) {
    const lineChartFinance = document.getElementById("lineChart");
    Chart.defaults.global.defaultFontFamily = "PFDinTextPro";
    Chart.defaults.global.defaultFontSize = 13;
    Chart.defaults.global.defaultColor = "#2f2f2f";
    let financeDateVal = $("#finance-period").val();
    Chart.defaults.global.defaultFontWeight = 400;
    const arr = [];
    const jas = {
        label: "JAS",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#db046b',
        backgroundColor: '#db046b',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#db046b',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const cha = {
        label: "CHA",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#000',
        backgroundColor: '#000',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#000',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const myf = {
        label: "MYF",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#20feee',
        backgroundColor: '#20feee',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#20feee',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const bon = {
        label: "BON",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#5bfe13',
        backgroundColor: '#5bfe13',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#5bfe13',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const iml = {
        label: "IML",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#0e61dd',
        backgroundColor: '#0e61dd',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#0e61dd',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const str = {
        label: "STR",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#a02626',
        backgroundColor: '#a02626',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#a02626',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const cam = {
        label: "CAM",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#3814ee',
        backgroundColor: '#3814ee',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#3814ee',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
      const sod = {
        label: "SOD",
        data: arr,
        lineTension: 0.3,
        fill: false,
        borderColor: '#F841B5',
        backgroundColor: '#F841B5',
        pointBorderColor: 'rgba(219, 4, 107, 0.4)',
        pointBackgroundColor: '#F841B5',
        pointRadius:0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth:10,
      };
    const chr = {
      label: "CHR",
      data: arr,
      lineTension: 0.3,
      fill: false,
      borderColor: '#FF0000',
      backgroundColor: '#FF0000',
      pointBorderColor: 'rgba(219, 4, 107, 0.4)',
      pointBackgroundColor: '#FF0000',
      pointRadius:0,
      pointHoverRadius: 5,
      pointHitRadius: 10,
      pointBorderWidth: 0,
      pointHoverBorderWidth:10,
    };
    const stm = {
      label: "STM",
      data: arr,
      lineTension: 0.3,
      fill: false,
      borderColor: '#FF6600',
      backgroundColor: '#FF9933',
      pointBorderColor: 'rgba(219, 102, 4, 0.4)',
      pointBackgroundColor: '#FFAA55',
      pointRadius:0,
      pointHoverRadius: 5,
      pointHitRadius: 10,
      pointBorderWidth: 0,
      pointHoverBorderWidth:10,
    };
    const xlc = {
      label: "XLC",
      data: arr,
      lineTension: 0.3,
      fill: false,
      borderColor: '#D99CF6',
      backgroundColor: '#D99CF6',
      pointBorderColor: 'rgba(219, 4, 107, 0.4)',
      pointBackgroundColor: '#D99CF6',
      pointRadius: 0,
      pointHoverRadius: 5,
      pointHitRadius: 10,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 10,
    };
    const f4f = {
      label: "F4F",
      data: arr,
      lineTension: 0.3,
      fill: false,
      borderColor: '#368A3A',
      backgroundColor: '#368A3A',
      pointBorderColor: 'rgba(219, 4, 107, 0.4)',
      pointBackgroundColor: '#368A3A',
      pointRadius: 0,
      pointHoverRadius: 5,
      pointHitRadius: 10,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 10,
    };
    const con = {
      label: "CON",
      data: arr,
      lineTension: 0.3,
      fill: false,
      borderColor: '#9519E3',
      backgroundColor: '#9519E3',
      pointBorderColor: 'rgba(219, 4, 107, 0.4)',
      pointBackgroundColor: '#9519E3',
      pointRadius: 0,
      pointHoverRadius: 5,
      pointHitRadius: 10,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 10,
    };

     const dataSecond = {
        label: "Cумма",
        data: [],
        lineTension: 0.3,
        fill: false,
        borderColor: '#f4bc00',
        backgroundColor: '#f4bc00',
        pointBorderColor: 'rgba(244, 188, 0, 0.4)',
        pointBackgroundColor: '#f4bc00',
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 0,
        pointHoverBorderWidth: 10,
      };
    let periodVal = '';
    function getArrayforChartdataSecond() {
      var res = [];
      for (var i = 2; i <= 16; i++) {
        res[i-2] = $('#day_result td:nth-child('+ i+')').html();
        if (res[i-2] === '-') {
          res[i-2] = 0;
        }
      }
      var check = $('#day_result td:nth-child(18)').html();
      if (check) res[15] = $('#day_result td:nth-child(17)').html();
      return res;
    }
    function getArraySiteData(id) {
      var res = [];
      for (var i = 2; i <= 16; i++) {
        res[i-2] = $('#site_id_'+id+' td:nth-child('+ i+')').html();
        if (res[i-2] === '-') {
          res[i-2] = 0;
        }
      }
      var check = $('#site_id_'+id+' td:nth-child(18)').html();
      if (check) res[15] = $('#site_id_'+i+' td:nth-child(17)').html();
      return res;
    }
    function getPeriodVal(){
      let periodSelectDate = $("#finance-period-styler .jq-selectbox__select-text").text();
      $("#finance-period option").each(function(){
        if ($(this).text() === periodSelectDate){
          periodVal = $(this).val();
          return false;
        }
      });
    }
    let speedData = {};
    function chartMonth(financeDateVal) {
      jas.data = getArraySiteData(1);
      cha.data = getArraySiteData(2);
      myf.data = getArraySiteData(3);
      bon.data = getArraySiteData(4);
      iml.data = getArraySiteData(5);
      str.data = getArraySiteData(6);
      cam.data = getArraySiteData(7);
      sod.data = getArraySiteData(9);
      chr.data = getArraySiteData(10);
      stm.data = getArraySiteData(11);
      xlc.data = getArraySiteData(12);
      f4f.data = getArraySiteData(13);
      con.data = getArraySiteData(14);
      dataSecond.data  = getArrayforChartdataSecond();
      //console.log(dataSecond.data);
      switch (financeDateVal) {
        case 'january-first': speedData = {
          labels: ["01 янв", "02 янв", "03 янв", "04 янв", "05 янв", "06 янв", "07 янв", "08 янв", "09 янв", "10 янв", "11 янв", "12 янв", "13 янв", "14 янв", "15 янв"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'january-second': speedData = {
          labels: ["16 янв", "17 янв", "18 янв", "19 янв", "20 янв", "21 янв", "22 янв", "23 янв", "24 янв", "25 янв", "26 янв", "27 янв", "28 янв", "29 янв", "30 янв", "31 янв"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'february-first': speedData = {
          labels: ["01 фев", "02 фев", "03 фев", "04 фев", "05 фев", "06 фев", "07 фев", "08 фев", "09 фев", "10 фев", "11 фев", "12 фев", "13 фев", "14 фев", "15 фев"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'february-second': speedData = {
          labels: ["16 фев", "17 фев", "18 фев", "19 фев", "20 фев", "21 фев", "22 фев", "23 фев", "24 фев", "25 фев", "26 фев", "27 фев", "28 фев"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'february-leap-year': speedData = {
          labels: ["16 фев", "17 фев", "18 фев", "19 фев", "20 фев", "21 фев", "22 фев", "23 фев", "24 фев", "25 фев", "26 фев", "27 фев", "28 фев", "29 фев"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'march-first': speedData = {
          labels: ["01 март", "02 март", "03 март", "04 март", "05 март", "06 март", "07 март", "08 март", "09 март", "10 март", "11 март", "12 март", "13 март", "14 март", "15 март"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'march-second': speedData = {
          labels: ["16 март", "17 март", "18 март", "19 март", "20 март", "21 март", "22 март", "23 март", "24 март", "25 март", "26 март", "27 март", "28 март", "29 март", "30 март", "31 март"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'april-first': speedData = {
          labels: ["01 апр", "02 апр", "03 апр", "04 апр", "05 апр", "06 апр", "07 апр", "08 апр", "09 апр", "10 апр", "11 апр", "12 апр", "13 апр", "14 апр", "15 апр"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'april-second': speedData = {
          labels: ["16 апр", "17 апр", "18 апр", "19 апр", "20 апр", "21 апр", "22 апр", "23 апр", "24 апр", "25 апр", "26 апр", "27 апр", "28 апр", "29 апр", "30 апр"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'may-first': speedData = {
          labels: ["01 май", "02 май", "03 май", "04 май", "05 май", "06 май", "07 май", "08 май", "09 май", "10 май", "11 май", "12 май", "13 май", "14 май", "15 май"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'may-second': speedData = {
          labels: ["16 май", "17 май", "18 май", "19 май", "20 май", "21 май", "22 май", "23 май", "24 май", "25 май", "26 май", "27 май", "28 май", "29 май", "30 май", "31 май"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'june-first': speedData = {
          labels: ["01 июнь", "02 июнь", "03 июнь", "04 июнь", "05 июнь", "06 июнь", "07 июнь", "08 июнь", "09 июнь", "10 июнь", "11 июнь", "12 июнь", "13 июнь", "14 июнь", "15 июнь"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'june-second': speedData = {
          labels: ["16 июнь", "17 июнь", "18 июнь", "19 июнь", "20 июнь", "21 июнь", "22 июнь", "23 июнь", "24 июнь", "25 июнь", "26 июнь", "27 июнь", "28 июнь", "29 июнь", "30 июнь", "31 июнь"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'jule-first': speedData = {
          labels: ["01 июль", "02 июль", "03 июль", "04 июль", "05 июль", "06 июль", "07 июль", "08 июль", "09 июль", "10 июль", "11 июль", "12 июль", "13 июль", "14 июль", "15 июль"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'jule-second': speedData = {
          labels: ["16 июль", "17 июль", "18 июль", "19 июль", "20 июль", "21 июль", "22 июль", "23 июль", "24 июль", "25 июль", "26 июль", "27 июль", "28 июль", "29 июль", "30 июль","31 июль"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'august-first': speedData = {
          labels: ["01 авг", "02 авг", "03 авг", "04 авг", "05 авг", "06 авг", "07 авг", "08 авг", "09 авг", "10 авг", "11 авг", "12 авг", "13 авг", "14 авг", "15 авг"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'august-second': speedData = {
          labels: ["16 авг", "17 авг", "18 авг", "19 авг", "20 авг", "21 авг", "22 авг", "23 авг", "24 авг", "25 авг", "26 авг", "27 авг", "28 авг", "29 авг", "30 авг", "31 авг"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'september-first': speedData = {
          labels: ["01 сент", "02 сент", "03 сент", "04 сент", "05 сент", "06 сент", "07 сент", "08 сент", "09 сент", "10 сент", "11 сент", "12 сент", "13 сент", "14 сент", "15 сент"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'september-second': speedData = {
          labels: ["16 сент", "17 сент", "18 сент", "19 сент", "20 сент", "21 сент", "22 сент", "23 сент", "24 сент", "25 сент", "26 сент", "27 сент", "28 сент", "29 сент", "30 сент"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'october-first': speedData = {
          labels: ["01 окт", "02 окт", "03 окт", "04 окт", "05 окт", "06 окт", "07 окт", "08 окт", "09 окт", "10 окт", "11 окт", "12 окт", "13 окт", "14 окт", "15 окт"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'october-second': speedData = {
          labels: ["16 окт", "17 окт", "18 окт", "19 окт", "20 окт", "21 окт", "22 окт", "23 окт", "24 окт", "25 окт", "26 окт", "27 окт", "28 окт", "29 окт", "30 окт", "31 окт"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'november-first': speedData = {
          labels: ["01 нояб", "02 нояб", "03 нояб", "04 нояб", "05 нояб", "06 нояб", "07 нояб", "08 нояб", "09 нояб", "10 нояб", "11 нояб", "12 нояб", "13 нояб", "14 нояб", "15 нояб"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'november-second': speedData = {
          labels: ["16 нояб", "17 нояб", "18 нояб", "19 нояб", "20 нояб", "21 нояб", "22 нояб", "23 нояб", "24 нояб", "25 нояб", "26 нояб", "27 нояб", "28 нояб", "29 нояб", "30 нояб"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'december-first': speedData = {
          labels: ["01 дек", "02 дек", "03 дек", "04 дек", "05 дек", "06 дек", "07 дек", "08 дек", "09 дек", "10 дек", "11 дек", "12 дек", "13 дек", "14 дек", "15 дек"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
        case 'december-second': speedData = {
          labels: ["16 дек", "17 дек", "18 дек", "19 дек", "20 дек", "21 дек", "22 дек", "23 дек", "24 дек", "25 дек", "26 дек", "27 дек", "28 дек", "29 дек", "30 дек", "31 дек"],
          datasets: [jas, cha, myf, bon, iml, str, cam, sod, chr, stm, xlc, f4f, con, dataSecond]
        }
        break;
      }
    };

    const chartOptions = {
      legend: {
        display: false,
      },
      ticks: {
        beginAtZero:true
      },
    };
    let lineChart = new Chart(lineChartFinance, {
      type: 'line',
      data: speedData,
      options: chartOptions
    });
    function chartTotalNumber(){
      const totalNumber = $('#day_result td:last-child').text();
      $('.chart-total__span').text(totalNumber + '$');
    }
     $(".style-data__schedule").click(function(){
        lineChart.destroy();
        getPeriodVal();
        chartTotalNumber()
        chartMonth(periodVal);
        lineChart = new Chart(lineChartFinance, {
          type: 'line',
          data: speedData,
          options: chartOptions
        });
        document.getElementById('legendChartLines').innerHTML = lineChart.generateLegend();
      });
  })();
}
