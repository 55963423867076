if ($("#questionnaire").length) {
  // label для селекторов
  $('.field-questionnaireform-fm_hair_color').append('<label class="selector-label">Цвет волос</label>');
  $('.field-questionnaireform-fm_hair_length').append('<label class="selector-label">Длина волос</label>');
  $('.field-questionnaireform-fm_eye_color').append('<label class="selector-label">Цвет глаз</label>');
  $('.field-questionnaireform-sm_hair_color').append('<label class="selector-label">Цвет волос</label>');
  $('.field-questionnaireform-sm_hair_length').append('<label class="selector-label">Длина волос</label>');
  $('.field-questionnaireform-sm_eye_color').append('<label class="selector-label">Цвет глаз</label>');
  // label для селекторов end

  // select language
  $('#questionnaireform-english_level').keydown(function(e) {
      e.preventDefault();
      return false;
  });
  $('#questionnaireform-german_level').keydown(function(e) {
      e.preventDefault();
      return false;
  });
  $('#questionnaireform-french_level').keydown(function(e) {
      e.preventDefault();
      return false;
  });
  $('#questionnaireform-spanish_level').keydown(function(e) {
      e.preventDefault();
      return false;
  });

  jQuery(function($) {
    $('.block-select__selector').click(function() {
        $(this).next().toggle();
        $(this).addClass('active');
    });

    $(document).mouseup(function (e) {
        var block = $('.block-select__dropdown');
        if (!block.is(e.target) && block.has(e.target).length === 0) {
            block.hide();
            $('.block-select__selector').removeClass('active');
        }
    });

    var counter = 0;

    var inputEngLvl = $('#questionnaireform-english_level');
    var inputGerLvl = $('#questionnaireform-german_level');
    var inputFrenLvl = $('#questionnaireform-french_level');
    var inputSpanLvl = $('#questionnaireform-spanish_level');

    $('.row-select .block-select__menu-item').click(function() {
      var span = $(this).parent().parent().prev().children(':first');
      var dropdown =  $(this).parent().parent();
      var spanLng = $('.row-select .block-select-lng__selector').children(':first');
      var spanLngLvl = $('.row-select .block-select-lng-lvl__selector').children(':first');

      span.html($(this).html());
      span.removeClass('span-gray');
      dropdown.hide();

      if (!(spanLng.hasClass('span-gray') || spanLngLvl.hasClass('span-gray'))) {
        counter++;
        if ( dropdown.hasClass('block-select-lng-lvl__dropdown') ) {
          switch(spanLng.html()) {
            case 'Английский':
              inputEngLvl.val($(this).html());
              $('.eng').addClass('hide');
              $('.lng-container__row-eng .block-select-lng-lvl__selector').children(':first').html(inputEngLvl.val());
              $('.lng-container__row-eng').show();
              break;
            case 'Немецкий':
              inputGerLvl.val($(this).html());
              $('.ger').addClass('hide');
              $('.lng-container__row-ger .block-select-lng-lvl__selector').children(':first').html(inputGerLvl.val());
              $('.lng-container__row-ger').show();
              break;
            case 'Французский':
              inputFrenLvl.val($(this).html());
              $('.fren').addClass('hide');
              $('.lng-container__row-fren .block-select-lng-lvl__selector').children(':first').html(inputFrenLvl.val());
              $('.lng-container__row-fren').show();
              break;
            case 'Испанский':
              inputSpanLvl.val($(this).html());
              $('.span').addClass('hide');
              $('.lng-container__row-span .block-select-lng-lvl__selector').children(':first').html(inputSpanLvl.val());
              $('.lng-container__row-span').show();
              break;
          }
        } else {
          switch($(this).html()) {
            case 'Английский':
              inputEngLvl.val(spanLngLvl.html());
              $('.eng').addClass('hide');
              $('.lng-container__row-eng .block-select-lng-lvl__selector').children(':first').html(inputEngLvl.val());
              $('.lng-container__row-eng').show();
              break;
            case 'Немецкий':
              inputGerLvl.val(spanLngLvl.html());
              $('.ger').addClass('hide');
              $('.lng-container__row-ger .block-select-lng-lvl__selector').children(':first').html(inputGerLvl.val());
              $('.lng-container__row-ger').show();
              break;
            case 'Французский':
              inputFrenLvl.val(spanLngLvl.html());
              $('.fren').addClass('hide');
              $('.lng-container__row-fren .block-select-lng-lvl__selector').children(':first').html(inputFrenLvl.val());
              $('.lng-container__row-fren').show();
              break;
            case 'Испанский':
              inputSpanLvl.val(spanLngLvl.html());
              $('.span').addClass('hide');
              $('.lng-container__row-span .block-select-lng-lvl__selector').children(':first').html(inputSpanLvl.val());
              $('.lng-container__row-span').show();
              break;
          }
          $(this).addClass('hide');
        }
        spanLng.addClass('span-gray');
        spanLng.html('Язык');
        spanLngLvl.addClass('span-gray');
        spanLngLvl.html('Уровень');
        if (counter > 3 ) {
          $('.row-select').hide();
        }
      }
    });

    $('.delete-lng').click( function() {
      if($(this).parent().hasClass('lng-container__row-eng')) {
        $('.eng').removeClass('hide');
        inputEngLvl.val(null);
      } else if ($(this).parent().hasClass('lng-container__row-ger')) {
        $('.ger').removeClass('hide');
        inputGerLvl.val(null);
      } else if ($(this).parent().hasClass('lng-container__row-fren')) {
        $('.fren').removeClass('hide');
        inputFrenLvl.val(null);
      } else {
        $('.span').removeClass('hide');
        inputSpanLvl.val(null);
      }
      $(this).parent().hide();
      counter--;
      $('.row-select').show();
    });

    $('.lng-container__row .block-select-lng-lvl__dropdown .block-select__menu-item').click( function() {

    var rowCont =  $(this).parent().parent().parent().parent();

    if ( rowCont.hasClass('lng-container__row-eng')) {
      inputEngLvl.val($(this).html());
    } else if ( rowCont.hasClass('lng-container__row-ger') ) {
      inputGerLvl.val($(this).html());
    } else if ( rowCont.hasClass('lng-container__row-fren') ) {
      inputFrenLvl.val($(this).html());
    } else {
      inputSpanLvl.val($(this).html());
    };
    $(this).parent().parent().prev().children(':first').html($(this).html());
    $(this).parent().parent().hide();
  });

  $('.lng-container__row .block-select-lng__dropdown .block-select__menu-item').click( function() {;
    var lngWrap = $(this).parent().parent().parent().parent();
    var lngLvl = $(this).parent().parent().parent().next().children(':first').children(':first');
    switch($(this).html()) {
      case 'Английский':
        inputEngLvl.val(lngLvl.html());
        $('.lng-container__row-eng').show();
        $('.lng-container__row-eng').children(':first').next().children(':first').children(':first').html(lngLvl.html());
        $('.eng').addClass('hide');
        break;
      case 'Немецкий':
        inputGerLvl.val(lngLvl.html());
        $('.lng-container__row-ger').show();
        $('.lng-container__row-ger').children(':first').next().children(':first').children(':first').html(lngLvl.html());
        $('.ger').addClass('hide');
        break;
      case 'Французский':
        inputFrenLvl.val(lngLvl.html());
        $('.lng-container__row-fren').show();
        $('.lng-container__row-fren').children(':first').next().children(':first').children(':first').html(lngLvl.html());
        $('.fren').addClass('hide');
        break;
      case 'Испанский':
        inputSpanLvl.val(lngLvl.html());
        $('.lng-container__row-span').show();
        $('.lng-container__row-span').children(':first').next().children(':first').children(':first').html(lngLvl.html());
        $('.span').addClass('hide');
    }
    if (lngWrap.hasClass('lng-container__row-eng')) {
      inputEngLvl.val(null);
      $('.eng').removeClass('hide');
    } else if (lngWrap.hasClass('lng-container__row-ger')) {
      inputGerLvl.val(null);
      $('.ger').removeClass('hide');
    } else if (lngWrap.hasClass('lng-container__row-fren')) {
      inputFrenLvl.val(null);
      $('.fren').removeClass('hide');
    } else {
      inputSpanLvl.val(null);
      $('.span').removeClass('hide');
    }
    lngWrap.hide();
  })

  //отображение языков, если были ранее заполнены в анкете

  if($('#questionnaireform-english_level').val()) {
    counter++;
    $('.lng-container__row-eng').show();
    $('.eng').addClass('hide');
    $('.lng-container__row-eng').children('.block-select-lng-lvl').children(':first').children('span').html($('#questionnaireform-english_level').val());
  }
  if($('#questionnaireform-german_level').val()) {
    counter++;
    $('.lng-container__row-ger').show();
    $('.ger').addClass('hide');
    $('.lng-container__row-ger').children('.block-select-lng-lvl').children(':first').children('span').html($('#questionnaireform-german_level').val());
  }
  if($('#questionnaireform-french_level').val()) {
    counter++;
    $('.lng-container__row-fren').show();
    $('.fren').addClass('hide');
    $('.lng-container__row-fren').children('.block-select-lng-lvl').children(':first').children('span').html($('#questionnaireform-french_level').val());
  }
  if($('#questionnaireform-spanish_level').val()) {
    counter++;
    $('.lng-container__row-span').show();
    $('.span').addClass('hide');
    $('.lng-container__row-span').children('.block-select-lng-lvl').children(':first').children('span').html($('#questionnaireform-spanish_level').val());
  }

  if (counter > 3 ) {
    $('.row-select').hide();
  }
});
    //end select language
    //check input for style label
    const input = $('.form-group input');
    const inputEnterLogin = $('#loginform-username');
    const inputEnterPassword = $('#loginform-password');

    function checkInputRegistration(input) {
    if (input.val() !== undefined){
        $(input).addClass('active-label');
    } else $(input).removeClass('active-label');
    };

    function checkInput(input) {
//    if (input.val() > 0){
      if (input && input.val()){
        $(input).addClass('active-label');
    } else $(input).removeClass('active-label');
    };

    checkInput(input);
    checkInputRegistration(inputEnterLogin);
    checkInputRegistration(inputEnterPassword);

    $(inputEnterPassword).change(function() {
    if (inputEnterPassword.val() === undefined){
        $(inputEnterPassword).removeClass('active-label');
    }else $(inputEnterPassword).removeClass('active-label');
    });
    $(inputEnterLogin).change(function() {
    if (inputEnterLogin.val() !== undefined){
        $(inputEnterLogin).removeClass('active-label');
    }else $(inputEnterLogin).removeClass('active-label');
    });

    if ( $(inputEnterPassword).val() === "" ) {
        $(inputEnterPassword).addClass('active-label');
    } else $(inputEnterPassword).removeClass('active-label');
    if ($(inputEnterPassword).val() === ""){
        $(inputEnterLogin).addClass('active-label');
    } else $(inputEnterLogin).removeClass('active-label');
    //end check input for style label

    if ($('#start_send_ajax').length) {
      var timerId = setInterval(function() {
      data = 1;
      $.ajax({
        url         : '/user/ajaxcheckfullaccessloop',
        type        : 'POST',
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
             console.log(respond);
             if (respond == 'go_fourthtab') {
              window.location.replace('/user/fourthtab');
             }
             if (respond == 'go_home') {
              window.location.replace('/user/home');
             }
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        }

      });

      }, 10000);

      // через 24 часа остановить повторы
      setTimeout(function() {
        clearInterval(timerId);
      }, 60000 * 60 *24);
    }

  // скрытие блока добавления фотографий, когда их количество 12 
  if($('#addit_photo_12_wrap').is(':visible')) {
    $('#show_photo').hide()
  }
  $('#questionnaire').mouseover(() => {
    if($('#addit_photo_12_wrap').is(':visible')) {
      $('#show_photo').hide()
    }
  })
  $('#show_photo').click(() => {
    if($('#addit_photo_12_wrap').is(':visible')) {
      $('#show_photo').hide()
    }
  })
}
