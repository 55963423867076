$(".service-page .change-tribe-checkbox").change(function() {
  const checkbox = $(this);
  const parentCheckbox = checkbox.closest('.jq-checkbox');
  const tribeId = checkbox.val();
  const isChecked = checkbox.prop("checked");
  const isWork = isChecked ? 1 : 0;
  const formData = new FormData();

  parentCheckbox.addClass("disabled");
  formData.append('tribe_id', tribeId);
  formData.append('is_active', isWork);

  $.ajax({
    url: '/manager/ajaxupdatetribestatus',
    type: 'POST',
    data: formData,
    cache: false,
    dataType: 'json',
    processData: false,
    contentType: false,
    success: function(respond) {
      if (typeof respond.error === 'undefined') {
        parentCheckbox.removeClass("disabled");

        $.toast({
          heading: 'Success',
          text: 'Статус трайба обновлен',
          showHideTransition: 'slide',
          icon: 'success'
        });
        setTimeout(function(){
          window.location.reload();
        }, 1000);
      } else {
        console.log('ОШИБКА: ' + respond.error);
        parentCheckbox.removeClass("disabled");
      }
    },
    error: function(jqXHR, status) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      parentCheckbox.removeClass("disabled");

      $.toast({
        heading: '',
        text: 'Произошла ошибка',
        showHideTransition: 'fade',
        icon: 'error'
      });
    }
  });
});