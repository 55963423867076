const input = $('.form-group input');
const inputEnterLogin = $('#loginform-username');
const inputEnterPassword = $('#loginform-password');

// function checkInput(input) {
//   if (input.val() !== ""){
//     $(input).addClass('active-label');
//   } else $(input).removeClass('active-label');
// };

$(".form-group input").focus(function(){
  const inputBlock = $(this).parent();
  const inputError = inputBlock.find(".help-block-error");
  inputError.css("margin-top", "26px");
});
$('.style-label input').change(function(){
  if ($(this).val() !== ''){
    $(this).addClass('active-label');
  }else $(this).removeClass('active-label');
});

function checkInput() {
  const input = $('.style-label input');
  input.removeClass('not-empty-input');
  input.each(function(elem){
    if ($(this).val() !== ''){
      $(this).addClass('not-empty-input');
    }
  })
}

$('.style-label input').change(function(){
  $(this).removeClass('not-empty-input');
});
setTimeout(checkInput(), 300);

