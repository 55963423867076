if ($("#superadmin-create-cope-function").length){
  $('.ajax_create_copy_record').click(function() {
    let className = $(this).parent().attr('class');
    var data = new FormData();
    data.append( 'classname', className );
    //console.log(data);
    $.ajax({
    url         : '/adminka/default/ajaxcreaterecordcopy',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        console.log(respond);
        if (confirm('Перейти к редактированию созданой записи ?')) {
          window.location.replace(respond); 
        }else{
          console.log('stay here');
          window.location.reload();
        }


      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      $.toast({
            heading: 'Error',
            text: 'Неизвестная ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
    }

    });
  });  
}