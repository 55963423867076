$(() => {
    if ($("#singlemodel").length) {
        const assignEducationBtn = $('#add-request-for-coaching-assign');
        const reconciliationBtn = $('.manager-model__single-reconciliation-btn');

        $('#frozen-status').click(function () {
            let setStatus;
            if ($(this).is(':checked')) {
                setStatus = 0;
            } else {
                setStatus = 1;
            }
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var data = new FormData();
            data.append('modelId', modelId);
            data.append('setStatus', setStatus);
            $.ajax({
                url: '/manager/ajaxchangefrozenstatus',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Статус успешно изменен',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        $('#control-status').click(function () {
            var status = $(this).is(':checked') ? 0 : 1;
            if (status) {
                $('.modal-box-add-for-quality-control').toggle();
            } else {
                $('.modal-box-add-for-quality-control').hide();

                var get = window.location.search.replace('?', '');
                var modelId = parseInt(get.substr(3));
                var data = new FormData();
                data.append('modelId', modelId);
                data.append('setStatus', status);
                $.ajax({
                    url: '/manager/ajaxchangequalitycontrolstatus',
                    type: 'POST', // важно!
                    data: data,
                    cache: false,
                    dataType: 'json',
                    // отключаем обработку передаваемых данных, пусть передаются как есть
                    processData: false,
                    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                    contentType: false,
                    // функция успешного ответа сервера
                    success: function (respond, status, jqXHR) {

                        if (typeof respond.error === 'undefined') {
                            $.toast({
                                heading: 'Success',
                                text: 'Статус успешно изменен',
                                showHideTransition: 'slide',
                                icon: 'success'
                            });
                        }
                        // ошибка
                        else {
                            console.log('ОШИБКА: ' + respond.error);
                        }
                    },
                    // функция ошибки ответа сервера
                    error: function (jqXHR, status, errorThrown) {
                        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                        $.toast({
                            heading: 'Error',
                            text: 'Произошла ошибка',
                            showHideTransition: 'fade',
                            icon: 'error'
                        });
                    }
                });
            }
        });

        $('#addRequestForComputerAssistance').click(function () {
            var urlParams = new URLSearchParams(window.location.search);
            var modelId = urlParams.get('id');
            var text = $('#computerAssistance').val();

            var data = new FormData();
            data.append('user_id', modelId);
            data.append('text', text);

            $.ajax({
                url: '/manager/ajaxcreatecomputerassistanceticket',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Заявка отправлена',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });

                    $('.modal-box-computer-assistance').hide();
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function (jqXHR, status) {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading: 'Error',
                    text: 'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
            }
        });
    });

        $('#addRequestForQualityControl').click(function () {
        var get = window.location.search.replace('?', '');
        var modelId = parseInt(get.substr(3));
        var text = $('#commentForQualityControl').val();
        var data = new FormData();
        data.append('modelId', modelId);
        data.append('setStatus', '1');
        data.append('text', text);
        $.ajax({
            url: '/manager/ajaxchangequalitycontrolstatus',
            type: 'POST', // важно!
            data: data,
            cache: false,
            dataType: 'json',
            // отключаем обработку передаваемых данных, пусть передаются как есть
            processData: false,
            // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
            contentType: false,
            // функция успешного ответа сервера
            success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Статус успешно изменен',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });

                        $('.modal-box-add-for-quality-control').hide();
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        function showRegistrarModal() {

            var status = $(this).is(':checked') ? 0 : 1;
            var modalBoxAddForRegistrar = $('.modal-box-add-for-registrar');

            if (status === 1) {
                modalBoxAddForRegistrar.toggle();
            }
            if (status === 0 && modalBoxAddForRegistrar.is(':visible')) {
                modalBoxAddForRegistrar.hide();
            }
            if (status === 0) {
                modalBoxAddForRegistrar.hide();

                var get = window.location.search.replace('?', '');
                var modelId = parseInt(get.substr(3));
                var data = new FormData();

                data.append('user_id', modelId);

                $.ajax({
                    url: '/manager/ajaxdeleteregistrarticket',
                    type: 'POST',
                    data: data,
                    cache: false,
                    dataType: 'json',
                    processData: false,
                    contentType: false,
                    success: function (respond) {
                        if (typeof respond.error === 'undefined') {
                            $.toast({
                                heading: 'Success',
                                text: 'Заявка удалена',
                                showHideTransition: 'slide',
                                icon: 'success'
                            });

                            $('.modal-box-computer-assistance').hide();
                        } else {
                            console.log('ОШИБКА: ' + respond.error);
                        }
                    },
                    error: function (jqXHR, status) {
                        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                        $.toast({
                            heading: 'Error',
                            text: 'Произошла ошибка',
                            showHideTransition: 'fade',
                            icon: 'error'
                        });
                    }
                });
            }
        }

        $('input[name=priority-status]').click(function () {
            let setStatus = $(this).val();

            $('.priority-level').text(setStatus);

            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var data = new FormData();
            data.append('modelId', modelId);
            data.append('setStatus', setStatus);
            $.ajax({
                url: '/manager/ajaxchangeprioritystatus',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Статус успешно изменен',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        $('#permanent-holiday-status').click(function () {
            var status = $(this).is(':checked') ? 0 : 1;
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));

            var data = new FormData();
            data.append('model_id', modelId);
            data.append('status', status);

            $.ajax({
                url: '/manager/ajaxchangepermanentholiday',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: status ? 'Постоянный отпуск проставлен' : 'Постоянный отпуск удален',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        $('#on-coaching-status').click(function () {
            var status = $(this).is(':checked') ? 0 : 1;
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));

            var data = new FormData();
            data.append('model_id', modelId);
            data.append('status', status);

            $.ajax({
                url: '/manager/ajaxchangeoncoaching',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: status ? 'На коучинге' : 'Снята с коучинга',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        $('#holiday-status').click(function () {
            var status = $(this).is(':checked') ? 0 : 1;
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var data = new FormData();

            data.append('model_id', modelId);
            data.append('status', status);

            var firstIntervalDate = getFirstIntervalDates();
            var secondIntervalDate = getSecondIntervalDates();
            var dateFrom;
            var dateTo;
            if ($('#period-inteval').prop('checked')) {
                dateFrom = firstIntervalDate;
                dateTo = secondIntervalDate;
            } else {
                dateFrom = getOnlyFirstIntervalBegan();
                dateTo = getOnlyFirstIntervalEnd();
            }

            data.append('date_from', dateFrom);
            data.append('date_to', dateTo);

            $.ajax({
                url: '/manager/ajaxchangeholiday',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                success: function (respond) {
                    $('span.holiday-period').text(status ? 'отпуск' : '');

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: status ? 'Отпуск проставлен' : 'Отпуск удален',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        $('#for-registrar-status').click(showRegistrarModal);

        $('#addRequestForRegistrar').click(function () {
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var text = $('#commentForRegistrar').val();
            var data = new FormData();

            for (var i = 1; i <= 14; i++) {
                var checkbox = document.getElementById('registrar-site_' + i);
                if (checkbox.checked) {
                    data.append('sites[]', checkbox.attributes['data-site'].value)
                }
            }

            data.append('user_id', modelId);
            data.append('status', 'pending');
            data.append('text', text);
            $.ajax({
                url: '/manager/ajaxcreateregistrarticket',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Статус успешно изменен',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });

                        $('.modal-box-add-for-registrar').hide();
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $.toast({
                        heading: 'Error',
                        text: 'Произошла ошибка',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });

        function handleStreamAjaxRequestOnClick() {
            console.log('handleStreamAjaxRequestOnClick');
            const selectedSites = $('.manager-main__stream-site').find($('input:checked'));
            if (selectedSites.length === 0) {
                $.toast({
                    heading: 'Error',
                    text: 'Please select a site',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
            } else {
                const siteArr = [];
                selectedSites.each(function () {
                    siteArr.push($(this).data('site'));
                });
                const day = $('#select-current-day-styler .jq-selectbox__select-text').text();
                const minute = Number($('.ajax_stream_minute').val());
                const hour = Number($('.ajax_stream_hour').val());
                if (isNaN(minute) || isNaN(hour) || minute > 59 || hour > 23) {
                    $.toast({
                        heading: 'Error',
                        text: 'Hours must be <= 23 and minutes must be <= 59',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                } else if (hour === 0 && minute === 0) {
                    $.toast({
                        heading: 'Error',
                        text: 'Time must not be zero',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                } else {
                    const queryString = window.location.search.replace('?', '');
                    const modelId = parseInt(queryString.substr(3));
                    const dt = `${day} ${hour}:${minute}:00`;
                    const dt0 = `${day} 00:00:00`;
                    const data = new FormData();

                    data.append('modelId', modelId);
                    data.append('dt', dt);
                    data.append('dt0', dt0);
                    data.append('s', siteArr.join(','));

                    $.ajax({
                        url: '/manager/ajaxaddstream',
                        type: 'POST',
                        data: data,
                        cache: false,
                        dataType: 'json',
                        processData: false,
                        contentType: false,
                        success: function (respond, status, jqXHR) {
                            if (typeof respond.error === 'undefined') {
                                $.toast({
                                    heading: 'Success',
                                    text: 'Session added',
                                    showHideTransition: 'slide',
                                    icon: 'success'
                                });
                            } else {
                                console.log('ERROR: ' + respond.error);
                            }
                        },
                        error: function (jqXHR, status, errorThrown) {
                            console.log('AJAX request ERROR: ' + status, jqXHR);
                            $.toast({
                                heading: 'Error',
                                text: 'An error occurred',
                                showHideTransition: 'fade',
                                icon: 'error'
                            });
                        }
                    });
                }
            }
        }

        $('#addStream').on('click', handleStreamAjaxRequestOnClick);

        $(document).on("change", ".assist-operator-checkbox", function () {
            const checkboxStatus = this.checked;
            let date = $(this).parent().prop('className').replace('assist-operator-checkbox-', '');
            const modelId = new URLSearchParams(window.location.search).get('id');

            var data = new FormData();
            data.append('modelId', modelId);
            data.append('status', checkboxStatus);
            data.append('date', date);

            $.ajax({
                url: '/manager/ajaxchangeshiftoperatorstatus',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                async: false,
                success: function (respond, status, jqXHR) {
                    date = date.split('-');
                    date.shift();
                    date = date.join('_');

                    var incomeElement = $('.singlemodel__money').find('td.' + date);
                    var daySumWithOperator = parseFloat(incomeElement.html());
                    var operatorTotalSum = $('#assist-operator-sum');
                    if (incomeElement.length === 1) {
                        var result = checkboxStatus ? (parseFloat(operatorTotalSum.text()) + daySumWithOperator) : (parseFloat(operatorTotalSum.text()) - daySumWithOperator);
                        operatorTotalSum.text(parseFloat(result).toFixed(2));
                    }
                }
            });
        });

        $(document).on("change", "#two-phones", function () {
            const checkboxStatus = this.checked;
            const modelId = new URLSearchParams(window.location.search).get('id');

            let data = new FormData();
            data.append('status', checkboxStatus);
            data.append('modelId', modelId);

            $.ajax({
                url: '/manager/ajaxupdateincreasedoperatorassistcoef',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                async: false
            });
        });

        $("#constuctor-pay").change(function () {
            if ($("#constuctor-pay").prop("checked")) {
                $(".singlemodel__site").show();
            } else {
                $(".singlemodel__site").hide();
            }
        });

        $("#constuctor-kpi").change(function () {
            if ($("#constuctor-kpi").prop("checked")) {
                $(".singlemodel__KPI").show();
            } else {
                $(".singlemodel__KPI").hide();
            }
        });

        $("#constuctor-online").change(function () {
            if ($("#constuctor-online").prop("checked")) {
                $(".singlemodel__online").show();
            } else {
                $(".singlemodel__online").hide();
            }
        });

        $(".constuctor-btn").click(function () {
            $(".manager-model__single-constructor-block").show();
        });

        $(".close-constructor-block").click(function () {
            $(".manager-model__single-constructor-block").hide();
        });

        function showBeganIntervalDate() {
            const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
            const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

            const beganOfPeriod = $("#period-select-date-styler .sel").text();
            const beganIntervalDay = beganOfPeriod.split('-');
            const beganIntervalMonth = beganOfPeriod.slice(5);

            if (beganIntervalYear === endIntervalYear) {
                $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + "\u00A0");
                $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod);
            } else {
                $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
                $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod);
                $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
            }
        }

        function showEndIntervalDate() {
            const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
            const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

            const endOfPeriod = $("#period-select-date-second-styler .sel").text();
            const endIntervalDay = endOfPeriod.split('-');
            const endIntervalMonth = endOfPeriod.slice(5);

            if (beganIntervalYear === endIntervalYear) {
                $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
            } else {
                $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
            }
        }

        function getFirstIntervalDates() {
            const firstIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();

            const firstIntervalSelect = $('#period-select-date-styler .jq-selectbox__select-text').text();
            const firstIntervalName = $('#period-select-date option:selected').attr('name');
            const firstIntervalArr = firstIntervalSelect.split('-');
            const firstIntervalDay = firstIntervalArr[0]; //day began
            const firstIntervalMonth = firstIntervalName.split('.'); //month number
            const firstIntervalDate = firstIntervalYear + '-' + firstIntervalMonth[0] + '-' + firstIntervalDay;

            return firstIntervalDate;
        }

        function getSecondIntervalDates() {
            const secondIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

            const secondIntervalSelect = $('#period-select-date-second-styler .jq-selectbox__select-text').text();
            const secondIntervalName = $('#period-select-date-second option:selected').attr('name');
            const secondIntervalArr = secondIntervalSelect.split('-');
            const secondIntervalDay = secondIntervalArr[1].slice(0, 2);
            const secondIntervalMonth = secondIntervalName.split('.'); //month number
            const secondIntervalDate = secondIntervalYear + '-' + secondIntervalMonth[0] + '-' + secondIntervalDay;

            return secondIntervalDate;
        }

        function getOnlyFirstIntervalBegan() {
            const firstIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();

            const firstIntervalSelect = $('#period-select-date-styler .jq-selectbox__select-text').text();
            const firstIntervalName = $('#period-select-date option:selected').attr('name');
            const firstIntervalArr = firstIntervalSelect.split('-');
            const firstIntervalDay = firstIntervalArr[0]; //day began
            const firstIntervalMonth = firstIntervalName.split('.'); //month number

            const from = firstIntervalYear + '-' + firstIntervalMonth[0] + '-' + firstIntervalDay;

            return from;
        }

        function getOnlyFirstIntervalEnd() {
            const firstIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();

            const firstIntervalSelect = $('#period-select-date-styler .jq-selectbox__select-text').text();
            const firstIntervalName = $('#period-select-date option:selected').attr('name');
            const firstIntervalArr = firstIntervalSelect.split('-');
            const firstIntervalMonth = firstIntervalName.split('.'); //month number

            const to = firstIntervalYear + '-' + firstIntervalMonth[0] + '-' + firstIntervalArr[1].slice(0, 2);

            return to;
        }

        function getDiffBetweenDates() {
            const firstIntervalDate = getFirstIntervalDates();
            const secondIntervalDate = getSecondIntervalDates();
            const onlyFirstIntervalBegan = getOnlyFirstIntervalBegan();
            const onlyFirstIntervalEnd = getOnlyFirstIntervalEnd();

            const firstIntervalDateSplit = firstIntervalDate.split('-');
            const secondIntervalDateSplit = secondIntervalDate.split('-');
            const onlyFirstIntervalBeganSplit = onlyFirstIntervalBegan.split('-');
            const onlyFirstIntervalEndSplit = onlyFirstIntervalEnd.split('-');

            let now = {};
            let end = {};


            if ($("#period-inteval").prop("checked")) {
                now = moment([firstIntervalDateSplit[0], firstIntervalDateSplit[1] - 1, firstIntervalDateSplit[2]]);
                end = moment([secondIntervalDateSplit[0], secondIntervalDateSplit[1] - 1, secondIntervalDateSplit[2]]);
            } else {
                now = moment([onlyFirstIntervalBeganSplit[0], onlyFirstIntervalBeganSplit[1] - 1, onlyFirstIntervalBeganSplit[2]]);
                end = moment([onlyFirstIntervalEndSplit[0], onlyFirstIntervalEndSplit[1] - 1, onlyFirstIntervalEndSplit[2]]);
            }


            const duration = moment.duration(end.diff(now));
            const days = (duration.asDays()) + 1;

            return days;
        }

        function generateTable() {

            const firstIntervalDate = getFirstIntervalDates();
            const secondIntervalDate = getSecondIntervalDates();

            let from = '';
            let to = '';
            if ($("#period-inteval").prop("checked")) {
                from = firstIntervalDate;
                to = secondIntervalDate;
            } else {
                from = getOnlyFirstIntervalBegan();
                to = getOnlyFirstIntervalEnd();
            }
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var data = new FormData();
            data.append('modelId', modelId);
            data.append('from', from);
            data.append('to', to);
            $.ajax({
                url: '/user/ajaxgetdatafinanceoldversion',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {
                    if (respond == 'error') {
                        $('#ajax_on_hand').text(' ');
                        $('#ajax_on_handwithbonus').text(' ');
                        $('#ajax_pay_level').text(' ');
                        $('#ajax_pay_levelbonus').text(' ');
                        hideEmptyTr();
                        $.toast({
                            heading: 'Error',
                            text: 'За данный период нет данных',
                            showHideTransition: 'fade',
                            icon: 'error'
                        });
                        createVoidTableSinglemodel();
                    } else if (typeof respond.error === 'undefined') {
                        $('span.holiday-period').text(respond[0]['holiday'] === 1 ? 'отпуск' : '');
                        $('#holiday-status').prop('checked', respond[0]['holiday'] === 1).trigger('refresh');

                        fillTheTable(respond);
                        hideEmptyTr();
                        calculateMonthSum();
                        calculateDaysum(respond[0]['sum_day']);
                        claculateResult();
                        updateAssistOperatorIncome()
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $('#ajax_on_hand').text(' ');
                    $('#ajax_on_handwithbonus').text(' ');
                    $('#ajax_pay_level').text(' ');
                    $('#ajax_pay_levelbonus').text(' ');
                    hideEmptyTr();
                    $.toast({
                        heading: 'Error',
                        text: 'За данный период нету данных',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                    createVoidTableSinglemodel();
                }
            });

        }

        $('#ajax_send_document').on('change', function () {
            files = this.files;
            $(".preloader").fadeIn();
            let type = 'document';
            var get = window.location.search.replace('?', '');
            let user_id = get.substr(3);
            if (typeof files == 'undefined') return;
            let data = new FormData();
            $.each(files, function (key, value) {
                data.append(key, value);
            });
            data.append('type', type);
            data.append('user_id', user_id);
            $.ajax({
                url: '/manager/ajaxuploaddocument',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {
                    $('.preloader').fadeOut('slow', function () {
                    });
                    let div = $('<div style="width: 25%;"></div>');
                    let img = $('<img style="width: 100%"/>').attr('src', respond.url);
                    let but = $('<a href="" class="ajax_remove_document">Удалить</a>').attr('data-id', respond.id);
                    but.on('click', removeDocument);
                    div.append(img);
                    div.append(but);
                    $("#singlemodel-documents").append(div);
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $('.preloader').fadeOut('slow', function () {
                    });
                    $('.ajax_send_photo').prop("value", "");
                    $.toast({
                        heading: '',
                        text: 'Ошибка сервера. Попробуйти повторить попытку позже.',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }

            });
        });

        $('#ajax_send_document_photo').on('change', function () {
            files = this.files;
            $(".preloader").fadeIn();
            let type = 'photo';
            var get = window.location.search.replace('?', '');
            let user_id = get.substr(3);
            if (typeof files == 'undefined') return;
            let data = new FormData();
            $.each(files, function (key, value) {
                data.append(key, value);
            });
            data.append('type', type);
            data.append('user_id', user_id);

            $.ajax({
                url: '/manager/ajaxuploaddocument',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {
                    $('.preloader').fadeOut('slow', function () {
                    });
                    let div = $('<div style="width: 25%;"></div>');
                    let img = $('<img style="width: 100%"/>').attr('src', respond.url);
                    let but = $('<a href="" class="ajax_remove_document">Удалить</a>').attr('data-id', respond.id);
                    but.on('click', removeDocument);
                    div.append(img);
                    div.append(but);
                    $("#singlemodel-document-photos").append(div);
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $('.preloader').fadeOut('slow', function () {
                    });
                    $('.ajax_send_photo').prop("value", "");
                    $.toast({
                        heading: '',
                        text: 'Ошибка сервера. Попробуйти повторить попытку позже.',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }

            });
        });

        $('.ajax_remove_document').on('click', removeDocument);

        function removeDocument() {
            let data = new FormData();
            let id = $(this).data().id;
            data.append('id', id);

            $.ajax({
                url: '/manager/ajaxremovedocument',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {
                    $('.ajax_remove_document[data-id=' + respond.id + ']').parent().remove();
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $('.ajax_send_photo').prop("value", "");
                    $.toast({
                        heading: '',
                        text: 'Ошибка сервера. Попробуйти повторить попытку позже.',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }

            });
        }

        function createVoidTableSinglemodel() {
            if ($('#period-inteval').prop('checked')) {
                const days = getDiffBetweenDates();
                $('.singlemodel__all tr').each(function () {
                    $('td', this).each(function (elem) {
                        if (elem !== 0) {
                            $(this).remove();
                        }
                    })
                })
                for (let f = 0; f <= days; f++) {
                    $('.singlemodel__all tr').append("<td>-</td>");
                }
            } else {
                const month = $("#period-select-date-styler .jq-selectbox__select-text").text();
                const dayArr = month.split('-');
                $('.singlemodel__all tr').each(function () {
                    $('td', this).each(function (elem) {
                        if (elem !== 0) {
                            $(this).remove();
                        }
                    })
                })
                for (let f = dayArr[0]; f <= parseInt(dayArr[1]) + 1; f++) {
                    $('.singlemodel__all tr').append("<td>-</td>");
                }
            }
        }

        function claculateResult() {
            var cashBlock = $('.singlemodel_site_1 tr:nth-child(1) td');
            var cashSum = 0;
            var timeSum = 0;
            var kpiSum = 0;
            for (var i = 2; i <= cashBlock.length - 1; i++) {
                var cash = Number($('.singlemodel__all tr:nth-child(3) td:nth-child(' + i + ')').text());
                var time = Number($('.singlemodel__all tr:nth-child(1) td:nth-child(' + i + ')').attr('data-minute'));
                var kpi = Number($('.singlemodel__all tr:nth-child(2) td:nth-child(' + i + ')').text());
                if (isNaN(time)) {
                    time = 0;
                }
                cashSum = cashSum + cash;
                timeSum = timeSum + time;
                kpiSum = kpiSum + kpi;
            }
            var t = String(Math.trunc(timeSum / 60)).padStart(2, "0") + ':' + String(Math.trunc(timeSum % 60)).padStart(2, "0");
            var delimetr = cashBlock.length - 1;
            middleArifmetic = kpiSum / delimetr;
            $('.singlemodel__all tr:nth-child(3) td:nth-child(' + cashBlock.length + ')').text(cashSum.toFixed(2));
            $('.singlemodel__all tr:nth-child(1) td:nth-child(' + cashBlock.length + ')').text(t);
            $('.singlemodel__all tr:nth-child(1) td:nth-child(' + cashBlock.length + ')').attr('data-minute', timeSum.toFixed(2));
            $('.singlemodel__all tr:nth-child(1) td:nth-child(' + cashBlock.length + ')').attr('data-time', (timeSum / 60).toFixed(2));
            $('.singlemodel__all tr:nth-child(2) td:nth-child(' + cashBlock.length + ')').text(middleArifmetic.toFixed(2));
        }

        function calculateDaysum(sum_day) {
            var allDays = $('.singlemodel_site_1 tr:nth-child(1) td');
            for (var j = 2; j <= allDays.length - 1; j++) {
                var dayCash = 0;
                var dayTime = 0;
                var dayKpi = 0;
                for (var i = 1; i <= 14; i++) {
                    var daySiteCash = $('.singlemodel_site_' + i + ' .singlemodel__site td:nth-child(' + j + ')').text();
    //          daySiteTime = $('.singlemodel_site_'+i+' .singlemodel__online td:nth-child('+j+')').attr('data-minute');
                    daySiteKpi = $('.singlemodel_site_' + i + ' .singlemodel__KPI td:nth-child(' + j + ')').text();
    //	  if (isNaN(daySiteTime)) {daySiteTime = 0;}
                    //        dayTime = Number(daySiteTime) + dayTime;
                    dayCash = Number(daySiteCash) + dayCash;
                    dayKpi = Number(daySiteKpi) + dayKpi;
                }
                var day = $('.singlemodel__all .singlemodel__online td:nth-child(' + j + ')').attr('class');
                dayTime = sum_day[day];
                if (isNaN(dayTime)) {
                    dayTime = 0;
                }

                var t = String(Math.trunc(dayTime / 60)).padStart(2, "0") + ':' + String(Math.trunc(dayTime % 60)).padStart(2, "0");
                $('.singlemodel__all .singlemodel__online td:nth-child(' + j + ')').text(t);
                $('.singlemodel__all .singlemodel__online td:nth-child(' + j + ')').attr('data-minute', dayTime.toFixed(2));
                $('.singlemodel__all .singlemodel__online td:nth-child(' + j + ')').attr('data-time', (dayTime / 60).toFixed(2));
                $('.singlemodel__all .singlemodel__money td:nth-child(' + j + ')').text(dayCash.toFixed(2));
                $('.singlemodel__all .singlemodel__KPI td:nth-child(' + j + ')').text((dayKpi / 5).toFixed(2));
            }
        }

        function calculateMonthSum() {
            for (var j = 1; j <= 14; j++) {
                var cashBlock = $('.singlemodel_site_1 tr:nth-child(1) td');
                var cashSum = 0;
                var timeSum = 0;
                var kpiSum = 0;
                for (var i = 2; i <= cashBlock.length - 1; i++) {
                    var cash = Number($('.singlemodel_site_' + j + ' tr:nth-child(1) td:nth-child(' + i + ')').text());
                    var time = Number($('.singlemodel_site_' + j + ' tr:nth-child(2) td:nth-child(' + i + ')').attr('data-minute'));
                    var kpi = Number($('.singlemodel_site_' + j + ' tr:nth-child(3) td:nth-child(' + i + ')').text());
                    if (isNaN(time)) {
                        time = 0;
                    }
                    cashSum = cashSum + cash;
                    timeSum = timeSum + time;
                    kpiSum = kpiSum + kpi;
                }
                var delimetr = cashBlock.length - 1;
                middleArifmetic = kpiSum / delimetr;
                var t = String(Math.trunc(timeSum / 60)).padStart(2, "0") + ':' + String(Math.trunc(timeSum % 60)).padStart(2, "0");
                $('.singlemodel_site_' + j + ' tr:nth-child(1) td:nth-child(' + cashBlock.length + ')').text(cashSum.toFixed(2));
                $('.singlemodel_site_' + j + ' tr:nth-child(2) td:nth-child(' + cashBlock.length + ')').text(t);
                $('.singlemodel_site_' + j + ' tr:nth-child(2) td:nth-child(' + cashBlock.length + ')').attr('data-minute', timeSum.toFixed(2));
                $('.singlemodel_site_' + j + ' tr:nth-child(2) td:nth-child(' + cashBlock.length + ')').attr('data-time', (timeSum / 60).toFixed(2));
                $('.singlemodel_site_' + j + ' tr:nth-child(3) td:nth-child(' + cashBlock.length + ')').text(middleArifmetic.toFixed(2));
            }
        }

        function fillTheTable(respond) {
            for (var i = 0; i < respond.length; i++) {
                var key_array_datetime = respond[i].date_start.split(' ');
                var key_array_date = key_array_datetime[0].split('-');
                var key = key_array_date[2] + '_' + key_array_date[1];
                //console.log(key);
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(1)').children('td:nth-child(1)').text('Заработок на ' + respond[i].site_name);
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(2)').children('td:nth-child(1)').text('Время онлайн на  ' + respond[i].site_name);
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(3)').children('td:nth-child(1)').text('KPI на  ' + respond[i].site_name);
                var time = 0;
                if (isNaN(respond[i].time)) {
                    time = 0;
                } else {
                    time = parseInt(respond[i].time);
                }
                // var time = parseInt(respond[i].time);
                var t = String(Math.trunc(time / 60)).padStart(2, "0") + ':' + String(Math.trunc(time % 60)).padStart(2, "0");
                var time = time / 60;
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(1)').children('.' + key + '').text(respond[i].cash);
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(2)').children('.' + key + '').text(t);
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(2)').children('.' + key + '').attr('data-time', time.toFixed(2));
                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(2)').children('.' + key + '').attr('data-minute', parseInt(respond[i].time));
                var kpi = respond[i].cash / time;
                if (!isFinite(kpi)) {
                    kpi = 0;
                }

                $('.singlemodel_site_' + respond[i].site_id + ' tr:nth-child(3)').children('.' + key + '').text(kpi.toFixed(2));
            }
            // $('#ajax_on_hand').text(respond[0].total_cash.toFixed(2));
            $('#ajax_on_handwithbonus').text(respond[0].total_cashwithbonus.toFixed(2));
            if ($("#period-inteval").prop("checked")) {
                $('#ajax_pay_level').text(' ');
                $('#ajax_pay_levelbonus').text(' ');
            } else {
                $('#ajax_pay_level').text(respond[0].pay_level);
                $('#ajax_pay_levelbonus').text(respond[0].pay_levelbonus);
            }
        }

        function hideEmptyTr() {
            for (var i = 1; i <= 14; i++) {
                var check = $('.singlemodel_site_' + i).children('tr:nth-child(1)').children('td:nth-child(1)');
                if (check.text() == '') {
                    check.parent().parent().hide();
                } else {
                    check.parent().parent().show();
                }
            }
        }

        $('#buildTableBtn').click(function () {
            getDiffBetweenDates();
            generateTable();
            createTh();
            createTbody();
        });

        $(document).ready(setTimeout(function () {
            getDiffBetweenDates();
            generateTable();
            createTh();
            createTbody();
            convertHeicImages();
        }, 1500));

        function createTh() {
            const tableTh = $('.manager-model__single-info-table thead th');
            const tableThead = $('.manager-model__single-info-table thead tr');
            const firstIntervalDate = getFirstIntervalDates();
            const firstIntervalDateSplit = firstIntervalDate.split('-');

            tableTh.remove();
            tableThead.append("<th>Дата</th>");

            const countDays = getDiffBetweenDates();
            const days = [firstIntervalDateSplit[0], firstIntervalDateSplit[1] - 1, firstIntervalDateSplit[2]];
            for (let i = 0; i < countDays; i++) {
                const date = moment(days).add(i, 'days');
                const date2 = moment(date).format('DD.MM');
                tableThead.append("<th>" + date2 + "</th>");
            }

            tableThead.append("<th>Итого</th>");
        }

        function convertHeicImages() {
            var images = document.getElementsByTagName("img");

            for (var i = 0; i < images.length; i++) {
                let image = images[i];
                let imageSrc = image.src;

                var fileNameExt = imageSrc.substr(imageSrc.lastIndexOf('.') + 1).toLowerCase();
                if (fileNameExt === "heic") {
                    fetch(imageSrc)
                        .then((res) => res.blob())
                        .then((blob) => heic2any({blob}))
                        .then((conversionResult) => {
                            image.src = URL.createObjectURL(conversionResult)
                        })
                        .catch((e) => {
                            console.log(e)
                        });
                }
            }
        }

        function getDatesOfCurrentTable() {
            const firstIntervalDateSplit = getFirstIntervalDates().split('-');
            const days = [firstIntervalDateSplit[0], firstIntervalDateSplit[1] - 1, firstIntervalDateSplit[2]];

            let dates = [];
            for (let i = 0; i < getDiffBetweenDates(); i++) {
                dates.push($('#select-period-year-styler .jq-selectbox__select-text').text() + '-' + moment(days).add(i, 'days').format('DD-MM'));
            }

            return dates;
        }

        function updateAssistOperatorIncome() {
            $.get({
                url: '/manager/ajaxgetshiftoperatordates',
                async: false,
                data: {modelId: new URLSearchParams(window.location.search).get('id')},
                dataType: 'json',
                cache: false,
                success: function (respond) {
                    let sumWithOperator = 0;
                    const currentDates = getDatesOfCurrentTable();
                    respond.forEach(function (operatorAssistDate) {
                        if (currentDates.includes(operatorAssistDate)) {
                            operatorAssistDate = operatorAssistDate.split('-');
                            operatorAssistDate.shift();
                            operatorAssistDate = operatorAssistDate.join('_');
                            let incomeElement = $('.singlemodel__money').find("td." + operatorAssistDate)
                            if (incomeElement.length === 1) {
                                sumWithOperator += parseFloat(incomeElement.html());
                            }
                        }
                    });
                    $("#assist-operator-sum").text(parseFloat(sumWithOperator).toFixed(2));
                }
            });
        }

        function changeAssistOperatorCheckboxesStatus() {
            $.get({
                url: '/manager/ajaxgetshiftoperatordates',
                async: false,
                data: {modelId: new URLSearchParams(window.location.search).get('id')},
                dataType: 'json',
                cache: false,
                success: function (respond) {
                    respond.forEach(date => $(".assist-operator-checkbox-" + date).children().prop('checked', true));
                }
            });
        }

        function createTbody() {
            const firstIntervalDate = getFirstIntervalDates();
            const firstIntervalDateSplit = firstIntervalDate.split('-');

            const countDays = getDiffBetweenDates();
            const days = [firstIntervalDateSplit[0], firstIntervalDateSplit[1] - 1, firstIntervalDateSplit[2]];

            $('.manager-model__single-info-table tr td').remove();

            $('.singlemodel-checkboxes-row tr').each(function () {
                $(this).append('<td></td>');
                for (i = 0; i < countDays; i++) {
                    const date = moment(days).add(i, 'days');
                    const date2 = moment(date).format('YYYY-DD-MM');
                    $(this).append('<td class = "assist-operator-checkbox-' + date2 + '"><input type="checkbox" class="assist-operator-checkbox"></td>');
                }
                $(this).append('<td></td>');
            });

            changeAssistOperatorCheckboxesStatus();

            $('.singlemodel-checkboxes-row tr:nth-child(1)').children('td:nth-child(1)').text("С оператором").addClass('assist-operator-text');

            $('.singlemodel_site-body tr').each(function () {
                $(this).append('<td></td>');
                for (i = 0; i < countDays; i++) {
                    const date = moment(days).add(i, 'days');
                    const date2 = moment(date).format('DD_MM');
                    $(this).append('<td class = "' + date2 + '">0</td>');
                }
                $(this).append('<td></td>');
            });
            $('.singlemodel__all tr').each(function () {
                $(this).append('<td></td>');
                for (i = 0; i < countDays; i++) {
                    const date = moment(days).add(i, 'days');
                    const date2 = moment(date).format('DD_MM');
                    $(this).append('<td class="' + date2 + '"></td>');
                }
                $(this).append('<td></td>');
            });

            $('.singlemodel__all tr td:first-child').text('Общее время онлайн');
            $('.singlemodel__all tr:nth-child(2) td:first-child').text('Общий KPI');
            $('.singlemodel__all tr:last-child td:first-child').text('Общий заработок');
        }

        $("#period-select-date").change(function () {
            if ($("#period-inteval").prop("checked")) {
                showBeganIntervalDate();
            } else {
                const beganOfPeriod = $("#period-select-date-styler .sel").text();
                $(".began-period").text(beganOfPeriod + "\u00A0");
                $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod);
            }
        });

        $("#period-select-date-second").change(function () {
            showEndIntervalDate();
        });

        $('.manager-main__period-select-year').change(function () {
            showBeganIntervalDate();
            showEndIntervalDate();
        });

        $("#period-inteval").change(function () {
            if ($("#period-inteval").prop("checked")) {
                $(".manager-main__period-select-second").css("display", "flex");
                $(".manager-main__period-select").css("height", "140px");
                $(".end-period").css("display", "inline-block");
                showBeganIntervalDate();
                showEndIntervalDate();
            } else {
                $(".manager-main__period-select-second").css("display", "none");
                $(".manager-main__period-select").css("height", "81px");
                $(".end-period").css("display", "none");
            }
        });

        $(".manager-model__single-payment-btn").click(function () {
            $(".modal-box.history-pay").toggle();
        });

        //plan show
        $(".manager-main__plan-btn").click(function () {
            $(".show-modal .modal-box").toggle();
        });

        $('#modelCommonMenuItem').addClass('current-page');

        $("#tabs").tabs();

        $("#tabs").tabs({
            active: 0
        });

        $(".approve").click(function () {
            const thisApprove = $(this);
            const thisNoApprove = thisApprove.parent().find(".no-approve");
            thisApprove.addClass("disabled");
            thisNoApprove.removeClass("disabled");
            thisApprove.removeClass("active");
            thisNoApprove.addClass("active");
        });

        $(".no-approve").click(function () {
            const thisNoApprove = $(this);
            const thisApprove = thisNoApprove.parent().find(".approve");
            thisNoApprove.addClass("disabled");
            thisApprove.removeClass("disabled");
            thisNoApprove.removeClass("active");
            thisApprove.addClass("active");
        });

        $(".manager-model__single-chat-icon").click(function () {
            $(".chat-messenger-block").toggle();
            $(".chat-messenger-svg").toggle();
            $(".chat-close-svg").toggle();
        });

        $(".work-with-sites__lk-status-btn").click(function () {
            const thisLink = $(this);
            const thisStatus = $(this).parent().parent().find(".work-with-sites__lk-status");
            if (thisLink.text() === "Активировать") {
                thisLink.text("Деактивировать");
                thisStatus.text("Активен");
            } else {
                thisLink.text("Активировать");
                thisStatus.text("Неактивен");
            }
        });

        (function () {
            const statusBtn = $(".work-with-sites__lk-status").parent().find("work-with-sites__lk-status-btn");
            if ($(".work-with-sites__lk-status").text() === "Неактивен") {
                statusBtn.text("Активировать");
            } else statusBtn.text("Дективировать");
        })();

        function getSrc(btn) {
            const photoImg = btn.parent().find(".manager-model__single-work-photo img");
            const src = photoImg.prop('src');
            btn.prop("href", src);
        };

        $(".superuser-singlemodel__single-download-photo").click(function () {
            const downloadBtn = $(this);
            getSrc(downloadBtn);
        });

        $(".make-big-photo").click(function () {
            const bigPhotoBtn = $(this);
            getSrc(bigPhotoBtn);
        });

        $(document).ready(function () {
            $(".make-big-photo").fancybox({
                padding: 0,
                helpers: {
                    overlay: {
                        locked: false
                    }
                }
            });
        });

        const constructorInput = $('.manager-model__single-constructor-block input');
        constructorInput.change(function () {
            let counter = 0;
            constructorInput.each(function () {
                if ($(this).prop('checked')) {
                    counter++;
                }
                if (counter < 2) {
                    $('.manager-model__single-constructor-block .jq-checkbox.checked').addClass('disabled');
                } else $('.manager-model__single-constructor-block .jq-checkbox').removeClass('disabled');
            })
        });

        $('.manager-model__single-delete-img-svg').click(function () {
            var sure = confirm('Вы уверены что хотите удалить это фото ?');
            if (sure) {
                var img = $(this).parent().parent().children('img');
                var get = window.location.search.replace('?', '');
                var modelId = parseInt(get.substr(3));
                var position = img.prop('name');
                var data = new FormData();
                var blockWithPhoto = $(this).parent().parent().parent();
                data.append('modelId', modelId);
                data.append('position', position);

                $.ajax({
                    url: '/manager/ajaxdeletemodelphoto',
                    type: 'POST',
                    data: data,
                    cache: false,
                    dataType: 'json',
                    // отключаем обработку передаваемых данных, пусть передаются как есть
                    processData: false,
                    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                    contentType: false,
                    // функция успешного ответа сервера
                    success: function (respond, status, jqXHR) {

                        if (typeof respond.error === 'undefined') {
                            img.prop('src', '/upload/photo-default.png')
                            blockWithPhoto.hide();
                        }
                        // ошибка
                        else {
                            console.log('ОШИБКА: ' + respond.error);
                        }
                    },
                    // функция ошибки ответа сервера
                    error: function (jqXHR, status, errorThrown) {
                        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    }

                });


            }
        });

        $('.manager-model__single-approve-btn').click(function () {
            let data = new FormData();
            let get = window.location.search.replace('?', '');
            let modelId = parseInt(get.substr(3));
            let comment = $('#commentForDocument').val();

            let docPhotosObject = {};
            for (let i = 1; i <= 10; i++) {
                docPhotosObject[i] = $('#doc_photo' + i).length === 1 ? $('#doc_photo' + i).hasClass('disabled') : true;
            }

            if (Object.values(docPhotosObject).indexOf(false) > -1) {
                if (comment === '') {
                    $.toast({
                        heading: 'Error',
                        text: 'Заполните поле комментарий',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });

                    return false;
                }
            }

            data.append('modelId', modelId);
            data.append('docPhotos', JSON.stringify(docPhotosObject));
            data.append('comment', comment);

            $.ajax({
                url: '/manager/ajaxgetmodelfullaccess',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {
                    $('.preloader').fadeOut();
                    if (typeof respond.res.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Данные о фото сохранены',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                        if (respond.first == 1) {
                            showRegistrarModal();
                        }
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }
            });


        });

        if ($('.toggle-btns').length === 6) {
            $('#documentWork .manager-model__single-work-block-photo:nth-child(2)').css('margin', '0 51px');
        }

        $('.manager-model__single-stream-btn').click(function () {
            $('.modal-box.history-stream').toggle();
        });

        $('.manager-model__single-stream-add-btn').click(function () {
            $('.modal-box-add-stream').toggle();
        });

        $('.manager-model__single-computer-assistance-btn').click(function () {
            $('.modal-box-computer-assistance').toggle();
        });

        $('.manager-model__single-assign-coaching-btn').click(function () {
            $('.modal-box-coaching-assign').toggle();
        });

        $('.new-model-coaching__btn-change').click(function () {
            $('.modal-box-coaching-assign').toggle();
        });

        $('.work-with-sites__save').click(function () {
            var login = $(this).parent().parent().children('div:nth-child(1)').children('div:nth-child(2)').children('div.site_model_login').children('input').val();
            var password = $(this).parent().parent().children('div:nth-child(1)').children('div:nth-child(2)').children('div.site_model_password').children('input').val();
            var nick = $(this).parent().parent().children('div:nth-child(1)').children('div:nth-child(2)').children('div.site_model_nick').children('input').val();
            var keystream = $(this).parent().parent().children('div:nth-child(1)').children('div:nth-child(2)').children('div.site_model_key').children('input').val();
            var url = $(this).parent().parent().children('div:nth-child(1)').children('div:nth-child(2)').children('div.site_model_url').children('input').val();
            if (login == '' || password == '' || nick == '') {
                $.toast({
                    heading: 'Error',
                    text: 'Заполните поля логин пароль ник',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
                return false;
            }
            var siteId = $(this).parent().parent().children('div:nth-child(1)').children('div:nth-child(1)').children().attr('name');
            var accountNumber = $(this).data('accountnumber');
            var apiToken = $('#api_token_'.concat(siteId, '_', accountNumber)).length ? $('#api_token_'.concat(siteId, '_', accountNumber)).val() : '';
            if ($(this).parent().parent().children('div:nth-child(2)').children('div:nth-child(1)').children().children('input').is(':checked')) {
                var photo = 1;
            } else {
                var photo = 0;
            }
            ;
            if ($(this).parent().parent().children('div:nth-child(2)').children('div:nth-child(2)').children().children('input').is(':checked')) {
                var video = 1;
            } else {
                var video = 0;
            }
            ;
            if ($(this).parent().parent().children('div:nth-child(2)').children('div:nth-child(3)').children().children('input').is(':checked')) {
                var text = 1;
            } else {
                var text = 0;
            }
            ;
            if ($(this).parent().parent().children('div:nth-child(2)').children('div:nth-child(4)').children().children('input').is(':checked')) {
                var page = 1;
            } else {
                var page = 0;
            }
            ;
            if ($(this).parent().parent().children('div:nth-child(2)').children('div:nth-child(5)').children().children('input').is(':checked')) {
                var funclub = 1;
            } else {
                var funclub = 0;
            }
            ;
            if ($.trim($(this).parent().parent().children('div:nth-child(3)').children('div:nth-child(1)').children('span').text()) == 'Активен') {
                var isActive = 1;
            } else {
                var isActive = 0
            }
            ;
            var data = new FormData();
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            data.append('modelId', modelId);
            data.append('accountNumber', accountNumber);
            if (login) {
                data.append('login', login);
            }
            if (password) {
                data.append('password', password);
            }
            data.append('nick', nick);
            data.append('siteId', siteId);
            data.append('photo', photo);
            data.append('video', video);
            data.append('text', text);
            data.append('page', page);
            data.append('funclub', funclub);
            data.append('isActive', isActive);
            data.append('keyStream', keystream);
            data.append('url', url);
            data.append('apiToken', apiToken);

            $.ajax({
                url: '/manager/ajaxcreatenewmodelsitedata',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.res.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Данные успешно сохранены',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                        if (respond.old == 0 && isActive == 1) {
                            var site_name = "";
                            if (siteId == '1') site_name = "JAS";
                            if (siteId == '2') site_name = "CHA";
                            if (siteId == '3') site_name = "MYF";
                            if (siteId == '4') site_name = "BON";
                            if (siteId == '5') site_name = "IML";
                            if (siteId == '6') site_name = "STR";
                            if (siteId == '7') site_name = "CAM4";
                            if (siteId == '8') site_name = "MYF";
                            if (siteId == '9') site_name = "SOD";
                            if (siteId == '10') site_name = "CHR";
                            if (siteId == '11') site_name = "STM";
                            if (siteId == '12') site_name = "XLC";
                            if (siteId == '13') site_name = "F4F";
                            if (siteId == '14') site_name = "CON";
                        }

                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.res.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }

            });
        });

        $('.work_with_site_save_obs').click(function () {
            var key = $('.ajax_obs_key').val();
            if (key == '') {
                $.toast({
                    heading: 'Error',
                    text: 'Заполните обязательное поле key',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
                return false;
            }
            var data = new FormData();
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            data.append('modelId', modelId);
            data.append('key', key);
            $.ajax({
                url: '/manager/ajaxsavemodelobskey',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Данные успешно сохранены',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }

            });
        });

        $(".manager-model__single-comment-lasttab-save").click(function () {
            var comment = $(this).parent().children('div:nth-child(1)').children('div:nth-child(1)').children('textarea').val();
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var data = new FormData();
            data.append('modelId', modelId);
            data.append('comment', comment);
            $.ajax({
                url: '/manager/ajaxcreatemodelnote',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Заметка успешно добавлена',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }

            });
        });

        $(".manager-model__single-quality-control-note-save").click(function () {
            var text = $(this).parent().children('div:nth-child(1)').children('div:nth-child(1)').children('textarea').val();
            var queryParameters = window.location.search.replace('?', '');
            var userId = parseInt(queryParameters.substr(3));

            var data = new FormData();
            data.append('user_id', userId);
            data.append('text', text);

            $.ajax({
                url: '/manager/ajaxsavequalitycontrolnote',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Заметка успешно добавлена',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }
            });
        });

        $(".manager-model__single-chat-operator-note-save").click(function () {
            var text = $(this).parent().children('div:nth-child(1)').children('div:nth-child(1)').children('textarea').val();
            var queryParameters = window.location.search.replace('?', '');
            var userId = parseInt(queryParameters.substr(3));

            var data = new FormData();
            data.append('user_id', userId);
            data.append('text', text);

            $.ajax({
                url: '/manager/ajaxsavechatoperatornote',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Заметка успешно добавлена',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }
            });
        });

        $(".manager-model__single-technical-specialist-note-save").click(function () {
            var text = $(this).parent().children('div:nth-child(1)').children('div:nth-child(1)').children('textarea').val();
            var queryParameters = window.location.search.replace('?', '');
            var userId = parseInt(queryParameters.substr(3));

            var data = new FormData();
            data.append('user_id', userId);
            data.append('text', text);

            $.ajax({
                url: '/manager/ajaxsavetechnicalspecialistnote',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Заметка успешно добавлена',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }
            });
        });

        $(".manager-model__single-registrar-note-save").click(function () {
            var text = $(this).parent().children('div:nth-child(1)').children('div:nth-child(1)').children('textarea').val();
            var queryParameters = window.location.search.replace('?', '');
            var userId = parseInt(queryParameters.substr(3));

            var data = new FormData();
            data.append('user_id', userId);
            data.append('text', text);

            $.ajax({
                url: '/manager/ajaxsaveregistrarnote',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond) {
                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Заметка успешно добавлена',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    } else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                error: function (jqXHR, status) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }
            });
        });

        function checkRegistrationSitesInfo() {
            $('.work-with-sites__checkbox input').each(function () {
                const dateOfDownload = $(this).parent().find(".work-with-sites__download-date");
                if ($(this).prop("checked")) {
                    dateOfDownload.show();
                } else dateOfDownload.hide();
            });
        }

        function changeViewDownload() {
            const dateOfDownload = $(".work-with-sites__download-date");
            dateOfDownload.each(function () {
                const thisDateOfDownload = $(this).text();
                if (thisDateOfDownload.length > 10) {
                    const datesDownloadArr = thisDateOfDownload.split(',');
                    if (datesDownloadArr[0] == "" || datesDownloadArr[1] == "") {
                        $(this).text("");
                    } else {
                        const firstDownload = datesDownloadArr[0].split('-');
                        const secondDownload = datesDownloadArr[1].split('-');
                        const newFirstDownload = firstDownload[2] + '.' + firstDownload[1] + '.' + firstDownload[0];
                        let newSecondDownload = secondDownload[2] + '.' + secondDownload[1] + '.' + secondDownload[0];
                        newSecondDownload = newSecondDownload.replace(/\s/g, "");
                        const newDatesDownload = newFirstDownload + ', ' + newSecondDownload;
                        $(this).text(newDatesDownload);
                    }

                } else {
                    const dateDownload = thisDateOfDownload.split('-');
                    if (dateDownload[0] === "") {
                        $(this).text("");
                    } else {
                        const newDateDownload = dateDownload[2] + '.' + dateDownload[1] + '.' + dateDownload[0];
                        $(this).text(newDateDownload);
                    }
                }
            });
        }

        checkRegistrationSitesInfo();

        changeViewDownload();

        $('.work-with-sites__checkbox input').change(function () {
            if ($(this).prop("checked")) {
                dateOfDownload.show();
            } else dateOfDownload.hide();

            changeViewDownload();
        });


        $('#period-select-date-plan-singlemodel-page').change(function () {
            var attrname = $('#period-select-date-plan-singlemodel-page option:selected').attr('name');
            var year = $('#select-period-year-second-plan option:selected').val();
            var dateArr = attrname.split('.');
            var date = year + '-' + dateArr[0] + '-' + dateArr[1];
            var get = window.location.search.replace('?', '');
            var modelId = parseInt(get.substr(3));
            var data = new FormData();
            data.append('modelId', modelId);
            data.append('date', date);
            $.ajax({
                url: '/manager/ajaxgetmodelplanforperiodinsinglemodel',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        //console.log(respond);
                        $('.model_plan_cash_value').empty();
                        $('.model_plan_cash_value').append(respond);
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                    $('.model_plan_cash_value').empty();
                    $('.model_plan_cash_value').append('---');
                }
            });
        });


        $('#ajax_change_model_time_full_access').change(function () {
            let timeFullAccess = 0;
            let modelId = window.location.search.replace('?id=', '');
            if ($(this).is(':checked')) {
                timeFullAccess = 1;
            }
            var data = new FormData();
            data.append('timeFullAccess', timeFullAccess);
            data.append('modelId', modelId);
            $.ajax({
                url: '/manager/ajaxchangemodeltimefullaccess',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $.toast({
                            heading: 'Success',
                            text: 'Временный доступ изменен',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                }

            });
        });


        $("#urgent-payment").on("change", function () {
            $("input[name=prepayment-summ]").keyup();

            let earnings = $('.model_earnings');

            if ($("#urgent-payment").is(':checked')) {
                let balance = Number(earnings.data('value') + $('#currentEarnings').data('value')).toFixed(2);
                earnings.text(balance + '$');
            } else {
                earnings.text(earnings.data('value') + '$');
            }
        });

        $("input[name=prepayment-summ]").on('keyup', function () {
            let data = new FormData();
            data.append('sum', $(this).val());
            data.append('from', 'modelfinance');
            data.append('urgentPaymentCheckboxStatus', $("#urgent-payment").is(':checked'));

            $.ajax({
                url: '/user/ajaxorderearlypaymentcommission',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $('.preloader').fadeOut('slow', function () {
                        });
    //		console.log(respond);
                        let com = $('.commission_label');
                        if (respond.result) {
                            com.html('Комиссия платежной системы составит ' + respond.com + '$');
                        } else {
                            com.html('');
                        }
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                        $('.commission_label').html('');
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    $('.commission_label').html('');
                    console.log(jqXHR);
                }
            });
        });

        $(".prepayment-apply").click(function () {
            $(".preloader").fadeIn();
            const sum = $('input[name=prepayment-summ]').val();
            if (!sum) {
                $.toast({
                    heading: '',
                    text: 'Укажите сумму выплаты',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
                $('.preloader').fadeOut('slow', function () {
                });

            return false;
        } else if (sum < 15) {
            $.toast({
                heading: '',
                text: 'Сумма досрочной выплаты не может быть меньше 40$',
                showHideTransition: 'fade',
                icon: 'error'
            });
            $('.preloader').fadeOut('slow', function () {
            });

                return false;
            }
            let modelId = parseInt(window.location.search.replace('?', '').substr(3));
            let data = new FormData();
            data.append('sum', sum);
            data.append('model_id', modelId);
            data.append('from', 'singlemodel');
            data.append('urgentPaymentCheckboxStatus', $("#urgent-payment").is(':checked'));
            $.ajax({
                url: '/user/ajaxorderearlypayment',
                type: 'POST', // важно!
                data: data,
                cache: false,
                dataType: 'json',
                // отключаем обработку передаваемых данных, пусть передаются как есть
                processData: false,
                // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
                contentType: false,
                // функция успешного ответа сервера
                success: function (respond, status, jqXHR) {

                    if (typeof respond.error === 'undefined') {
                        $('.preloader').fadeOut('slow', function () {
                        });
                        $.toast({
                            heading: 'Success',
                            text: 'Досрочная выплата успешно заказана',
                            showHideTransition: 'slide',
                            icon: 'success'
                        });
                        $(".prepayment-hide").hide();
                        $(".application-approve").show();
                    }
                    // ошибка
                    else {
                        console.log('ОШИБКА: ' + respond.error);
                        $('.preloader').fadeOut('slow', function () {
                        });
                    }
                },
                // функция ошибки ответа сервера
                error: function (jqXHR, status, errorThrown) {
                    console.log(jqXHR);
                    $('.preloader').fadeOut('slow', function () {
                    });
                    $.toast({
                        heading: '',
                        text: 'Сумма досрочной выплаты превышает сумму двух ближайших плановых выплат',
                        showHideTransition: 'fade',
                        icon: 'error'
                    });
                }
            });
        });


        function handleEducationAssignAjaxOnClick(modelId, dateInput, timeInput, textInput, sessionTitle) {
            console.log('handleCoachingAssignAjaxRequestOnClick');

            if (!dateInput || !timeInput || !modelId || !sessionTitle) {
                $.toast({
                    heading: 'Error',
                    text: 'Please provide both date and time',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
            } else {
                const dateTime = `${dateInput} ${timeInput}:00`;

                const jsonData = {
                    dateTime: dateTime,
                    modelId: modelId,
                    comment: textInput,
                    sessionTitle: sessionTitle
                };

                $.ajax({
                    url: '/manager/ajaxeducationassign',
                    type: 'POST',
                    data: JSON.stringify(jsonData),
                    cache: false,
                    dataType: 'json',
                    processData: false,
                    contentType: 'application/json',
                    success: function (respond, status, jqXHR) {
                        if (typeof respond.error === 'undefined') {
                            $.toast({
                                heading: 'Success',
                                text: 'Data sent successfully',
                                showHideTransition: 'slide',
                                icon: 'success'
                            });
                        } else {
                            console.log('ERROR: ' + respond.error);
                        }
                    },
                    error: function (jqXHR, status, errorThrown) {
                        console.log('AJAX request ERROR: ' + status, jqXHR);
                        $.toast({
                            heading: 'Error',
                            text: 'An error occurred',
                            showHideTransition: 'fade',
                            icon: 'error'
                        });
                    }
                });
            }
        }

        assignEducationBtn.on('click', function () {
            const modelId = $('.manager-model__single-info-title').data('id');
            const dateInput = $('#add-coaching-assign-date').val();
            const timeInput = $('#add-coaching-assign-time').val();
            const textInput = $('#add-coaching-assign-text').val().replace(/"/g, '\\"');
            const sessionTitle = $('#add-coaching-assign-title').val();

            handleEducationAssignAjaxOnClick(modelId, dateInput, timeInput, textInput, sessionTitle);
        });

        function handleModelFinancialReconciliation(modelId) {
            if (!modelId) {
                $.toast({
                    heading: 'Error',
                    text: 'Please provide id',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
            } else {
                const jsonData = {
                    modelId: modelId,
                };

                $.ajax({
                    url: '/manager/ajaxmodelfinancialreconciliation',
                    type: 'POST',
                    data: JSON.stringify(jsonData),
                    cache: false,
                    dataType: 'json',
                    processData: false,
                    contentType: 'application/json',
                    success: function (response) {
                        if (response.error) {
                            $.toast({
                                heading: 'Error',
                                text: response.error,
                                showHideTransition: 'fade',
                                icon: 'error'
                            });
                        } else if (response.data !== undefined) {
                            $.toast({
                                heading: 'Success',
                                text: 'Сумма сверки: ' + response.data,
                                showHideTransition: 'slide',
                                icon: 'success',
                                hideAfter: 15000
                            });
                        } else {
                            $.toast({
                                heading: 'Info',
                                text: 'Received unexpected response',
                                showHideTransition: 'fade',
                                icon: 'info'
                            });
                        }
                    },
                    error: function (jqXHR, status, errorThrown) {
                        console.log('AJAX request ERROR: ' + status, jqXHR);
                        $.toast({
                            heading: 'Error',
                            text: 'An error occurred',
                            showHideTransition: 'fade',
                            icon: 'error'
                        });
                    }
                });
            }
        }

        reconciliationBtn.on('click', function () {
            const modelId = $('.manager-model__single-info-title').data('id');

            handleModelFinancialReconciliation(modelId);
        });

        /* Код для переключения статуса стримов. Галочки во вкладке работа с сайтами в singlemodel */
        const streamCheckboxes = $('.single-model__is-active-stream-checkbox');
        const streamCheckboxesWrappers = $('.work-with-sites__checkbox.workcab');

        /*взято из workcab.js*/
        streamCheckboxes.click(function () {
            const modelId = $('.manager-model__single-info-title').data('id');
            var is_stream_active;
            var check = $(this);
            if ($(this).prop("checked") == true) {
                is_stream_active = 0;
            } else if ($(this).prop("checked") == false) {
                is_stream_active = 1;
            }

            var site_id = $(this).data("tag");
            var data = new FormData();
            data.append("site_id", site_id);
            data.append("is_stream_active", is_stream_active);
            data.append("model_id", modelId);
            $.ajax({
                url: "/manager/ajaxchangestatusstream",
                type: "POST",
                data: data,
                cache: false,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (respond, status, jqXHR) {
                    if (typeof respond.error === "undefined") {
                        $.toast({
                            heading: "Success",
                            text: "Статус изменен",
                            showHideTransition: "slide",
                            icon: "success",
                        });
                    }
                    else {
                        if(is_stream_active == 0 ) check.prop('checked', true); else check.prop('checked', false);
                        check.trigger("change");
                        console.log("Ошибка: " + respond.error);
                        $.toast({
                            heading: "Error",
                            text: "Ошибка: " + respond.error,
                            showHideTransition: "fade",
                            icon: "error",
                        });
                    }
                },
                error: function (jqXHR, status, errorThrown) {
                    if(is_stream_active == 0 )check.prop('checked', true); else check.prop('checked', false);
                    check.trigger("change");
                    console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
                    $.toast({
                        heading: "",
                        text: "Ошибка",
                        showHideTransition: "fade",
                        icon: "error",
                    });
                },
            });
        });

        const timerStreamId = setInterval(function () {
            const modelId = $('.manager-model__single-info-title').data('id');
            console.log(modelId);

            $.ajax({
                url: '/manager/ajaxcheckactivestreamonline',
                type: 'POST',
                data: JSON.stringify({ modelId: modelId }),
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: 'application/json; charset=utf-8',
                success: function (respond) {
                    if (respond.online === true) {
                        streamCheckboxesWrappers.addClass("disabled");
                    } else if (respond.error) {
                        console.log(respond.error);
                    } else {
                        streamCheckboxesWrappers.removeClass("disabled");
                    }

                },
                error: function (jqXHR, status, errorThrown) {
                    console.log("ОШИБКА AJAX запроса: " + status, jqXHR);
                }
            });
        }, 15000);

        setTimeout(function () {
            clearInterval(timerStreamId);
        }, 60000 * 60 * 5);

        /* Конец кода для переключения статуса стримов. Галочки во вкладке работа с сайтами в singlemodel */


        //-----end-of-file-----
    }
});

