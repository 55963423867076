if ($('#superuserManagers').length) {
  $('#superuserManagersLi').addClass('current-page');

   //constructor work
   $(".constuctor-btn").click(function(){
    $(".superuser-managers__constructor-block").show();
  });
  $(".close-constructor-block").click(function(){
    $(".superuser-managers__constructor-block").hide();
  });
  jQuery(function($){
    $(document).mouseup(function (e){
        const block = $(".superuser-managers__constructor-block");
        if (!block.is(e.target)
            && block.has(e.target).length === 0) {
            block.hide();
        }
    });
  });

  $("#constuctor-contacts").change(function(){
    if ($("#constuctor-contacts").prop("checked")){
      $(".superuser-managers__table-phone").show();
      $(".superuser-managers__table-rocket-chat-login").show();
      $(".superuser-managers__table-mail").show();
    }else{
      $(".superuser-managers__table-phone").hide();
      $(".superuser-managers__table-rocket-chat-login").hide();
      $(".superuser-managers__table-mail").hide();
    }
  });
  $("#constuctor-birthday").change(function(){
    if ($("#constuctor-birthday").prop("checked")){
      $(".superuser-managers__table-birthday").show();
    }else{
      $(".superuser-managers__table-birthday").hide();
    }
  });
  $("#constuctor-hide-frozen").change(function(){
    if ($("#constuctor-hide-frozen").prop("checked")){
      $(".frozen_manager_row").hide();
    }else{
      $(".frozen_manager_row").show();
    }
  });

  //manager period
  function showBeganIntervalDate() {
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const beganOfPeriod = $("#period-select-date-styler .sel").text();
    const beganIntervalDay = beganOfPeriod.split('-');
    const beganIntervalMonth = beganOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    } else {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
      $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
    }
  }

  function showEndIntervalDate(){
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const endOfPeriod = $("#period-select-date-second-styler .sel").text();
    const endIntervalDay = endOfPeriod.split('-');
    const endIntervalMonth = endOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
    } else {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
    }
  }

  $("#period-inteval").change(function(){
    if ($("#period-inteval").prop("checked")){
      $(".superuser-managers__period-select-second").css("display", "flex");
      $(".superuser-managers__period-select").css("height", "140px");
      $(".end-period").css("display", "inline-block");
      $(".superuser-managers__top").css("height", "200px");
      showBeganIntervalDate();
      showEndIntervalDate();
    }else {
      $(".superuser-managers__period-select-second").css("display", "none");
      $(".superuser-managers__period-select").css("height", "81px");
      $(".end-period").css("display", "none");
      $(".superuser-managers__top").css("height", "140px");
    }
  });

  $("#period-select-date").change(function(){
    if ($("#period-inteval").prop("checked")){
      showBeganIntervalDate();
    } else {
      const beganOfPeriod = $("#period-select-date-styler .sel").text();
      $(".began-period").text(beganOfPeriod + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    }
  });

  $("#period-select-date-second").change(function(){
    showEndIntervalDate();
  });

  $('.superuser-managers__period-select-year').change(function(){
    showBeganIntervalDate();
    showEndIntervalDate();
  });

  //plan show
  $(".superuser-managers__plan-btn").click(function(){
    $(".show-modal .modal-box").toggle();
    document.querySelector(".show_new_manager_photo_create").src = "../web/public/img/managers-photo.jpg";
  });

  function setSelectOnManagerUpdateWindow(currency, paymentSystem, country) {
    var textOption = 0;
    var optionSystem = 0;
    $('#update_manager_currency option').each(function(){
    if ($(this).val() === currency){
      $(this).attr("selected", true);
      textOption = $(this).text();
    }
    });
    $("#update_manager_currency-styler li").each(function(){
      $(this).removeClass('selected sel');
      if ($(this).text() === textOption){
        $("#update_manager_currency-styler").children('.jq-selectbox__select').children('.jq-selectbox__select-text').text(textOption);
        $(this).addClass('selected sel');
      }
    });

    if (country !== '') {
      $('#select2-update_manager_country-container').text(country);
      $("#update_manager_country-styler li").each(function() {
        $(this).removeClass('selected sel');
        if ($(this).text() === country) {
          $(this).addClass('selected sel');
        }
      });
    }

    $('#update_manager_payment_system option').each(function(){
    if ($(this).val() === paymentSystem){
      $(this).attr("selected", true);
      optionSystem = $(this).text();
    }
    });
    $("#update_manager_payment_system-styler li").each(function(){
      $(this).removeClass('selected sel');
      if ($(this).text() === optionSystem){
        $("#update_manager_payment_system-styler").children('.jq-selectbox__select').children('.jq-selectbox__select-text').text(optionSystem);
        $(this).addClass('selected sel');
      }
    });
  }

  $('.superuser-managers__table tr td a').click(function(e){
    e.stopPropagation();
  });

  $('.superuser-managers__table tr').click(function(){
    var manager_id = $(this).attr('name');
    var fullname = $(this).children('.superuser-managers__table-name').find('.fio').text().trim();
    arr = fullname.split(' ');
    var firstName = arr[0];
    var lastName = arr[1];
    var phone = $(this).children('.superuser-managers__table-phone').text();
    var rocketChatLogin = $(this).children('.superuser-managers__table-rocket-chat-login').text();
    var telegram = $(this).children('.superuser-managers__table-name').children('.get_manager_telegram').text().trim();
    var login = $(this).children('.superuser-managers__table-mail').text();
    var birthDate = $(this).children('.superuser-managers__table-birthday').text().trim();
    var studioHome = $(this).children('.superuser-managers__table-place').text();
    var mainPhotoUrl =$(this).children('.superuser-managers__table-name').children('.get_main_photo_url').text();
    var secondMainPhotoUrl =$(this).children('.superuser-managers__table-name').children('.get_second_main_photo_url').text();
    var thirdMainPhotoUrl =$(this).children('.superuser-managers__table-name').children('.get_third_main_photo_url').text();
    var videoUrl =$(this).children('.superuser-managers__table-name').children('.get_video_url').text();
    var fullAccess = $(this).children('.superuser-managers__table-name').children('.get_manager_full_access_here').text();
    var currency = $(this).children('.superuser-managers__table-name').children('.get_manager_currency_here').text();
    var paymentSystem = $(this).children('.superuser-managers__table-name').children('.get_manager_payment_system_here').text();
    var paymentSystemNumber = $(this).children('.superuser-managers__table-name').children('.get_manager_payment_system_number_here').text();
    var country = $(this).children('.superuser-managers__table-name').children('.get_manager_country').text().trim();

    var managerType = $(this).children('.superuser-managers__table-name').children('.get_manager_type').text().trim();
    var tribe       = $(this).children('.superuser-managers__table-name').children('.get_manager_tribe').text().trim();

    $('.change_manager_type').each(function(){
      $(this).removeClass('checked');
    })
    $('.change_manager_tribe').each(function(){
      $(this).removeClass('checked');
    })

    $('#change_manager_type_' + managerType + '-styler').addClass('checked');
    if (tribe !== '') {
      $('#change_manager_tribe_' + tribe + '-styler').addClass('checked');
    } else {
      $('#change_manager_tribe_' + tribe + '-styler').removeClass('checked');
    }
    if ($('#change_manager_type_' + managerType + '-styler input')[0].dataset.tribeList) {
      $('#change_manager_tribe_block').css("display", "block");
    } else {
      $('#change_manager_tribe_block').css("display", "none");
    }

    $('.ajax_update_payment_system_number').val(paymentSystemNumber);
    setSelectOnManagerUpdateWindow(currency, paymentSystem, country);
    fullAccess = Number(fullAccess.trim());
    $('.manager_id_for_change_data').text(manager_id);
    $('.superuser-managers__modal-photo').children('img').attr('src', mainPhotoUrl);
    $('.superuser-managers__modal-first_photo').children('img').attr('src', secondMainPhotoUrl);
    $('.superuser-managers__modal-second_photo').children('img').attr('src', thirdMainPhotoUrl);

    if (videoUrl.length > 0) {
      const video = document.getElementById('show_updated_manager_video_video')
      video.src = videoUrl;
      video.style.display = "block";
    }

    $('.ajax_change_manager_last_name').val(lastName);
    $('.ajax_change_manager_login').val(login);
    if (studioHome == 'home') {
      $('#change_manager_studio-styler').removeClass('checked');
      $('#change_manager_home-styler').addClass('checked');
      $('#change_manager_home').attr('checked','checked');
    }else {
      $('#change_manager_home-styler').removeClass('checked');
      $('#change_manager_studio-styler').addClass('checked');
      $('#change_manager_studio').attr('checked','checked');
    }

    if (fullAccess == 1) {
      $('#change_manager_open').attr('checked','checked');
      $('#change_manager_open-styler').addClass('checked');
      $('#change_manager_closed-styler').removeClass('checked');
    }else {
      $('#change_manager_closed').attr('checked','checked');
      $('#change_manager_closed-styler').addClass('checked');
      $('#change_manager_open-styler').removeClass('checked');
    }
    $('.ajax_change_manager_first_name').val(firstName);
    $('.ajax_change_manager_birth_date').val(birthDate);
    $('.ajax_change_manager_phone').val(phone);
    $('.ajax_change_manager_rocket-chat-login').val(rocketChatLogin);
    $('.ajax_change_manager_telegram').val(telegram);
    $('#changeManagerModal').show();

    const input = $('.style-label input');
    input.removeClass('not-empty-input');
    input.each(function(elem){
      if ($(this).val() !== ''){
        $(this).addClass('not-empty-input');
      }
    })
  });

  $('.ajax_delete_manager').click(function(){
    if (confirm("Вы уверены что хотите удалить менеджера?")) {
      console.log('ok');
    } else {
      return false;
    }
    var manager_id = $('.manager_id_for_change_data').text();
    var data = new FormData();
    data.append( 'manager_id', manager_id );
    $.ajax({
      url         : '/superuser/ajaxdeletemanager',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          $.toast({
              heading: 'Success',
              text: 'Менеджер успешно удален',
              showHideTransition: 'slide',
              icon: 'success'
            });
          setTimeout(function(){
            window.location.reload();
          }, 3000);
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
          $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });

      }
    });
  });

  $('.change_manager_type').click(function () {
    if ($(this)[0].dataset.tribeList) {
      $('#change_manager_tribe_block').show();
    } else {
      $('#change_manager_tribe_block').hide();
    }
  });
  $('.new_manager_type').click(function () {
    if ($(this)[0].dataset.tribeList) {
      $('#new_manager_tribe_block').show();
    } else {
      $('#new_manager_tribe_block').hide();
    }
  });

  $('.ajax_update_manager_data').click(function(){
    $('.preloader').fadeIn();

    var manager_id = $('.manager_id_for_change_data').text();
    var lastName = $('.ajax_change_manager_last_name').val();
    var login = $('.ajax_change_manager_login').val();
    var firstName = $('.ajax_change_manager_first_name').val();
    var password = $('.ajax_change_manager_password').val();
    var studioHome = $('.ajax_change_manager_studioHome:checked').val();
    var access = $('.ajax_change_manager_access:checked').val();
    var birthDate = $('.ajax_change_manager_birth_date').val();
    var phone = $('.ajax_change_manager_phone').val();
    var rocketChatLogin = $('.ajax_change_manager_rocket-chat-login').val();
    var telegram = $('.ajax_change_manager_telegram').val();
    var currency = $("#update_manager_currency option:selected").val();
    var paymentSystem = $("#update_manager_payment_system option:selected").val();
    var paymentSystemNumber = $('.ajax_update_payment_system_number').val();
    var country = $('#select2-update_manager_country-container').text();

    var managerTypeInput = document.querySelector('.change_manager_type.checked input');
    if (managerTypeInput !== null) {
      var managerType = managerTypeInput.value;
    }
    var tribeInput  = document.querySelector('.change_manager_tribe.checked input');
    if (tribeInput !== null) {
      var tribeId = tribeInput.value;
    }

    var data = new FormData();
    if (typeof photoUpdate !== 'undefined') {
      $.each( photoUpdate, function( key, value ){
        data.append( 'main_photo', value );
      });
    }
    if (typeof photo_1_Update !== 'undefined') {
      $.each( photo_1_Update, function( key, value ){
        data.append( 'first_photo', value );
      });
    }
    if (typeof photo_2_Update !== 'undefined') {
      $.each( photo_2_Update, function( key, value ){
        data.append( 'second_photo', value );
      });
    }
    if (typeof videoUpdate !== 'undefined') {
      $.each( videoUpdate, function( key, value ){
        data.append( 'video', value );
      });
    }

    data.append( 'manager_id', manager_id );
    data.append( 'lastName', lastName );
    data.append( 'login', login );
    data.append('firstName', firstName);
    data.append( 'password', password );
    data.append( 'studioHome', studioHome );
    data.append('access', access);
    data.append( 'birthDate', birthDate );
    data.append( 'phone', phone );
    data.append('rc_login', rocketChatLogin);
    data.append('telegram', telegram);
    data.append('currency', currency);
    data.append('paymentSystem', paymentSystem);
    data.append('paymentSystemNumber', paymentSystemNumber);
    data.append('country', country);

    if (managerTypeInput !== null && managerType.length !== 0) {
      data.append('manager_type', managerType);
    }
    if (tribeInput !== null && tribeId.length !== 0) {
      data.append('tribe_id', tribeId);
    }

    $.ajax({
      url         : '/superuser/ajaxupdatemanagerdata',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          $('.preloader').fadeOut();

          $.toast({
              heading: 'Success',
              text: 'Данные обновлены',
              showHideTransition: 'slide',
              icon: 'success'
            });
          setTimeout(function(){
            window.location.reload();
          }, 3000);
        }
        // ошибка
        else {
          $('.preloader').fadeOut();

          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          $('.preloader').fadeOut();
          console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
          $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
      }
    });
  });

  $('.superuser-managers__create').click(function(){
    $('#new_manager_type_-styler').addClass('checked');
    $('#createManagerModal').show();
  });

  $('.superuser-managers__modal-close').click(function(){
    $(this).parent().hide();
    document.getElementById('update_manager_photo').value = null;
    document.getElementById('update_manager_photo_1').value = null;
    document.getElementById('update_manager_photo_2').value = null;
    document.getElementById('update_manager_video').value = null;
  });

  $('.close_create_manager_window').click(function() {
    $('#createManagerModal').hide();
  });

  // отобразить файл на клиенте при создании менеджера
  document.querySelector(".create_manager_photo").addEventListener("change", function () {
  photoCreate = this.files;
  if (this.files[0]) {
    const fr = new FileReader();
    fr.addEventListener("load", function () {
      document.querySelector(".show_new_manager_photo_create").src = fr.result;
    }, false);

    fr.readAsDataURL(this.files[0]);
  }
});

  // отобразить первое изображение на клиенте при создании менеджера
  document.querySelector(".create_manager_photo_1").addEventListener("change", function () {
  photo_1_Create = this.files;
  if (this.files[0]) {
    const fr = new FileReader();
    fr.addEventListener("load", function () {
      document.querySelector(".show_new_manager_photo_create_1").src = fr.result;}, false);
    fr.readAsDataURL(this.files[0]);
  }
});

  // отобразить второе изображение на клиенте при создании менеджера
  document.querySelector(".create_manager_photo_2").addEventListener("change", function () {
  photo_2_Create = this.files;
  if (this.files[0]) {
    const fr = new FileReader();
    fr.addEventListener("load", function () {
      document.querySelector(".show_new_manager_photo_create_2").src = fr.result;}, false);
    fr.readAsDataURL(this.files[0]);
  }
});

  // отобразить видео на клиенте при создании менеджера
  document.querySelector(".create_manager_video").addEventListener("change", function () {
  videoCreate = this.files;
  const video = document.getElementById("show_manager_video_video")

  if (this.files[0]) {
    const fr = new FileReader();
    fr.addEventListener("load", function () {
      video.src = fr.result;
      video.style.display = "block";
      }, false);
    fr.readAsDataURL(this.files[0]);
  }
});

  // отобразить файл на клиенте при редактировании менеджера
  document.querySelector(".update_manager_photo").addEventListener("change", function () {
  photoUpdate = this.files;
  if (this.files[0]) {
    var fr = new FileReader();
    fr.addEventListener("load", function () {
      document.querySelector(".show_new_manager_photo_update").src = fr.result;
    }, false);

    fr.readAsDataURL(this.files[0]);
  }
});

  // отобразить файл на клиенте при редактировании менеджера
  document.querySelector(".update_manager_photo_1").addEventListener("change", function () {
  photo_1_Update = this.files;
  if (this.files[0]) {
    var fr = new FileReader();
    fr.addEventListener("load", function () {
      document.querySelector(".show_new_manager_photo_update_1").src = fr.result;
    }, false);

    fr.readAsDataURL(this.files[0]);
  }
});

  // отобразить файл на клиенте при редактировании менеджера
  document.querySelector(".update_manager_photo_2").addEventListener("change", function () {
  photo_2_Update = this.files;
  if (this.files[0]) {
    var fr = new FileReader();
    fr.addEventListener("load", function () {
      document.querySelector(".show_new_manager_photo_update_2").src = fr.result;
    }, false);

    fr.readAsDataURL(this.files[0]);
  }
});

  document.querySelector(".update_manager_video").addEventListener("change", function () {
    videoUpdate = this.files;
    const video = document.getElementById("show_updated_manager_video_video")

    if (this.files[0]) {
      const fr = new FileReader();
      fr.addEventListener("load", function () {
        video.src = fr.result;
        video.style.display = "block";
      }, false);
      fr.readAsDataURL(this.files[0]);
    }
  });

  function getSrc(btn){
    const photoImg = btn.parent().find("img");
    const src = photoImg.prop('src');
    btn.prop("href", src);
  };

  function getVideoSrc(btn){
    const photoImg = btn.parent().find("video");
    const src = photoImg.prop('src');
    btn.prop("href", src);
  };

  $(".make-big-manager_photo").click(function(){
    const bigPhotoBtn = $(this);
    getSrc(bigPhotoBtn);
  });

  $(".manager_download_photo").click(function(){
    const downloadBtn = $(this);
    getSrc(downloadBtn);
  });

  $(".manager_download_video").click(function(){
    const downloadBtn = $(this);
    getVideoSrc(downloadBtn);
  });

  $(document).ready(function() {
    $(".make-big-manager_photo").fancybox({
      padding : 0,
      helpers: {
        overlay: {
          locked: false
        }
      }
    });
  });

  $('.ajax_create_new_manager').click(function() {
    var lastName = $('.ajax_create_last_name').val();
    var login = $('.ajax_create_login').val();
    var firstName = $('.ajax_create_first_name').val();
    var password = $('.ajax_create_password').val();
    var studioHome = $('.ajax_create_studioHome:checked').val();
    var access = $('.ajax_create_access:checked').val();
    var birthDate = $('.ajax_create_birth_date').val();
    var phone = $('.ajax_create_phone').val();
    var rocketChatLogin = $('.ajax_create_rocket-chat-login').val();
    var telegram = $('.ajax_create_telegram').val();
    var currency = $("#create_manager_currency option:selected").val();
    var paymentSystem = $("#create_manager_payment_system option:selected").val();
    var paymentSystemNumber = $('.ajax_create_payment_system_number').val();
    var country = $('#select2-create_manager_country-container').text();

    var managerTypeInput = document.querySelector('.new_manager_type.checked input');
    if (managerTypeInput !== null) {
      var managerType = managerTypeInput.value;
    }
    var tribeInput  = document.querySelector('.new_manager_tribe.checked input');
    if (tribeInput !== null) {
      var tribeId = tribeInput.value;
    }
    //var photo = $('.create_manager_photo').files;
    if(lastName == ''){
      alert('Заполните поле фамилия');
      return false;
    }
    if(login == ''){
      alert('Заполните поле логин');
      return false;
    };
    if(firstName == ''){
      alert('Заполните поле имя');
      return false;
    };
    if(currency == ''){
      alert('Выберите валюту');
      return false;
    };
    if(paymentSystem == ''){
      alert('Выберите платежную систему');
      return false;
    };
    if(paymentSystemNumber == ''){
      alert('Укажите номер платежной системы');
      return false;
    };
    if(password == ''){
      alert('Заполните поле пароль');
      return false;
    };
    if(studioHome == ''){
      alert('Выберите студия или дом');
      return false;
    };
    if(access == ''){
      alert('Заполните поле доступа');
      return false;
    };
    if(birthDate == ''){
      alert('Заполните поле дата рождения');
      return false;
    };
    if(phone == ''){
      alert('Заполните поле телефон');
      return false;
    }
    if (rocketChatLogin === ''){
      alert('Заполните поле Rocket chat');
      return false;
    }
    if (telegram === ''){
      alert('Заполните поле Telegram');
      return false;
    }
    if (country === 'Страна'){
      alert('Заполните поле Страна');
      return false;
    }

    var data = new FormData();
    if (typeof photoCreate !== 'undefined') {
      $.each( photoCreate, function( key, value ){
        data.append( 'main_photo', value );
      });
    }
    if (typeof photo_1_Create !== 'undefined') {
      $.each( photo_1_Create, function( key, value ){
        data.append( 'first_photo', value );
      });
    }
    if (typeof photo_2_Create !== 'undefined') {
      $.each( photo_2_Create, function( key, value ){
        data.append( 'second_photo', value );
      });
    }
    if (typeof videoCreate !== 'undefined') {
      $.each( videoCreate, function( key, value ){
        data.append( 'video', value );
      });
    }

    data.append( 'lastName', lastName );
    data.append( 'login', login );
    data.append('firstName', firstName);
    data.append( 'password', password );
    data.append( 'studioHome', studioHome );
    data.append('access', access);
    data.append( 'birthDate', birthDate );
    data.append( 'phone', phone );
    data.append('rc_login', rocketChatLogin);
    data.append('telegram', telegram);
    data.append('currency', currency);
    data.append('paymentSystem', paymentSystem);
    data.append('paymentSystemNumber', paymentSystemNumber);
    data.append('country', country);

    if (managerTypeInput !== null && managerType.length !== 0) {
      data.append('manager_type', managerType);
    }
    if (tribeInput !== null && tribeId.length !== 0) {
      data.append('tribe_id', tribeId);
    }

    $.ajax({
        url         : '/superuser/ajaxcreatenewmanager',
        type        : 'POST', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
            $.toast({
              heading: 'Success',
              text: 'Менеджер успешно создан',
              showHideTransition: 'slide',
              icon: 'success'
            });
            setTimeout(function(){
              window.location.reload();
            }, 3000);
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
          $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
        }
      });
    });

  $(".change-tribe-checkbox").change(function() {
    const checkbox = $(this);
    const parentCheckbox = checkbox.closest('.jq-checkbox');
    const tribeId = checkbox.val();
    const isChecked = checkbox.prop("checked");
    const isWork = isChecked ? 1 : 0;
    const formData = new FormData();

    parentCheckbox.addClass("disabled");
    formData.append('tribe_id', tribeId);
    formData.append('is_active', isWork);

    $.ajax({
      url: '/superuser/ajaxupdatetribestatus',
      type: 'POST',
      data: formData,
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: function(respond) {
        if (typeof respond.error === 'undefined') {
          parentCheckbox.removeClass("disabled");

          $.toast({
            heading: 'Success',
            text: 'Статус трайба обновлен',
            showHideTransition: 'slide',
            icon: 'success'
          });
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        } else {
          console.log('ОШИБКА: ' + respond.error);
          parentCheckbox.removeClass("disabled");
        }
      },
      error: function(jqXHR, status) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        parentCheckbox.removeClass("disabled");

        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  });
}
