if ($("#superadmin-ajax-load-video").length){

$('input[type=file]').on('change', function(){
	files = this.files;
});

// обработка и отправка AJAX запроса при клике на кнопку upload_files
$('.ajax_load_video').on( 'click', function( event ){

	event.stopPropagation(); // остановка всех текущих JS событий
	event.preventDefault();  // остановка дефолтного события для текущего элемента - клик для <a> тега

	// ничего не делаем если files пустой
	if( typeof files == 'undefined' ) return;

	// создадим данные файлов в подходящем для отправки формате
	var data = new FormData();
	$.each( files, function( key, value ){
		data.append( key, value );
	});
	$(".preloader").fadeIn();

	// добавим переменную идентификатор запроса
	data.append( 'my_file_upload', 1 );

	// AJAX запрос
	$.ajax({
		url         : '/adminka/default/ajaxloadvideoonserver',
		type        : 'POST',
		data        : data,
		cache       : false,
		dataType    : 'json',
		// отключаем обработку передаваемых данных, пусть передаются как есть
		processData : false,
		// отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
		contentType : false,
		// функция успешного ответа сервера
		success     : function( respond, status, jqXHR ){

			// ОК
			if( typeof respond.error === 'undefined' ){
				// файлы загружены, делаем что-нибудь

				// покажем пути к загруженным файлам в блок '.ajax-reply'

				var files_path = respond.files;
				var html = '';
				$.each( files_path, function( key, val ){
					 html += val +'<br>';
				} )

				$('.ajax-reply').html( html );
				$('.preloader').fadeOut('slow', function() {});
			}
			// error
			else {
				console.log('ОШИБКА: ' + respond.error );
			}
		},
		// функция ошибки ответа сервера
		error: function( jqXHR, status, errorThrown ){
			console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
		}

	});

});


$('.ajax_remove_video').click(function() {
	let id = $(this).data('id');
	var data = new FormData();
	let hideAfterDelete = $(this).parent();

	// добавим переменную идентификатор запроса
	data.append( 'id', id );

	// AJAX запрос
	$.ajax({
		url         : '/admin/default/ajaxdeletevideo',
		type        : 'POST',
		data        : data,
		cache       : false,
		dataType    : 'json',
		// отключаем обработку передаваемых данных, пусть передаются как есть
		processData : false,
		// отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
		contentType : false,
		// функция успешного ответа сервера
		success     : function( respond, status, jqXHR ){

			// ОК
			if( typeof respond.error === 'undefined' ){
				hideAfterDelete.hide();
				$.toast({
       			    heading: 'Success',
       			    text: 'Видео удалено',
       			    showHideTransition: 'slide',
       			    icon: 'success'
       			});
			}
			// error
			else {
				console.log('ОШИБКА: ' + respond.error );
			}
		},
		// функция ошибки ответа сервера
		error: function( jqXHR, status, errorThrown ){
			console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
			$.toast({
       		    heading: 'Error',
       		    text: 'Неизвестная ошибка',
       		    showHideTransition: 'fade',
       		    icon: 'error'
       		});
		}

	});
});


}