if ($("#modelCommon").length){
  var preloader = $(".preloader");

  const submitVerifyProcessSendBtnElements = $('.manager-main__table-verify-btn');
  const directManualVerifyBtnElement = $('.manager-main__direct-manual-verify-btn');

  function submitVerifyProcessHandler(status, modelId) {
    const jsonData = {
      status: status,
      modelId: modelId,
    };

    $.ajax({
      url: '/manager/ajaxcreateverifyregistrarticket',
      type: 'POST',
      data: JSON.stringify(jsonData),
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: 'application/json; charset=utf-8',
      success: function (response) {
        if (response.success) {
          managerSuccessToast(response.message);
        } else {
          console.log(response.message);
          managerErrorToast(response.message);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        const status = jqXHR.status;
        const errorMessages = {
          0: 'Нет подключения к серверу',
          404: 'Запрашиваемый URL не найден',
          500: 'Внутренняя ошибка сервера'
        };

        const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
        managerErrorToast(message);
      }
    });
  }

  function submitManualVerifyProcessHandler(modelId) {
    const jsonData = {
      modelId: modelId,
    };

    $.ajax({
      url: '/manager/ajaxdirectmanualverif',
      type: 'POST',
      data: JSON.stringify(jsonData),
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: 'application/json; charset=utf-8',
      success: function (response) {
        if (response.success) {
          managerSuccessToast(response.message);
          location.reload();
        } else if (response.success){
          managerSuccessToast(response.message);
        } else {
          console.log(response.message);
          managerErrorToast(response.message);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        const status = jqXHR.status;
        const errorMessages = {
          0: 'Нет подключения к серверу',
          404: 'Запрашиваемый URL не найден',
          500: 'Внутренняя ошибка сервера'
        };

        const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
        managerErrorToast(message);
      }
    });
  }

  submitVerifyProcessSendBtnElements.on('click', function() {
    const modelId = $(this).next().data('modelId');
    const statusElement = $(this).closest('tr').find('.manager-main__table-status span');

    submitVerifyProcessHandler(statusElement, modelId);
  });

  directManualVerifyBtnElement.on('click', function() {
    const modelId = $(this).next().data('modelId');

    submitManualVerifyProcessHandler(modelId);
  });

  function managerSuccessToast(message) {
    $.toast({
      heading: 'Успешно',
      text: message,
      showHideTransition: 'slide',
      icon: 'success'
    });
  }

  function managerErrorToast(message) {
    $.toast({
      heading: 'Ошибка',
      text: message,
      showHideTransition: 'fade',
      icon: 'error'
    });
  }

  //constructor work
  $(".constuctor-btn").click(function(){
    $(".manager-main__constructor-block").css("display", "grid");
  });
  $(".close-constructor-block").click(function(){
    $(".manager-main__constructor-block").hide();
  });
  jQuery(function($){
    $(document).mouseup(function (e){
        const block = $(".manager-main__constructor-block");
        if (!block.is(e.target)
            && block.has(e.target).length === 0) {
            block.hide();
        }
    });
  });

  $("#constuctor-contacts").change(function(){
    if ($("#constuctor-contacts").prop("checked")){
      $(".manager-main__table-chat").show();
      $(".manager-main__table-phone").show();
      $(".manager-main__table-skype").show();
      $(".manager-main__table-whatsapp").show();
      $(".manager-main__table-viber").show();
      $(".manager-main__table-telegram").show();
      $(".manager-main__table-mail").show();
    }else{
      $(".manager-main__table-chat").hide();
      $(".manager-main__table-phone").hide();
      $(".manager-main__table-skype").hide();
      $(".manager-main__table-whatsapp").hide();
      $(".manager-main__table-viber").hide();
      $(".manager-main__table-telegram").hide();
      $(".manager-main__table-mail").hide();
    }
  });

  $("#constuctor-date").change(function(){
    if ($("#constuctor-date").prop("checked")){
      $(".manager-main__table-lastonline").show();
    }else{
      $(".manager-main__table-lastonline").hide();
    }
  });


  $("#constuctor-sites").change(function(){
    if ($("#constuctor-sites").prop("checked")){
      $(".show-site").show();
    }else{
      $(".show-site").hide();
    }
  });
  $("#constuctor-birthday").change(function(){
    if ($("#constuctor-birthday").prop("checked")){
      $(".manager-main__table-birthday").show();
    }else{
      $(".manager-main__table-birthday").hide();
    }
  });
  $("#constuctor-lvl-pay").change(function(){
    if ($("#constuctor-lvl-pay").prop("checked")){
      $(".manager-main__table-lvl").show();
    }else{
      $(".manager-main__table-lvl").hide();
    }
  });
  $("#constuctor-documents").change(function(){
    if ($("#constuctor-documents").prop("checked")){
      $(".manager-main__table-doc").show();
    }else{
      $(".manager-main__table-doc").hide();
    }
  });
  $("#constuctor-days").change(function(){
    if ($("#constuctor-days").prop("checked")){
      $(".deleteMeBeforeNewbuild").show();
    }else{
      $(".deleteMeBeforeNewbuild").hide();
    }
  });
  $("#constuctor-country").change(function(){
    if ($("#constuctor-country").prop("checked")){
      $(".manager-main__table-country").show();
    }else{
      $(".manager-main__table-country").hide();
    }
  });
  $("#constuctor-doctype").change(function(){
    if ($("#constuctor-doctype").prop("checked")){
      $(".manager-main__table-doctype").show();
    }else{
      $(".manager-main__table-doctype").hide();
    }
  });
  $("#constuctor-quality-control").change(function(){
    if ($("#constuctor-quality-control").prop("checked")){
      $(".qcstatus").show();
      $(".freeze").hide();
    }else{
      $(".qcstatus").hide();
    }
  });

  function showBeganIntervalDate() {
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const beganOfPeriod = $("#period-select-date-styler .sel").text();
    const beganIntervalDay = beganOfPeriod.split('-');
    const beganIntervalMonth = beganOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    } else {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
      $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
    }
  }

  function showEndIntervalDate(){
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const endOfPeriod = $("#period-select-date-second-styler .sel").text();
    const endIntervalDay = endOfPeriod.split('-');
    const endIntervalMonth = endOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
    } else {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
    }
  }

  $("#period-inteval").change(function(){
    if ($("#period-inteval").prop("checked")){
      $(".manager-main__period-select-second").css("display", "flex");
      $(".manager-main__period-select").css("height", "140px");
      $(".end-period").css("display", "inline-block");
      showBeganIntervalDate();
      showEndIntervalDate();
    }else {
      $(".manager-main__period-select-second").css("display", "none");
      $(".manager-main__period-select").css("height", "81px");
      $(".end-period").css("display", "none");
    }
  });

  $("#period-select-date").change(function(){
    if ($("#period-inteval").prop("checked")){
      showBeganIntervalDate();
    } else {
      const beganOfPeriod = $("#period-select-date-styler .sel").text();
      $(".began-period").text(beganOfPeriod + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    }
  });

  $("#period-select-date-second").change(function(){
    showEndIntervalDate();
  });

  $('.manager-main__period-select-year').change(function(){
    showBeganIntervalDate();
    showEndIntervalDate();
  });

  $('#modelCommonMenuItem').addClass('current-page');

  function changeViewBirthday(){
    const birthday = $("tbody .manager-main__table-birthday");
    birthday.each(function(){
      const thisBirthday = $(this).text();
      if (thisBirthday.length > 10) {
        const datesBirthdayArr = thisBirthday.split(',');
        if (datesBirthdayArr[0] == "" || datesBirthdayArr[1] == "") {
          $(this).text("");
        }else {
          const firstBirthday = datesBirthdayArr[0].split('-');
          const secondBirthday = datesBirthdayArr[1].split('-');
          const newFirstBirthday = firstBirthday[2] + '.' + firstBirthday[1] + '.' + firstBirthday[0];
          let newSecondBirthday = secondBirthday[2] + '.' + secondBirthday[1] + '.' + secondBirthday[0];
          newSecondBirthday = newSecondBirthday.replace( /\s/g, "");
          const newDatesBirthday = newFirstBirthday + ', ' + newSecondBirthday;
          $(this).text(newDatesBirthday);
        }

      } else {
        const dateBirthday = thisBirthday.split('-');
        if (dateBirthday[0] === "") {
          $(this).text("");
        }else {
          const newDateBirthday = dateBirthday[2] + '.' + dateBirthday[1] + '.' + dateBirthday[0];
          $(this).text(newDateBirthday);
        }
      }
    });
  }
  changeViewBirthday();

  function getDatePeriodNotChecked() {
    let year = $('#select-period-year-styler .jq-selectbox__select-text').text();
    let firstIntervalName = $('#period-select-date option:selected').attr('name');
    let firstIntervalNameArr = firstIntervalName.split('.');
    let firstIntervalYear = year === '' ? (new Date()).getFullYear() : year;

    result = new Array();
    result['from'] = firstIntervalYear+'-'+firstIntervalNameArr[0]+'-'+firstIntervalNameArr[1];
    result['to'] = firstIntervalYear+'-'+firstIntervalNameArr[0]+'-'+firstIntervalNameArr[2];
    return result;
  }

  function getDatePeriodChecked() {
    let firstIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    let firstIntervalName = $('#period-select-date option:selected').attr('name');
    let firstIntervalNameArr = firstIntervalName.split('.');
    let secondIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();
    let secondIntervalName = $('#period-select-date-second option:selected').attr('name');
    let secondIntervalNameArr = secondIntervalName.split('.');
    result = new Array();
    result['from'] = firstIntervalYear+'-'+firstIntervalNameArr[0]+'-'+firstIntervalNameArr[1];
    result['to'] = secondIntervalYear+'-'+secondIntervalNameArr[0]+'-'+secondIntervalNameArr[2];
    return result;
  }

  function getDiffBetweenDates() {

    let date = new Array();
    if ($("#period-inteval").prop("checked")) {
      date = getDatePeriodChecked();
    }else {
      date = getDatePeriodNotChecked();
    }

    let fromArr = date['from'].split('-');
    let toArr = date['to'].split('-');
    let now = {};
    let end = {};

    now = moment([fromArr[0], fromArr[1] - 1, fromArr[2]]);
    end = moment([toArr[0], toArr[1] - 1, toArr[2]]);

    const duration = moment.duration(end.diff(now));
    const days = (duration.asDays()) + 1;

    return days;
  }

  function createTh() {
      //const tableTh = $('.manager-model__single-info-table thead th');
      //const tableThead = $('.manager-model__single-info-table thead tr');
      let year = $('#select-period-year-styler .jq-selectbox__select-text').text();
      let firstIntervalName = $('#period-select-date option:selected').attr('name');
      let firstIntervalNameArr = firstIntervalName.split('.');
      let firstIntervalYear = year === '' ? (new Date).getFullYear() : year;
      $('.deleteMeBeforeNewbuild').remove();

      const countDays = getDiffBetweenDates();
      const days = [firstIntervalYear, firstIntervalNameArr[0] - 1, firstIntervalNameArr[1]];
      for ( let i = 0; i < countDays; i++ ){
        let date = moment(days).add(i, 'days');
        let date2 = moment(date).format('DD.MM');
        let date3 = moment(date).format('DD_MM');
        $('.manager-main__table .table-head tr .manager-main__table-earnings').before("<td class = ' deleteMeBeforeNewbuild'>" + date2 + "</td>");
        $('.manager-main__table tbody tr').each(function( index ) {
          let id = $(this).attr('name');
          let key = id +'_'+date3;
          $(this).children('.manager-main__table-earnings').before("<td class = 'deleteMeBeforeNewbuild "+key+"' >-</td>");
        });
        $('.manager-main__table #total-line .manager-main__table-earnings').before("<td class='deleteMeBeforeNewbuild'>-</td>");
      }

    }

$(document).ready(function(){

  $(document).mouseup(function(e)
  {
    let container = $(".div-models-list");
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
      container.hide();
    }
  });

  $('#search-model-text').click(function() {
    $(".div-models-list").show();
  });

  $('.model-search-btn').click(function() {
    let text = $('#search-model-text').val();
    if (text) {
      $.get({
        url: '/manager/ajaxfindmodels',
        data: { search: text },
        dataType: 'json',
        cache: false,
        success: function (respond) {
          if (respond['models'].length > 0) {
            let modelsList = $(".div-models-list");
            modelsList.show();
            modelsList.empty();
            respond['models'].forEach(
                model => modelsList.append('<div>ID модели: <div><a href="/manager/singlemodel?id=' + model['id'] + '" target="_blank">'+ model['id'] +'</a></div><br> Трайб: <div>' + model['tribe_id'] + '</div></div>')
            );
          } else {
            $.toast({
              text: 'Модели по введенным данным не найдены',
              showHideTransition: 'fade',
              icon: 'error',
            })
          }
        }
      });
    } else {
      $.toast({
        text: 'Поле для поиска не должно быть пустым',
        showHideTransition: 'fade',
        icon: 'error',
      })
    }
  });

});

/*
  Загрузка финансовой информации в таблицу моделей.
  Не нужна для чат-оператора.
  Проверка на тип менеджера и не загружаем для чат-оператора.
*/
const Manager = {
  CHAT_OPERATOR: 'chat_operator'
}
const managerType = $('#manager-type').data('manager-type');

if (managerType !== Manager.CHAT_OPERATOR) {
  $(document).ready(setTimeout(function(){
    $(".preloader").fadeIn();
    getDiffBetweenDates();
    createTh();
    getAllAjaxDataForTable();
  }, 1000));
}
/*
  END--------------------------------------------------------------
*/

$(".ajax_get_models_data").click(function(){
$(".preloader").fadeIn();
  getDiffBetweenDates();
  createTh();
  getAllAjaxDataForTable();
});


async function fetchData(url, data) {
  try {
    return await $.ajax({
      url,
      type: 'POST',
      data,
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false
    });
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    $.toast({
      heading: '',
      text: 'За данный период нет данных',
      showHideTransition: 'fade',
      icon: 'error'
    });
    throw error;
  }
}

async function getAllAjaxDataForTable() {
  const periodChecked = $('#period-inteval').is(':checked');

  const monthDayFrom = $("#period-select-date option:selected").attr('name');
  const yearFrom = $("#select-period-year option:selected").val();

  let monthDayTo, yearTo;

  if (periodChecked) {
    monthDayTo = $("#period-select-date-second option:selected").attr('name');
    yearTo = $("#select-period-year-second option:selected").val();
  } else {
    monthDayTo = monthDayFrom;
    yearTo = yearFrom;
  }

  const monthPeriodFromArray = monthDayFrom.split('.');
  const monthPeriodToArray = monthDayTo.split('.');

  const from = yearFrom + '-' + monthPeriodFromArray[0] + '-' + monthPeriodFromArray[1];
  const to = yearTo + '-' + monthPeriodToArray[0] + '-' + monthPeriodToArray[2];

  const ids = $(".ajax_get_data_per_period:not(.freeze)").map(function() {
    return Number($(this).attr('name'));
  }).get();

  const data = new FormData();
  data.append('from', from);
  data.append('to', to);
  data.append('ids', ids);

  try {
    const tableDataResponse = await fetchData('/manager/ajaxgetdataperperiod', data);
    await fillTableAllmodels(tableDataResponse);
    calculateSumAllmodels();
    calculateKpiAllmodels();
    calculateTotalSum();

    const params = new URLSearchParams(window.location.search);
    const managerId = params.get('manager_id');
    const managerIdQueryParameter = managerId ? ('?manager_id=' + managerId) : '';

    const totalsDataResponse = await fetchData('/manager/ajaxgettotalsitescashperperiod' + managerIdQueryParameter, data);
    fillTotalAllPages(totalsDataResponse);

  } catch (error) {
    console.error("Error in getAllAjaxDataForTable:", error);
  } finally {
    $(".preloader").fadeOut("slow");
  }
}

$("#managerTable_allmodels th").click(function() {
    let urlParams  = new URLSearchParams(window.location.search);
    let sortColumn = urlParams.get('sort');
    let sortType   = urlParams.get('sort_type');
    let columnName = $(this)[0].dataset.columnName;

    if (!columnName) {
      return;
    }

    if (sortColumn === columnName) {
        sortType = (sortType === 'desc') ? 'asc' : 'desc';
    } else {
        sortColumn = columnName;
        sortType = 'desc';
    }

    urlParams.set('sort', sortColumn);
    urlParams.set('sort_type', sortType);

    window.location.href = window.location.pathname + '?' + urlParams.toString();
});

$("#constuctor-freeze").click(function() {
    let urlParams  = new URLSearchParams(window.location.search);
    let showFrozen = $(this)[0].checked ? '1' : '0';

    urlParams.set('show_frozen', showFrozen);

    window.location.href = window.location.pathname + '?' + urlParams.toString();
});

$("#constructor-is_archived").click(function() {
    let urlParams  = new URLSearchParams(window.location.search);
    let showArchived = $(this)[0].checked ? '0' : '1';

    urlParams.set('show_archived', showArchived);

    window.location.href = window.location.pathname + '?' + urlParams.toString();
});

$("#constuctor-auto-type").click(function() {
    let urlParams  = new URLSearchParams(window.location.search);

    if ($(this)[0].checked) {
      urlParams.set('auto_type', '0');
    } else {
      urlParams.delete('auto_type')
    }

    window.location.href = window.location.pathname + '?' + urlParams.toString();
});

$("#constuctor-manual-type").click(function() {
    let urlParams  = new URLSearchParams(window.location.search);

    if ($(this)[0].checked) {
      urlParams.set('manual_type', '0');
    } else {
      urlParams.delete('manual_type')
    }

    window.location.href = window.location.pathname + '?' + urlParams.toString();
});

$('[id^="constructor-status_"]').click(function() {
    let urlParams  = new URLSearchParams(window.location.search);
    let status = $(this)[0].id.replace('constructor-status_', '')

    if ($(this)[0].checked) {
      urlParams.set(status, '0');
    } else {
      urlParams.delete(status)
    }

    window.location.href = window.location.pathname + '?' + urlParams.toString();
});

function calculateTotalSum() {
  var jas_sum = 0;
  var cha_sum = 0;
  var myf_sum = 0;
  var bon_sum = 0;
  var iml_sum = 0;
  var str_sum = 0;
  var cam_sum = 0;
  var sod_sum = 0;
  var chr_sum = 0;
  var stm_sum = 0;
  var xlc_sum = 0;
  var f4f_sum = 0;
  var con_sum = 0;
  var all =0;
  var all_bonus=0;
  var plan = 0;
  var hours = 0;
  var kpi = 0;
  var managerCash =0;
  var i=0;
  $('.ajax_this_have_full_access').each(function( index ) {
      if(isFinite($( this ).children('.manager-main__table-site1').text()))  jas_sum = jas_sum + Number($( this ).children('.manager-main__table-site1').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
      if(isFinite($( this ).children('.manager-main__table-site2').text()))  cha_sum = cha_sum + Number($( this ).children('.manager-main__table-site2').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
     if(isFinite($( this ).children('.manager-main__table-site3').text()))  myf_sum = myf_sum + Number($( this ).children('.manager-main__table-site3').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site4').text()))  bon_sum = bon_sum + Number($( this ).children('.manager-main__table-site4').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site5').text())) iml_sum = iml_sum + Number($( this ).children('.manager-main__table-site5').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site6').text())) str_sum = str_sum + Number($( this ).children('.manager-main__table-site6').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site7').text()))  cam_sum = cam_sum + Number($( this ).children('.manager-main__table-site7').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site9').text()))  sod_sum = sod_sum + Number($( this ).children('.manager-main__table-site9').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site10').text())) chr_sum = chr_sum + Number($( this ).children('.manager-main__table-site10').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site11').text())) stm_sum = stm_sum + Number($( this ).children('.manager-main__table-site11').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site12').text())) xlc_sum = xlc_sum + Number($( this ).children('.manager-main__table-site12').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site13').text())) f4f_sum = f4f_sum + Number($( this ).children('.manager-main__table-site13').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    if(isFinite($( this ).children('.manager-main__table-site14').text())) con_sum = con_sum + Number($( this ).children('.manager-main__table-site14').text());
  });

  $('.ajax_this_have_full_access').each(function( index ) {
    all = all + Number($( this ).children('.manager-main__table-earnings').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    all_bonus = all_bonus + Number($( this ).children('.manager-main__table-earnings-bonus').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    plan = plan + Number($( this ).children('.manager-main__table-plan').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    hours = hours + Number($( this ).children('.manager-main__table-hours').text());
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    kpi = kpi + Number($( this ).children('.manager-main__table-kpi').text());
    i++;
  });
  $('.ajax_this_have_full_access').each(function( index ) {
    managerCash = managerCash + Number($( this ).children('.manager-main__table-prize').text());
  });
  $('#total-line').children('.manager-main__table-site1').text(Math.round(jas_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site2').text(Math.round(cha_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site3').text(Math.round(myf_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site4').text(Math.round(bon_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site5').text(Math.round(iml_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site6').text(Math.round(str_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site7').text(Math.round(cam_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site9').text(Math.round(sod_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site10').text(Math.round(chr_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site11').text(Math.round(stm_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site12').text(Math.round(xlc_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site13').text(Math.round(f4f_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-site14').text(Math.round(con_sum * 100) / 100);
  $('#total-line').children('.manager-main__table-earnings').text(Math.round(all * 100) / 100);
  $('#total-line').children('.manager-main__table-earnings-bonus').text(Math.round(all_bonus * 100) / 100);
  $('#total-line').children('.manager-main__table-plan').text(Math.round(plan * 100) / 100);
//  $('#total-line').children('.manager-main__table-hours').text(Math.round(hours * 100) / 100);

  kpi = kpi/i;
  $('#total-line').children('.manager-main__table-kpi').text(Math.round(kpi * 100) / 100);
  $('#total-line').children('.manager-main__table-prize').text(Math.round(managerCash * 100) / 100);


}

async function fillTableAllmodels(respond) {
    let i = 0;
    $('#total-line').children('.manager-main__table-hours').text(respond[0].full_time);
    await (new Promise((resolve, reject) => {
       function next() {
         do {
           let reportRow = $('tr[name="'+respond[i].id+'"]');
           reportRow.children('.manager-main__table-hours').text(respond[i].time);

           if ($('#period-inteval').is(':checked')) {
             reportRow.children('.manager-main__table-lvl').text('-');
             reportRow.children('.manager-main__table-lvlbonus').text('-');
           }else {
             reportRow.children('.manager-main__table-lvl').text(respond[i].pay_level);
             reportRow.children('.manager-main__table-lvlbonus').text(respond[i].pay_levelbonus);
           }

           reportRow.children('.manager-main__table-site1').text(respond[i].site_1_cash);
           reportRow.children('.manager-main__table-site2').text(respond[i].site_2_cash);
           reportRow.children('.manager-main__table-site3').text(respond[i].site_3_cash);
           reportRow.children('.manager-main__table-site4').text(respond[i].site_4_cash);
           reportRow.children('.manager-main__table-site5').text(respond[i].site_5_cash);
           reportRow.children('.manager-main__table-site6').text(respond[i].site_6_cash);
           reportRow.children('.manager-main__table-site7').text(respond[i].site_7_cash);
           reportRow.children('.manager-main__table-site9').text(respond[i].site_9_cash);
           reportRow.children('.manager-main__table-site10').text(respond[i].site_10_cash);
           reportRow.children('.manager-main__table-site11').text(respond[i].site_11_cash);
           reportRow.children('.manager-main__table-site12').text(respond[i].site_12_cash);
           reportRow.children('.manager-main__table-site13').text(respond[i].site_13_cash);
           reportRow.children('.manager-main__table-site14').text(respond[i].site_14_cash);
           reportRow.children('.manager-main__table-earnings').text(respond[i].sum_cash);
           reportRow.children('.manager-main__table-earnings-bonus').text(respond[i].sum_cashwithbonus);
           reportRow.children('.manager-main__table-plan').text(respond[i].plan);
           reportRow.children('.manager-main__table-prize').text(Math.round(respond[i].manager_cash * 100)/100);

           for (var j = 0; j <= respond[i].period_day_cash.length-1; j++) {
            let BdateArr = respond[i].period_day_cash[j].date_start.split(' ');
            let dateArr = BdateArr[0].split('-');
            let key = respond[i].id+'_'+dateArr[2]+'_'+dateArr[1];
            $('.'+key).text(Math.round(respond[i].period_day_cash[j].total_money * 100) / 100);
           }
           ++i;
         } while (i % 10 != 0 && i < respond.length);

         if (i < respond.length - 1) {
          setTimeout(next, 0);
         } else {
           resolve();
         }
      }
      setTimeout(next, 0);
    }));
}

function fillTotalAllPages(respond) {
  let totalLine    = $('#total-line-all-pages');
  let sitesCashSum = 0;
  const sitesCash  = respond['sites_cash'];
  for (let site in sitesCash) {
    let siteData = sitesCash[site];
    sitesCashSum += Number(siteData['site_cash']);
    totalLine.children('.manager-main__table-site' + siteData['site_id']).text(Math.round(siteData['site_cash'] * 100) / 100);
  }
  totalLine.children('.manager-main__table-earnings').text(Math.round(sitesCashSum * 100) / 100);
  totalLine.children('.manager-main__table-earnings-bonus').text(Math.round(respond['cash_with_bonus'] * 100) / 100);
  totalLine.children('.manager-main__table-hours').text(respond['time']);
  totalLine.children('.manager-main__table-prize').text(Math.round(respond['manager_cash'] * 100) / 100);

  const hours = respond['hours'];
  let kpi     = sitesCashSum / hours;
  kpi         = !isFinite(kpi) ? 0 : kpi;
  totalLine.children('.manager-main__table-kpi').text(kpi.toFixed(2));
}

function calculateSumAllmodels() {
  $('.ajax_this_have_full_access').each(function( index ) {
    var site1 = Number($(this).children('.manager-main__table-site1').text());
    var site2 = Number($(this).children('.manager-main__table-site2').text());
    var site3 = Number($(this).children('.manager-main__table-site3').text());
    var site4 = Number($(this).children('.manager-main__table-site4').text());
    var site5 = Number($(this).children('.manager-main__table-site5').text());
    var site6 = Number($(this).children('.manager-main__table-site6').text());
    var site7 = Number($(this).children('.manager-main__table-site7').text());
    var site9 = Number($(this).children('.manager-main__table-site9').text());
    var sum = site1 + site2 + site3 + site4 +site5 + site6 +site7+ site9;
//    $(this).children('.manager-main__table-earnings').text(Math.round(sum * 100) / 100);
    });
}

function calculateKpiAllmodels() {
    $('.ajax_this_have_full_access').each(function( index ) {
    var sum = Number($(this).children('.manager-main__table-earnings').text());
    var time = Number($(this).children('.manager-main__table-hours').text());
    var kpi = sum/time;
    if (!isFinite(kpi)) {
      kpi =0;
    }
    $(this).children('.manager-main__table-kpi').text(kpi.toFixed(2));
    });
}

$('.manager-main__table-plan').click(function(){
  let modelName = $(this).parent().find('.manager-main__table-name a').text();
  modelName = modelName.trim();
  console.log(modelName);
  const cashValue = $(this).text();
  $('.modal-plan .ajax_set_plan_model_name').val(modelName);
  $('.modal-plan .ajax_set_plan_model_name').addClass('active-label');
  $('.model_plan_cash_value').text(cashValue);
  $(".show-modal .modal-box").toggle();
})


$('.manager-main__table-studio_home').click(function(){
  let modelName = $(this).parent().find('.manager-main__table-name a').text();
  let id = $(this).parent().attr('name');
  let place = $(this).text();
  if (place == 'home') {
    $('#studio_checkbox').attr('checked', false);
    $('#home_checkbox').attr('checked', true);
    $('#studio_checkbox').parent().removeClass('checked');
    $('#home_checkbox').parent().addClass('checked');
  }else {
    $('#studio_checkbox').attr('checked', true);
    $('#home_checkbox').attr('checked', false);
    $('#home_checkbox').parent().removeClass('checked');
    $('#studio_checkbox').parent().addClass('checked');
  }
  modelName = modelName.trim();
  $('.get_model_id_here_for_place').text(id);
  $('.modal-box-studia .modal-studia-model-name').text(modelName);
  $(".modal-box-studia").toggle();
})

$('.close_studio_home_window').click(function() {
  $('.modal-box-studia').hide();
})

$('.ajax_save_model_place').click(function() {
  let placeValue = $('.studia-radio [name="studia"]:checked').val();
  let id = $('.get_model_id_here_for_place').text();
  let date = $('#date_for_studio_home').val();
  var data = new FormData();
  data.append( 'placeValue', placeValue );
  data.append( 'id', id );
  data.append('date', date);
    $.ajax({
      url         : '/manager/ajaxchangemodelplacevalue',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          console.log(respond);
          $('.manager-main__table tbody').children('tr[name="'+id+'"]').children('.manager-main__table-studio_home').text(placeValue);
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
          $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
      }
    });
})

$('.studia-radio').click(function(){
  $('.studia-radio').addClass('for-radio');
});
$('.modal-studia-cancel').click(function(){
  $('.modal-box-studia').hide();
})

  $('.change_model_tribe').click(function() {
    let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
    let modelId   = $(this).parent().closest('tr').attr('name');
    let tribeId   = $(this).data('tribe_id');

    $('#select-tribe').val(tribeId).change();
    $('.get_model_id_here').text(modelId);
    $('.get_tribe_id_here').text(tribeId);

    modelName = modelName.trim();
    $('.modal-tribe-model-name').text(modelName);
    $(".modal-box-tribe").toggle();
  })

$('.change_manager').click(function(){
  let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
  let id = $(this).parent().closest('tr').attr('name');
  let manager_id = $(this).data('manager_id');
  $('#select-manager').val(manager_id).change();
  $('.get_model_id_here').text(id);
  $('.get_manager_id_here').text(manager_id);
//  let second = $(this).text();
//  $('.get_manager_id_here').data('second',second);
  modelName = modelName.trim();
  $('.modal-manager-model-name').text(modelName);
  $(".modal-box-manager").toggle();
})

  $('.change_note').click(function(){
    let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
    let id = $(this).parent().closest('tr').attr('name');
    $('.get_note_model_id_here').text(id);
    modelName = modelName.trim();
    var data = new FormData();
    data.append( 'userid', id );
    $.ajax({
      url         : '/manager/ajaxgetgeneralnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $('#modal-change-note').val(respond);

          $.toast({
            heading: 'Success',
            text: 'Данные получены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
    $('.modal-model-name').text(modelName);
    $(".modal-box-note").toggle();
  })

  $('.change_quality_control_note').click(function(){
    let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
    let id = $(this).parent().closest('tr').attr('name');
    $('.get_quality_control_note_model_id_here').text(id);
    modelName = modelName.trim();
    var data = new FormData();
    data.append( 'userid', id );
    $.ajax({
      url         : '/manager/ajaxgetqualitycontrolnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $('#modal-change-quality-control-note').val(respond);

          $.toast({
            heading: 'Success',
            text: 'Данные получены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
    $('.modal-model-name').text(modelName);
    $(".modal-box-quality-control-note").toggle();
  })

  $('.change_chat_operator_note').click(function(){
    let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
    let id = $(this).parent().closest('tr').attr('name');
    $('.get_chat_operator_note_model_id_here').text(id);
    modelName = modelName.trim();
    var data = new FormData();
    data.append( 'userid', id );
    $.ajax({
      url         : '/manager/ajaxgetchatoperatornote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $('#modal-change-chat-operator-note').val(respond);

          $.toast({
            heading: 'Success',
            text: 'Данные получены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
    $('.modal-model-name').text(modelName);
    $(".modal-box-chat-operator-note").toggle();
  })

  $('.change_technical_specialist_note').click(function(){
    let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
    let id = $(this).parent().closest('tr').attr('name');
    $('.get_technical_specialist_note_model_id_here').text(id);
    modelName = modelName.trim();
    var data = new FormData();
    data.append( 'userid', id );
    $.ajax({
      url         : '/manager/ajaxgettechnicalspecialistnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $('#modal-change-technical-specialist-note').val(respond);

          $.toast({
            heading: 'Success',
            text: 'Данные получены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
    $('.modal-model-name').text(modelName);
    $(".modal-box-technical-specialist-note").toggle();
  })

  $('.change_registrar_note').click(function(){
    let modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
    let id = $(this).parent().closest('tr').attr('name');
    $('.get_registrar_note_model_id_here').text(id);
    modelName = modelName.trim();
    var data = new FormData();
    data.append( 'userid', id );
    $.ajax({
      url         : '/manager/ajaxgetregistrarnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $('#modal-change-registrar-note').val(respond);

          $.toast({
            heading: 'Success',
            text: 'Данные получены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
    $('.modal-model-name').text(modelName);
    $(".modal-box-registrar-note").toggle();
  })

  $('[id^="show_archive_modal_"]').click(function() {
    let id = $(this)[0].id.replace('show_archive_modal_', '')
    $('.get_archived_model_id_here').text(id);
    $(".modal-box-archived-reason").toggle();
  });

  $('.close_note_window').click(function(){
    $('.modal-box-note').hide();
  })

  $('.close_tribe_window').click(function(){
    $('.modal-box-tribe').hide();
  })

  $('.ajax_save_note').click(function () {
    let id = $('.get_note_model_id_here').text();
    let content = $('#modal-change-note').val();
    let data = new FormData();
    data.append('modelId', id);
    data.append('comment', content);
    $.ajax({
      url         : '/manager/ajaxcreatemodelnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });

          $('.modal-box-note').hide();
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  });

  $('.ajax_save_quality_control_note').click(function () {
    let modelId = $('.get_quality_control_note_model_id_here').text();
    let text    = $('#modal-change-quality-control-note').val();

    let data = new FormData();
    data.append('user_id', modelId);
    data.append('text', text);

    $.ajax({
      url         : '/manager/ajaxsavequalitycontrolnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });

          $('.modal-box-quality-control-note').hide();
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  });

  $('.ajax_save_chat_operator_note').click(function () {
    let modelId = $('.get_chat_operator_note_model_id_here').text();
    let text    = $('#modal-change-chat-operator-note').val();

    let data = new FormData();
    data.append('user_id', modelId);
    data.append('text', text);

    $.ajax({
      url         : '/manager/ajaxsavechatoperatornote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });

          $('.modal-box-chat-operator-note').hide();
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  });

  $('.ajax_save_technical_specialist_note').click(function () {
    let modelId = $('.get_technical_specialist_note_model_id_here').text();
    let text    = $('#modal-change-technical-specialist-note').val();

    let data = new FormData();
    data.append('user_id', modelId);
    data.append('text', text);

    $.ajax({
      url         : '/manager/ajaxsavetechnicalspecialistnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });

          $('.modal-box-technical-specialist-note').hide();
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  });

  $('.ajax_save_registrar_note').click(function () {
    let modelId = $('.get_registrar_note_model_id_here').text();
    let text    = $('#modal-change-registrar-note').val();

    let data = new FormData();
    data.append('user_id', modelId);
    data.append('text', text);

    $.ajax({
      url         : '/manager/ajaxsaveregistrarnote',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });

          $('.modal-box-registrar-note').hide();
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  });

  $('.ajax_archive_model').click(function () {
    let modelId = $('.get_archived_model_id_here').text();
    let text    = $('#archived_reason').val();

    let data = new FormData();
    data.append('user_id', modelId);
    data.append('archived_reason', text);

    $.ajax({
      url         : '/manager/ajaxarchivemodel',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      processData : false,
      contentType : false,
      success     : function (respond) {
        if (typeof respond.error === 'undefined') {
          $.toast({
            heading:            'Success',
            text:               'В архиве',
            showHideTransition: 'slide',
            icon:               'success'
          });

          var urlParams = new URLSearchParams(window.location.search);
          window.location.href = window.location.pathname + '?' + urlParams.toString();
        } else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      error: function (jqXHR, status) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        $.toast({
          heading:            '',
          text:               'Произошла ошибка',
          showHideTransition: 'fade',
          icon:               'error'
        });
      }
    });
  });

$('.ajax_save_tribe').click(function() {
  let selectedTribe = $('#select-tribe option:selected');
  let tribeId       = selectedTribe.val();
  let tribeText     = selectedTribe.text();
  let modelId       = $('.get_model_id_here').text();

  let data = new FormData();
  data.append('tribe_id', tribeId);
  data.append('model_id', modelId);

  $.ajax({
    url         : '/manager/ajaxchangemodeltribe',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    processData : false,
    contentType : false,
    success     : function(respond) {
      if (typeof respond.error === 'undefined') {
        let changeModelTribe = $('.manager-main__table tbody').children('tr[name="' + modelId + '"]').find('.change_model_tribe');
        changeModelTribe.data('tribe_id', tribeId);
        changeModelTribe.text(tribeText);

        $.toast({
          heading:            'Success',
          text:               'Данные сохранены',
          showHideTransition: 'slide',
          icon:               'success'
        });
      } else {
        console.log('ОШИБКА: ' + respond.error);
      }
    },
    error: function(jqXHR, status) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);

      $.toast({
        heading:            '',
        text:               'Произошла ошибка',
        showHideTransition: 'fade',
        icon:               'error'
      });
    }
  });
})
}

// ширина столбца скайп
const skypes = Array.from($('.manager-main__table-skype'));
let maxSkypeWidth = 0;
skypes.forEach((item) => {
  let width = $(item).width();
  if(maxSkypeWidth < width) {
    maxSkypeWidth = width + 20;
  }
});
$('.manager-main__table-skype').css({minWidth: maxSkypeWidth + 'px'})

// ширина столбца почты
const mails = Array.from($('.manager-main__table-mail'));
let maxMailWidth = 0;
mails.forEach((item) => {
  let width = $(item).width();
  if(maxMailWidth < width) {
    maxMailWidth = width + 20;
  }
});
$('.manager-main__table-mail').css({minWidth: maxMailWidth + 'px'})
