function showConstructModal() {
  $('.manager-main__constructor-block').show();
}

$('.change_note').click(function(){
  $('.modal-box-note').hide();
  var modelName = $(this).parent().closest('tr').find('.manager-main__table-name a').text();
  var id = $(this)[0].dataset.userId;
  var noteType = $(this)[0].dataset.noteType;

  modelName = modelName.trim();
  var data = new FormData();
  data.append('userid', id);
  if (noteType) {
    data.append('type', noteType);
  }

  $.ajax({
    url         : '/manager/ajaxgetmodelnote',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    processData : false,
    contentType : false,
    success     : function(respond) {
      if (typeof respond.error === 'undefined') {
        $('#modal-change-note').val(respond);

        $.toast({
          heading:            'Success',
          text:               'Данные получены',
          showHideTransition: 'slide',
          icon:               'success'
        });
      } else {
        console.log('ОШИБКА: ' + respond.error);
      }

      $('.modal-model-name').text(modelName);
      $('.get_note_model_id_here').text(id);
      $('.get_note_type_here').text(noteType);
      $('.modal-box-note').toggle();
    },
    error: function(jqXHR, status) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      $.toast({
        heading:            '',
        text:               'Произошла ошибка',
        showHideTransition: 'fade',
        icon:               'error'
      });
    }
  });
});

$('.ajax_save_note').click(function () {
  var content = $('#modal-change-note').val();
  var id = $('.get_note_model_id_here').text();
  var noteType = $('.get_note_type_here').text();

  let data = new FormData();
  data.append('user_id', id);
  data.append('text', content);
  if (noteType) {
    data.append('type', noteType);
  }

  $.ajax({
    url         : '/manager/ajaxsavemodelnote',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    processData : false,
    contentType : false,
    success     : function(respond) {
      if (typeof respond.error === 'undefined') {
        $.toast({
          heading:            'Success',
          text:               'Данные сохранены',
          showHideTransition: 'slide',
          icon:               'success'
        });

        $('.modal-box-note').hide();
      } else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    error: function(jqXHR, status) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      $.toast({
        heading:            '',
        text:               'Произошла ошибка',
        showHideTransition: 'fade',
        icon:               'error'
      });
    }
  });
});

function closeConstructorModal() {
  $('.manager-main__constructor-block').hide();
}

function showArchivedTickets() {
  var urlParams = new URLSearchParams(window.location.search);
  var showArchived = $('#constructor-archived').prop('checked') ? '0' : '1';

  urlParams.set('show_archived', showArchived);

  window.location.href = window.location.pathname + '?' + urlParams.toString();
}

function closeTicketModal() {
  $('.modal-box-ticket').hide();
}

function closeTicketRejectionModal() {
  $('.modal-box-ticket-rejection').hide();
}

function closeTicketCompletionModal() {
  $('.modal-box-ticket-completion').hide();
}

function showNewTicketModal() {
  $('.modal-box-ticket').toggle();
}

function showTicketRejectionModal(ticketId, name) {
  $('.rejected_ticket_id').text(ticketId);
  $('.modal-rejected-model-name').text(name);
  $('.modal-box-ticket-rejection').toggle();
}

function showTicketCompletionModal(ticketId, name) {
  $('.completed_ticket_id').text(ticketId);
  $('.modal-completed-model-name').text(name);
  $('.modal-box-ticket-completion').toggle();
}

function completeTicket() {
  var id                = $('.completed_ticket_id').text();
  var completionComment = $('#completion_comment').val();

  var data = new FormData();
  data.append('id', id);
  if (completionComment) {
    data.append('completion_comment', completionComment);
  }

  $.ajax({
    url         : '/manager/ajaxcompleteticket',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    processData : false,
    contentType : false,
    success     : function(respond) {
      if (typeof respond.error === 'undefined') {
        $.toast({
          heading:            'Success',
          text:               'Данные сохранены',
          showHideTransition: 'slide',
          icon:               'success'
        });

        var urlParams = new URLSearchParams(window.location.search);
        window.location.href = window.location.pathname + '?' + urlParams.toString();
      } else {
        console.log('ОШИБКА: ' + respond.error);
      }
    },
    error: function(jqXHR, status) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      $.toast({
        heading:            '',
        text:               'Произошла ошибка',
        showHideTransition: 'fade',
        icon:               'error'
      });
    }
  });
}

function rejectTicket() {
  var id              = $('.rejected_ticket_id').text();
  var rejectionReason = $('#rejection_reason').val();

  if (!rejectionReason) {
    $.toast({
      heading:            '',
      text:               'Заполните причину отмены',
      showHideTransition: 'fade',
      icon:               'error'
    });

    return;
  }

  var data = new FormData();
  data.append('id', id);
  data.append('rejection_reason', rejectionReason);

  $.ajax({
    url         : '/manager/ajaxrejectticket',
    type        : 'POST',
    data        : data,
    cache       : false,
    dataType    : 'json',
    processData : false,
    contentType : false,
    success     : function(respond) {
      if (typeof respond.error === 'undefined') {
        $.toast({
          heading:            'Success',
          text:               'Данные сохранены',
          showHideTransition: 'slide',
          icon:               'success'
        });

        var urlParams = new URLSearchParams(window.location.search);
        window.location.href = window.location.pathname + '?' + urlParams.toString();
      } else {
        console.log('ОШИБКА: ' + respond.error);
      }
    },
    error: function(jqXHR, status) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
      $.toast({
        heading:            '',
        text:               'Произошла ошибка',
        showHideTransition: 'fade',
        icon:               'error'
      });
    }
  });
}

$('#manager-table-tickets th').click(function() {
  var urlParams  = new URLSearchParams(window.location.search);
  var sortColumn = urlParams.get('sort');
  var sortType   = urlParams.get('sort_type');
  var columnName = $(this)[0].dataset.columnName;

  if (!columnName) {
    return;
  }

  if (sortColumn === columnName) {
    sortType = (sortType === 'desc') ? 'asc' : 'desc';
  } else {
    sortColumn = columnName;
    sortType = 'desc';
  }

  urlParams.set('sort', sortColumn);
  urlParams.set('sort_type', sortType);

  window.location.href = window.location.pathname + '?' + urlParams.toString();
});
