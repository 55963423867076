if ($("#fourthtab").length){

  function setElementVisibility(selector, isVisible = true) {
    $(selector).each(function() {
      $(this).css('display', isVisible ? 'block' : 'none');
    });
  }

  function handleDocTypeState(doc) {
    const userCountry = $('.country_info')?.attr('country')?.trim()?.toLowerCase() || '';
    const secondPic = document.querySelectorAll('.upload-images__second-photo');

    const is3PicNeeded = doc === 'identification' || doc === 'driver-license';

    setElementVisibility(secondPic, is3PicNeeded);

    const photos = document.querySelectorAll('.upload-images-page div.kv-file-content > img');

    const slotMapping = {
      0: 1,
      1: 1,
      2: 2,
      3: 2,
      4: 3,
      5: 3,
      6: 1,
      7: 1,
      8: 2,
      9: 2,
      10: 3,
      11: 3
    };

    photos.forEach((photo, index) => {
      let slot = slotMapping[index];

      const imgSource = getPhotoSourceByCountryAndDoctype(userCountry, doc, slot);

      changeImageSource(photo, imgSource);
    });

  }

  const select = $("#modeldata-doc_type") || $('select');

  select.change(function(e){ // TODO fix
    handleDocTypeState(e?.target?.value || 'passport');
  });

  $(document).ready(() => {
    // initial action
    setTimeout(() => {
      handleDocTypeState('passport');
    }, 1);
  });

  //quantity photo
  const docPhoto = $(".doc-photo .single_photo_wrap");
  const docPhotoSecond = $(".doc-photo .single_photo_wrap:nth-child(3)");
  const docBtnBlock = $(".doc_type_wrap .button_wrap");

  if ($('.four-step__second-model').length === 1){
    docBtnBlock.addClass("js-photo-quantity-pair-six");
  } else docBtnBlock.removeClass("js-photo-quantity-pair-six");

  const country = $('.country_info').text();

  function checkDocument(){
    $result = $("#doctypeform-doc_type").val();
    if ((($result === "driver-license") && ($('.four-step__second-model').length === 1)) ||
      ((country === "Украина") && ($result === "passport") && ($('.four-step__second-model').length === 1))
      || (($result === "identification") && ((country === 'Казахстан') || (country === 'Молдавия'))) && ($('.four-step__second-model').length === 1)){
          docPhoto.css("display", "block");
          docBtnBlock.addClass("js-photo-quantity-pair-eight");
          docPhotoSecond.css("display", "block");
          docPhoto.addClass("js-photo-quantity-four");
          docBtnBlock.removeClass("docIdentification");
          docBtnBlock.removeClass("js-doc-identification-pair");
    } else if(($result === "driver-license") || (($result === "identification") && ((country === 'Казахстан') || (country === 'Молдавия'))) ||
              ((country === "Украина") && ($result === "passport"))){
              docPhoto.css("display", "block");
              docPhotoSecond.css("display", "block");
              docPhoto.addClass("js-photo-quantity-four");
              docBtnBlock.addClass("js-photo-quantity-four");
              docPhoto.addClass("js-photo-quantity-four");
              docBtnBlock.removeClass("docIdentification");
              docBtnBlock.removeClass("js-doc-identification-pair");
    }else {
      docPhoto.css("display", "block");
      docPhotoSecond.css("display", "none");
      docPhoto.removeClass("js-photo-quantity-four");
      docBtnBlock.removeClass("js-photo-quantity-four");
      docPhoto.removeClass("js-photo-quantity-four");
      docBtnBlock.removeClass("js-photo-quantity-pair-eight");
      docBtnBlock.removeClass("docIdentification");
      docBtnBlock.removeClass("js-doc-identification-pair");
    }
    if ((country === "Россия") || (country === "Украина") || (country === "Беларусь")){
      $("#doctypeform-doc_type-styler li:nth-child(4)").css("display", "none");
    }
  }
  $("#doctypeform-doc_type").change(function() {
      checkDocument();
    });

//webcam photo
  $('.js-web-photo').click(function() {
    $('.modal-snapshot').show();
    startWebCam();
  });
  $('.close-modal').click(function() {
    stopMediaTracks();
  })
//webcam photo end
}
