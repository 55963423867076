if ($("#sattelit-article").length){
	function getArticle() {
    let id = strGET = window.location.search.replace( '?id=', ''); 
    var data = new FormData();
    data.append( 'id', id );
      $.ajax({
        url         : '/user/ajaxgetsinglearticle',
        type        : 'POST', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
            //console.log(respond);
            generateArticle(respond);
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log(jqXHR);
        }
    });
    }
    function generateArticle(respond) {
      let text = respond.description;
      if (respond.content == 'not_full') {
        text = text.substr(0, 3000);
        $('.sattelit-article__stop-banner').show();
      }
      $('.sattelit-article__single-content').html(text);
    }
    getArticle();


    function getAticles(){
      let start = 0;
      let stop = 3;
      let data = new FormData();
      data.append( 'start', start );
      data.append( 'stop', stop );
      $.ajax({
          url         : '/user/ajaxgetfourarticles',
          type        : 'POST', // важно!
          data        : data,
          cache       : false,
          dataType    : 'json',
          // отключаем обработку передаваемых данных, пусть передаются как есть
          processData : false,
          // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
          contentType : false,
          // функция успешного ответа сервера
          success     : function( respond, status, jqXHR ){
  
            if( typeof respond.error === 'undefined' ){
              console.log(respond);
              renderArticles(respond);

            }
            // ошибка
            else {
              console.log('ОШИБКА: ' + respond.error );
            }
          },
          // функция ошибки ответа сервера
          error: function( jqXHR, status, errorThrown ){
            console.log(jqXHR);
        }
      });
    }

    getAticles();

    function renderArticles(respond){
      $('.sattelit-home__four_news_wrap').html('');

      for (var i = 0; i < respond.length; i++) {
        $('.sattelit-home__four_news_wrap').append(
          '<div class="sattelit-home__single_news article-page">\
               <img src="/'+respond[i].photo_url+'" alt="">\
               <h4>'+respond[i].title+'</h4>\
               <p>'+respond[i].short_desc+'</p>\
               <div class="sattelit-home__button_wrapp">\
                   <button class="sattelit-home__button-read-white"><a href= "/landing/article.php?id='+respond[i].id+'">Читать</a></button>\
                   <div class="sattelit-home__date-news"> 15 Дек</div>\
               </div>\
           </div>'
          );
      }

    }
}