if ($('#modelStudy').length) {
  $('#modelStudyItem').addClass('current-page');

  $("#tabsStudy").tabs();
  $("#tabsStudy").tabs({
    active: 0
  });

  $('.model-study__tab').click(function() {
      $('.model-study__tabs').scrollTop(0);
  });

  $('.show_all_punkts').click(function() {
    $('.mobile-menu-punkts').show();
  });

  $('.js-close-popup').click(function(){
    $('.mobile-menu-punkts').hide();
  });

  $('.mobile-show-window').on('click',function(e){
    e.preventDefault();
    let id = $(this).attr('href');
    $('.help-information__fill-content').hide();
    $(id).show();
    $('.mobile-menu-punkts').hide();
  });
}