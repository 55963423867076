$(document).ready(function() {

window.addEventListener("orientationchange", function() {
  jQuery("#mainmenu").hide(0);
}, false);

$('#send_new_pwd').on('click', function(){
    var data = $(this).serialize();
        $.ajax({
            url: '/user/ajaxsendpasswordagain',
            type: 'POST',
            data: data,
            success: function(res){
                //$('#modal_box_ajax_sendagain').show();
                document.location.href = '/user/recoversecondstep';
            },
            error: function(){
              $.toast({
                heading: '',
                text: 'Error',
                showHideTransition: 'fade',
                icon: 'error'
              });
            }
        });
        return false;
});

// Пауза видео в iframe при загрузке страницы
function pauseFrameVideo(){
  $('iframe').contents().find('video').each(function ()
  {
      this.currentTime = 0;
      this.pause();
  });
}

// На esc пропадала картинка после увеличения fancy-box картинок из админки
function moveRect(e){
    switch(e.keyCode){

        case 27:  // если нажата клавиша влево
            setTimeout(showImage, 300, image, widthWas, heightWas);
            break;
    }
}

addEventListener("keydown", moveRect);


setTimeout(pauseFrameVideo, 300);
$('.youtube_pause').click(function() {
  jQuery("iframe").each(function() {
      jQuery(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')}
  );
});

$('img.zoomPhoto').fancybox({beforeClose:function(){
  image.show();
  setTimeout(showImage, 300, image, widthWas, heightWas);
}});

$('img.zoomPhoto').click(function(){
  image = $(this);
  widthWas = $(this).css('width');
  heightWas = $(this).css('height');
  image.css({
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  //$('.main').before('<div class= "test"></div>');
});


function showImage(image, width, height) {
  image.css({
    width: width,
    height: height,
  });
  image.show();
}

$('.close-modal-payment').on('click', function(){
  $('.modal-box.history-pay').hide();
});

$('.close-modal-add-stream').on('click', function(){
  $('.modal-box-add-stream').hide();
});

$('.close-modal-add-for-registrar').on('click', function(){
  $('.modal-box-add-for-registrar').hide();
  $('#for-registrar-status').prop('checked', false).trigger('refresh');
});

$('.close-modal-computer-assistance').on('click', function(){
  $('.modal-box-computer-assistance').hide();
});

$('.close-modal-coaching-assign').on('click', function(){
  $('.modal-box-coaching-assign').hide();
});

$('.close-modal-add-for-quality-control').on('click', function(){
  $('.modal-box-add-for-quality-control').hide();
  $('#control-status').prop('checked', false).trigger('refresh');
});

$('.close-modal').on('click', function(){
  $('.modal-box').hide();
});


$('.public_offer').on('click', function(){
  $('.public_offer_modal').show();
});

$('.privacy_cond').on('click', function(){
  $('.privacy_cond_modal').show();
});


$('.ajax_set_plan_model_name').keyup(function() {
  var text = $('.ajax_set_plan_model_name').val();
  if (text == '') {
    return  false;
  }
  var data = new FormData();
  data.append('text', text);
  $.ajax({
    url         : '/manager/ajaxsmartsearch',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        fillSmartSearch(respond);
      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }
  });
});

function fillSmartSearch(respond) {
  $('.smart_search').show();
  $('.smart_search ul li').remove();
  for (var i = 0; i <= respond.length-1; i++) {
    $('.smart_search ul').append('<li><a class = "click_me_name" style="cursor:pointer;">'+respond[i].second_name+' '+respond[i].first_name+'</a></li>');
  }
}

  $('body').on('click', '.click_me_name', function(){
    var name = $(this).text();
    $('.ajax_set_plan_model_name').val(name);
    $('.smart_search').hide();
    var attrname = $('#period-select-date-plan option:selected').attr('name');
    var year = $('#select-period-year-second-plan option:selected').val();
    var dateArr = attrname.split('.');
    var date = dateArr[0]+'-'+dateArr[1];
      var data = new FormData();
      data.append( 'name', name );
      data.append( 'date', date );
      data.append('year', year);
      $.ajax({
      url         : '/manager/ajaxgetmodelplanforperiod',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          //console.log(respond);
          $('.model_plan_cash_value').empty();
          $('.model_plan_cash_value').append(respond);
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $('.model_plan_cash_value').empty();
        $('.model_plan_cash_value').append('---');
      }
    });

  });


// Создание плана со страницы allmodels
  $('.ajax_creata_new_model_plan').click(function(){
  var name = $('.ajax_set_plan_model_name').val();
  var period = $('#period-select-date-plan option:selected').attr('name');
  var date_array = period.split('.');
  var date = date_array[0]+'-'+date_array[1];
  var cash = $('.ajax_set_plan_model_cash').val();
  var year = $('#select-period-year-second-plan option:selected').val();
  //console.log(year);
  var data = new FormData();
  if (name == '') {
    $.toast({
      heading: '',
      text: 'Введите имя',
      showHideTransition: 'fade',
      icon: 'error'
    });
    return false;
  }
  if (cash == '') {
    $.toast({
      heading: '',
      text: 'Введите план',
      showHideTransition: 'fade',
      icon: 'error'
    });
    return false;
  }
  data.append( 'name', name );
  data.append( 'date', date );
  data.append('cash', cash);
  data.append('year', year);
    $.ajax({
      url         : '/manager/ajaxsetmodelplan',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          console.log(respond);
          $.toast({
            heading: 'Success',
            text: 'План для модели успешно создан',
            showHideTransition: 'slide',
            icon: 'success'
          });
          recalculateTableWithNewModelPlan(year, date, cash, respond);
          $('.model_plan_cash_value').text(cash);
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $.toast({
          heading: '',
          text: 'Модели с таким именем и фамилией не найдено',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });

});

function recalculateTableWithNewModelPlan(year, date, cash, respond) {
    if ($('#period-inteval').is(':checked')){
      // Дата начала интервала дданных на странице
    var monthDayStart = $("#period-select-date option:selected").attr('name');
    var yearStart = $("#select-period-year option:selected").val();
    var array = monthDayStart.split('.');
    fromTable = yearStart + '-' + array[0] + '-' + array[1];
      // Дата конечного инетвала данных на странице
    var monthDayFinish = $("#period-select-date-second option:selected").attr('name');
    var yearFinish = $("#select-period-year-second option:selected").val();
    var array = monthDayFinish.split('.');
    toTable = yearFinish + '-' + array[0] + '-' + array[2];
      // Дата выставленная в план
    datePlan = year +'-'+date;
    if (new Date(datePlan)> new Date(fromTable) && new Date(datePlan) < new Date(toTable) ) {
      var planWas = Number($('.model_plan_cash_value').text());
      var planDiff = planWas - cash;
      var bigPlanWasAllmodels = Number($('.ajax_this_have_full_access[name="'+respond+'"]').children('.manager-main__table-plan').text());
      var resultAllmodels = bigPlanWasAllmodels - planDiff;
      var bigPlanWasFinance = Number($('.ajax_this_have_full_access[name="'+respond+'"]').children('.superuser-finance__table-plan').text());
      var resultFinance = bigPlanWasFinance - planDiff;
      $('.ajax_this_have_full_access[name="'+respond+'"]').children('.manager-main__table-plan').text(resultAllmodels.toFixed(2));// пересчет нового плана для manager/allmldels
      $('.ajax_this_have_full_access[name="'+respond+'"]').children('.superuser-finance__table-plan').text(resultFinance.toFixed(2)); // Пересчет плана на станице superuser/finance
      var totalPlanfinancePage = Number( $('#myTable tfoot tr').children('.superuser-finance__table-plan').text());
      var resultTotalFinancePage = totalPlanfinancePage - planDiff;
      $('#myTable tfoot tr').children('.superuser-finance__table-plan').text(resultTotalFinancePage.toFixed(2));
      var totalPlanallmodelsPage = Number($('table').children('#total-line').children('.manager-main__table-plan').text());
      var resultTotalFinancePage = totalPlanallmodelsPage - planDiff;
      $('table').children('#total-line').children('.manager-main__table-plan').text(resultTotalFinancePage.toFixed(2));
      //console.log($('table').children('#total-line').children('.manager-main__table-plan'));

    }else {
      return false;
    }

  } else {
    datePlan = year +'-'+date;
    var monthDay = $("#period-select-date option:selected").attr('name');
    var year = $("#select-period-year option:selected").val();
    var array = monthDay.split('.');
    from = year + '-' + array[0] + '-' + array[1];
    if (new Date(from) >= new Date(datePlan) && new Date(from) <= new Date(datePlan)) {
      var planWas = Number($('.model_plan_cash_value').text());
      var planDiff = planWas - cash;
      var totalPlanfinancePage = Number( $('#myTable tfoot tr').children('.superuser-finance__table-plan').text());
      var resultTotalFinancePage = totalPlanfinancePage - planDiff;
      $('#myTable tfoot tr').children('.superuser-finance__table-plan').text(resultTotalFinancePage.toFixed(2));
      var totalPlanallmodelsPage = Number($('table').children('#total-line').children('.manager-main__table-plan').text());
      var resultTotalFinancePage = totalPlanallmodelsPage - planDiff;
      $('table').children('#total-line').children('.manager-main__table-plan').text(resultTotalFinancePage.toFixed(2));
      $('.ajax_this_have_full_access[name="'+respond+'"]').children('.manager-main__table-plan').text(cash);
      $('.ajax_this_have_full_access[name="'+respond+'"]').children('.superuser-finance__table-plan').text(cash);
    }else {
      return false;
    }
  }
}

//Создание плана со странцицы singlemodel
$('.ajax_creata_new_model_plan_singlemodel_page').click(function(){
      var cash = $('.ajax_set_plan_model_cash').val();
      if (cash == '') {
        $.toast({
          heading: '',
          text: 'Укажите сумму плана',
          showHideTransition: 'fade',
          icon: 'error'
        });
        return false;
      }
      var period = $('#period-select-date-plan-singlemodel-page option:selected').attr('name');
      var date_array = period.split('.');
      var year = $('#select-period-year-second-plan option:selected').val();
      var date = year + '-' + date_array[0]+'-'+date_array[1];
      var get = window.location.search.replace( '?', '');
      var modelId = parseInt(get.substr(3));
      var data = new FormData();
      data.append( 'modelId', modelId );
      data.append( 'cash', cash );
      data.append( 'date', date );
      $.ajax({
      url         : '/manager/ajaxsetmodelplaninsinglemodel',
      type        : 'POST',
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          //console.log(respond);
          $('.model_plan_cash_value').text(cash);
           $.toast({
            heading: 'Success',
            text: 'План успешно создан',
            showHideTransition: 'slide',
            icon: 'success'
        });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      }

      });

});


$('#period-select-date-plan').change(function() {
  var attrname = $('#period-select-date-plan option:selected').attr('name');
  var dateArr = attrname.split('.');
  var date = dateArr[0]+'-'+dateArr[1];
  var name = $('.ajax_set_plan_model_name').val();
  var year = $('#select-period-year-second-plan option:selected').val();
  if (name == '') {
    return false;
  }
  var data = new FormData();
  data.append( 'name', name );
  data.append( 'date', date );
  data.append('year', year);
  $.ajax({
      url         : '/manager/ajaxgetmodelplanforperiod',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          //console.log(respond);
          $('.model_plan_cash_value').empty();
          $('.model_plan_cash_value').append(respond);
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $('.model_plan_cash_value').empty();
        $('.model_plan_cash_value').append('---');
      }
    });
});

$('.clear_plan_window').click(function(){
  $('.ajax_set_plan_model_name').val('');
  $('.ajax_set_plan_model_cash').val('');
  $('.model_plan_cash_value').text('');
});

(function($) {
  $(function() {
    $('select, input').styler();
  });
  })(jQuery);

});

function showModalMsgDlg(msg){
  const wnd = document.createElement('div');
  wnd.innerHTML =
  `<div class="modal-box">
    <div class="modal-content">
      <div class="cross close-modal"></div>
      <span class="modal-label">
        ${msg}
      </span>
      <button class="btn btn-tertiary btn-modal close-modal">OK</button>
    </div>
  </div>`.trim();
  document.body.appendChild(wnd);
  for(let btn of wnd.querySelectorAll('button, .close-modal')){
    btn.addEventListener('click', () => { wnd.remove();})
  }
}

function setCookie(cookieName, cookieValue, expiresAfter) {
  const date = new Date();
  date.setTime(date.getTime() + (expiresAfter * 24 * 60 * 60 * 1000));
  let expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

function getCookie(cookieName) {
  let name = cookieName + "=";
  let cookiesArray = document.cookie.split(';');
  for(let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

$('.ajax_send_photo').on('change', function(){
  files = this.files;
  if (Number(files[0].size) > 15728640 || Number(files[0].size) < 10240) {
    $('.ajax_send_photo').prop("value", "");
    showModalMsgDlg('Размер файла должен быть от 0.01 до 15МБ');
    return false;
  }

  $(".preloader").fadeIn();
  position = $(this).prop('name');
  checkbox_block = $(this).parent().parent().parent().children('div:nth-child(2)');
  if( typeof files == 'undefined' ) return;
  var data = new FormData();
  //console.log(files);
  $.each( files, function( key, value ){
    data.append( key, value );
  });
  $(this).parent().parent().parent().find('.hide_this_after_load').hide();
  if ($('.show_ajax_photo_12').css('display') === 'block'){
    $('#show_photo').hide();
  }
  if (position === '13') {
      $('.single_main_photo_wrap').each(function(elem){
        if( $(this).css('display') === 'none'){
          const positionPhoto = $(this).attr('name');
          data.append( 'position', positionPhoto );
          return false;
        }
        if (elem === 12) {
          $('#show_photo').hide();
        }
      })
  } else {
    data.append( 'position', position );
  }
  //console.log(data);
  if(typeof $('#'+position).data('id') !== 'undefined') data.append('id', $('#'+position).data('id'));

  $.ajax({
    url         : '/user/ajaxloadphoto',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервераfd
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        const firstPhotoLoadedCookie = getCookie('firstPhotoLoaded');
        const isFirstPhoto = firstPhotoLoadedCookie === null || firstPhotoLoadedCookie === '0'

        $('.preloader').fadeOut('slow', function() {});
        src = respond;
        var from = src.search("upload");
        var to = src.length;
        newsrc = src.substring(from,to);
        newsrc = '\\'+newsrc;
        console.log(newsrc);
        if (position === '13') {
          $('.single_main_photo_wrap').each(function(){
            if( $(this).css('display') === 'none'){
              $('img', this).prop('src', newsrc);
              $(this).css('display', 'block');
              $('.single_photo', this).css('display', 'block');
              return false;
            }
          })
        } else {
          $('#'+position).prop('src', newsrc);
          $('.ajax_position_'+position).hide();
          $('.show_ajax_photo_'+position).show();
        }
      }
      // ошибка
      else {
        $('.preloader').fadeOut('slow', function() {});
        showModalMsgDlg(respond.error);
        console.log('ОШИБКА: ' + respond.error);
        $('.ajax_send_photo').prop("value", "");
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      $('.preloader').fadeOut('slow', function() {});
      $('.ajax_send_photo').prop("value", "");
      $.toast({
        heading: '',
        text: 'Ошибка сервера. Попробуйте повторить попытку позже.',
        showHideTransition: 'fade',
        icon: 'error'
      });
    }

  });
});

$('.mask_date').mask('99.99.9999');
$('.mask_time').mask('99:99');
$('.mask_phone').mask('+7 (999) 999-99-99');

$(".fancybox").click(function(){
  const fancybox = $(this);
  const photoImg = fancybox.find("img");
  const src = photoImg.prop('src');
  fancybox.prop("href", src);
});

$(document).ready(function() {
  $(".fancybox").fancybox({
    padding : 0,
      helpers: {
        overlay: {
          locked: false
        }
      }
  });
});


function showOrHideImg3(){
   if($("#articlesform-first").is(':checked'))  $('.first_img').addClass('hidden');
    else $('.first_img').removeClass('hidden');

   if($("#articles-first").is(':checked'))  $('.first_img').addClass('hidden');
    else $('.first_img').removeClass('hidden');

}

$.ajax({
  url : '/user/ajaxgetfullaccessstatus',
  type        : 'GET',
  cache       : false,
  processData : false,
  contentType : false,
  success     : function (data) {
    if (data.length === 0) {
      const chatBotScript = document.createElement('script');

      chatBotScript.setAttribute('src', 'https://wmagency.brandy.im/api/Scripts/ChatPlatform/chatBot.js');
      document.head.appendChild(chatBotScript);
    }
  }
});
