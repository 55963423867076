if ($("#sattelit-home").length){
	function getAllArticles() {
    var data = new FormData();
    data.append( 'test', 'test' );
      $.ajax({
        url         : '/user/ajaxapigetallarticles',
        type        : 'POST', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
            //console.log(respond);
            renderContent(respond);
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log(jqXHR);
        }
    });
    }

    getAllArticles();

    function renderContent(respond) {
    	for (var i = 0; i < respond.length; i++) {
    		if (i == 0) {
    			$('.sattelit-home__big_article_wrap').css('background-image', 'url(/' + respond[i].photo_url + ')');
    			$('.sattelit-home__big_article_wrap').children('.sattelit-home__big_arcticle_desc_wrapp').children('h3').text(respond[i].title);
    			$('.sattelit-home__big_article_wrap').children('.sattelit-home__big_arcticle_desc_wrapp').children('p').html(respond[i].short_desc);
    			$('.sattelit-home__big_article_wrap').children('.sattelit-home__big_arcticle_desc_wrapp').children('button').html('<a href= "/landing/article.php?id='+respond[i].id+'">Читать</a>');
    		}else {
    			let hidden = 'show';
    			if (i > 4) {
    				hidden = 'hide_article';
    			}
    			$('.sattelit-home__four_news_wrap.four-articles').append('<div class="sattelit-home__single_news '+hidden+'">\
    				<img src="/'+respond[i].photo_url+'" alt="">\
    				<h4>'+respond[i].title+'</h4>\
    				<p>'+respond[i].short_desc+'</p>\
    				<div class="sattelit-home__button_wrapp">\
    				<button class="sattelit-home__button-read-white"><a href= "/landing/article.php?id='+respond[i].id+'">Читать</a></button>\
    				<div class="sattelit-home__date-news"> 15 Дек</div>\
    				</div>\
    				</div>');
    		}
    	}
    	$('.sattelit-home__more-news-count').text(respond[0].rest_articles);
    }

    $('.show_more_articles').click(function() {
    	$('.sattelit-home__four_news_wrap').children('.hide_article').show(500);
    	$('.sattelit-home__more-news-count').text('');
    });
}