if ($("#secondtab").length){
  $(".select_messenger").click(function() {
    $(".dropdown_messenger").toggle();
    $(this).addClass("active");
  });

  jQuery(function($){
    $(document).mouseup(function (e){
        const block = $(".dropdown_messenger");
        if (!block.is(e.target)
            && block.has(e.target).length === 0) {
            block.hide();
            $(".select_messenger").removeClass("active");
        }
    });
  });

  $(".dropdown_messenger li").click(function() {
    $(this).addClass('hide');
    const selectedMessenger = $(this).text().toLowerCase();
    $("#" + selectedMessenger).removeClass('hide');
    $("#" + selectedMessenger).addClass('active');
  });

  $(".delete-messenger").click(function() {
    $(this).parent().addClass('hide');
    $(this).parent().removeClass('active');
    const parentId = $(this).parent().prop('id');
    $(".dropdown_messenger li." + parentId).removeClass('hide');
    if ($(".messenger_input.active").length === 3){
      $(".block_select_messenger").removeClass('hide');
      $(".dropdown_messenger").css('display', 'none');
      $(".select_messenger").removeClass('active');
    }
  });

  (function(){
    var originalAddClassMethod = jQuery.fn.addClass;
    $.fn.addClass = function(){
        var result = originalAddClassMethod.apply(this, arguments);
        $(this).trigger('cssClassChanged');
        return result;
    }
  })();

  $(function(){
    $(".messenger_input").bind('cssClassChanged', function(){
      if ($(".messenger_input.active").length > 3){
        $(".block_select_messenger").addClass('hide');
      }
    });
  });
}

// autofill messenger
function autofillMessenger(input) {
  if ($("#secondtabform-phone").val() !== ""){
    input.val($("#secondtabform-phone").val());
  }
}
const telegramInput = $("#secondtabform-telegram");
const whatsappInput = $("#secondtabform-whatsapp");
const viberInput = $("#secondtabform-viber");
telegramInput.focus(function(){
  if (telegramInput.val() === ""){
    autofillMessenger(telegramInput);
  }
});
whatsappInput.focus(function(){
  if (whatsappInput.val() === ""){
    autofillMessenger(whatsappInput);
  }
});
viberInput.focus(function(){
  if (viberInput.val() === ""){
    autofillMessenger(viberInput);
  }
});


