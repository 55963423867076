if ($("#modelCommon").length){
  //constructor work
  $(".constuctor-btn").click(function(){
    $(".superuser-common__constructor-block").show();
  });
  $(".close-constructor-block").click(function(){
    $(".superuser-common__constructor-block").hide();
  });
  jQuery(function($){
    $(document).mouseup(function (e){
        const block = $(".superuser-common__constructor-block");
        if (!block.is(e.target)
            && block.has(e.target).length === 0) {
            block.hide();
        }
    });
  });

  $("#constuctor-contacts").change(function(){
    if ($("#constuctor-contacts").prop("checked")){
      $(".superuser-common__table-phone").show();
      $(".superuser-common__table-skype").show();
      $(".superuser-common__table-whatsapp").show();
      $(".superuser-common__table-viber").show();
      $(".superuser-common__table-telegram").show();
      $(".superuser-common__table-mail").show();
    }else{
      $(".superuser-common__table-phone").hide();
      $(".superuser-common__table-skype").hide();
      $(".superuser-common__table-whatsapp").hide();
      $(".superuser-common__table-viber").hide();
      $(".superuser-common__table-telegram").hide();
      $(".superuser-common__table-mail").hide();
    }
  });
  $("#constuctor-sites").change(function(){
    if ($("#constuctor-sites").prop("checked")){
      $(".show-site").show();
    }else{
      $(".show-site").hide();
    }
  });
  $("#constuctor-birthday").change(function(){
    if ($("#constuctor-birthday").prop("checked")){
      $(".superuser-common__table-birthday").show();
    }else{
      $(".superuser-common__table-birthday").hide();
    }
  });
  $("#constuctor-lvl-pay").change(function(){
    if ($("#constuctor-lvl-pay").prop("checked")){
      $(".superuser-common__table-lvl").show();
    }else{
      $(".superuser-common__table-lvl").hide();
    }
  });
  $("#constuctor-documents").change(function(){
    if ($("#constuctor-documents").prop("checked")){
      $(".superuser-common__table-doc").show();
    }else{
      $(".superuser-common__table-doc").hide();
    }
  });


  //manager period

  function showBeganIntervalDate() {
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const beganOfPeriod = $("#period-select-date-styler .sel").text();
    const beganIntervalDay = beganOfPeriod.split('-');
    const beganIntervalMonth = beganOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    } else {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
      $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
    }
  }

  function showEndIntervalDate(){
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const endOfPeriod = $("#period-select-date-second-styler .sel").text();
    const endIntervalDay = endOfPeriod.split('-');
    const endIntervalMonth = endOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
    } else {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
    }
  }

  $("#period-inteval").change(function(){
    if ($("#period-inteval").prop("checked")){
      $(".superuser-common__period-select-second").css("display", "flex");
      $(".superuser-common__period-select").css("height", "140px");
      $(".end-period").css("display", "inline-block");
      $(".superuser-common__top").css("height", "200px");
      showBeganIntervalDate();
      showEndIntervalDate();
    }else {
      $(".superuser-common__period-select-second").css("display", "none");
      $(".superuser-common__period-select").css("height", "81px");
      $(".end-period").css("display", "none");
      $(".superuser-common__top").css("height", "140px");
    }
  });

  $("#period-select-date").change(function(){
    if ($("#period-inteval").prop("checked")){
      showBeganIntervalDate();
    } else {
      const beganOfPeriod = $("#period-select-date-styler .sel").text();
      $(".began-period").text(beganOfPeriod + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    }
  });

  $("#period-select-date-second").change(function(){
    showEndIntervalDate();
  });

  $('.superuser-common__period-select-year').change(function(){
    showBeganIntervalDate();
    showEndIntervalDate();
  });

  //plan show
  $(".superuser-common__plan-btn").click(function(){
    $(".show-modal .modal-box").toggle();
  });

  $('#superuserModel').addClass('current-page');

  function showLineTable(){
    const quantityLine = $(".superuser-finance__quantity-line .sel").text();
    if (quantityLine === 'Все') {
      $(".superuser-common__table tr").css("display", "flex");
    } else {
      $(".superuser-common__table tr").each(function(elem){
        if (elem <= quantityLine){
          $(this).css("display", "flex");
        }else if (elem == quantityLine){
          $(this).css("display", "flex");
          $(this).css("margin-bottom", "14px");
        } else return false;
      });
    }
  }

  $(document).ready(setTimeout(function(){
    showLineTable();
  }, 300));
  $(".superuser-finance__quantity-line").change(function(){
    $(".superuser-common__table tr").css("display", "none");
    $(".superuser-common__table  tr").css("margin-bottom", "0");
    showLineTable();
  });
}