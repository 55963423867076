if ($("#superuserSites").length){
  function showBeganIntervalDate() {
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const beganOfPeriod = $("#period-select-date-styler .sel").text();
    const beganIntervalDay = beganOfPeriod.split('-');
    const beganIntervalMonth = beganOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    } else {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
      $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
    }
  }

  function showEndIntervalDate(){
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const endOfPeriod = $("#period-select-date-second-styler .sel").text();
    const endIntervalDay = endOfPeriod.split('-');
    const endIntervalMonth = endOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
    } else {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
    }
  }

  $("#period-inteval").change(function(){
    if ($("#period-inteval").prop("checked")){
      $(".superuser-sites__period-select-second").css("display", "flex");
      $(".superuser-sites__period-select").css("height", "140px");
      $(".end-period").css("display", "inline-block");
      $(".superuser-sites__top").css("height", "200px");
      showBeganIntervalDate();
      showEndIntervalDate();
    }else {
      $(".superuser-sites__period-select-second").css("display", "none");
      $(".superuser-sites__period-select").css("height", "81px");
      $(".end-period").css("display", "none");
      $(".superuser-sites__top").css("height", "140px");
    }
  });

  $("#period-select-date").change(function(){
    if ($("#period-inteval").prop("checked")){
      showBeganIntervalDate();
    } else {
      const beganOfPeriod = $("#period-select-date-styler .sel").text();
      $(".began-period").text(beganOfPeriod + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    }
  });

  $("#period-select-date-second").change(function(){
    showEndIntervalDate();
  });

  $('.superuser-sites__period-select-year').change(function(){
    showBeganIntervalDate();
    showEndIntervalDate();
  });

  $('#superuserSitesLi').addClass('current-page');

  $('.superuser-sites__table-table tr td').each(function(){
    if ($(this).text() === 'да'){
      $(this).css('color', '#0ccd9d');
    }
  });

$(document).ready(setTimeout(function(){
  getAllAjaxDataForTable();
}, 1000));

$(".ajax_get_models_data").click(function(){
  getAllAjaxDataForTable();
});

function getAllAjaxDataForTable(){
  $(".preloader").fadeIn();
    if ($('#period-inteval').is(':checked')){
    var monthDayStart = $("#period-select-date option:selected").attr('name');
    var yearStart = $("#select-period-year option:selected").val();
    var array = monthDayStart.split('.');
    from = yearStart + '-' + array[0] + '-' + array[1];
    var monthDayFinish = $("#period-select-date-second option:selected").attr('name');
    var yearFinish = $("#select-period-year-second option:selected").val();
    var array = monthDayFinish.split('.');
    to = yearFinish + '-' + array[0] + '-' + array[2];
  } else {
    var monthDay = $("#period-select-date option:selected").attr('name');
    var year = $("#select-period-year option:selected").val();
    var array = monthDay.split('.');
    from = year + '-' + array[0] + '-' + array[1];
    to = year + '-' + array[0] + '-' + array[2];
  }
    var data = new FormData();
    data.append( 'from', from );
    data.append( 'to', to );
    $.ajax({
      url         : '/superuser/ajaxgetsitesfinancedata',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){
        if( typeof respond.error === 'undefined' ){
          //console.log(respond);
          fillTheTable(respond);
          calculateKpi3(respond.length);
          calculateTotalKpi3(respond.length);
          $('.preloader').fadeOut('slow', function() {});
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
          $('.preloader').fadeOut('slow', function() {});
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $('.preloader').fadeOut('slow', function() {});
      }
    });

}

function fillTheTable(respond) {
  //console.log(respond);
  for (var i = respond.length -1; i >= 0; i--) {
    var num = i+2;
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-model').text(respond[i].models_online);
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-hours').text(respond[i].hours_online);
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-total_cash').text(Math.round(respond[i].total_site_cash * 100)/100);
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-comission').text(Math.round(respond[i].comission * 100) + '%');
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-comission_new').text(Math.round(respond[i].comission_new * 100) + '%');
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-kpi1').text(Math.round(respond[i].kpi_1 * 100)/100);
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-kpi2').text(Math.round(respond[i].kpi_2 * 100)/100);
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-kpi3').text(Math.round(respond[i].kpi_3 * 100) + '%');
  }
  var count  = respond.length+2;
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-model').text(respond[0].full_models_online + " (KPI - " + Math.round(respond[0].summary_kpi * 100) / 100 +")");
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-total_cash').text(Math.round(respond[0].full_total_cash * 100)/100);
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-hours').text(respond[0].full_hours_online);
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-comission_new').text(Math.round(respond[0].full_percent_comission * 100 * 100)/100  + '% (*)');
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-kpi1').text(Math.round((respond[0].full_total_cash / respond[0].full_hours_online) * 100) / 100);
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-kpi2').text(Math.round((respond[0].full_hours_online / respond[0].full_models_online) * 100) / 100);
}

function calculateKpi3(count) {
var c = count+2
var totalCash = $('.superuser-sites__table-table tbody').children('tr:nth-child('+c+')').children('.superuser-sites__table-total_cash').text();
  for (var i = 0; i <= count-1; i++) {
    num = i+2;
    var siteCash = Number($('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-total_cash').text());
    //console.log(siteCash);
    //console.log(totalCash);
    var kpi3 = siteCash/totalCash;
    //console.log(kpi3);
    if (isNaN(kpi3)) { kpi3= 0;}
    $('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-kpi3').text((Math.round(kpi3 * 100)) + '%');
  }
}


function calculateTotalKpi3(count) {
  var kpi3 = 0;
  for (var i = 0; i <= count-1; i++) {
    num = i+2;
    kpi3 = kpi3 + parseFloat($('.superuser-sites__table-table tbody').children('tr:nth-child('+num+')').children('.superuser-sites__table-kpi3').text());
  }
  kpi3 = kpi3/5;
  count = count+2;
  $('.superuser-sites__table-table tbody').children('tr:nth-child('+count+')').children('.superuser-sites__table-kpi3').text((Math.round(kpi3 * 100) / 100) + '%');

}
$('#open_window_for_parse').click(function() {
  $('.show-parse-window').show();
});


$('.ajax_start_parse').click(function() {
  let dateParseFrom = $('#date_parse_from').val();
  let dateParseTo = $('#date_parse_to').val();
  if (dateParseFrom =='' || dateParseTo == '') {
    alert('Set data');
    return false;
  }
  $(".preloader").fadeIn();
  let dateArrFrom = dateParseFrom.split('.');
  let dateFrom = dateArrFrom[2] +'-'+dateArrFrom[1]+'-'+dateArrFrom[0];
  let dateArrTo = dateParseTo.split('.');
  let dateTo = dateArrTo[2] +'-'+dateArrTo[1]+'-'+dateArrTo[0];
  var data = new FormData();
  data.append( 'dateFrom', dateFrom );
  data.append( 'dateTo', dateTo );
    $.ajax({
      url         : '/superuser/ajaxparserrequest',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          console.log(respond);
          $('.preloader').fadeOut('slow', function() {});
          if (respond == 'nice') {
          $.toast({
            heading: 'Success',
            text: 'Данные получены',
            showHideTransition: 'slide',
            icon: 'success'
          });
          }else if(respond == 'no_workers') {
            $.toast({
              heading: 'Error',
              text: 'Сервис решающий рекапчи перегружен попробуйте позже',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }else if(respond == 'change_proxy') {
            $.toast({
              heading: 'Error',
              text: 'Нету рабочего прокси сервера в массиве params.php',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }else if(respond =='try_later') {
            $.toast({
              heading: 'Error',
              text: 'Не полуается войти на chaturbate, попробуйте позже',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }else if(respond =='cant_parse_chaturbate'){
            $.toast({
              heading: 'Error',
              text: 'Проблемы с парсингом chaturbate',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }else if(respond == 'cant_parse_jasmin'){
            $.toast({
              heading: 'Error',
              text: 'Проблемы с парсингом jasmin',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }else if(respond == 'cant_parse_imlive'){
            $.toast({
              heading: 'Error',
              text: 'Проблемы с парсингом imlive',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }else if(respond == 'cant_parse_myf'){
            $.toast({
              heading: 'Error',
              text: 'Проблемы с парсингом myfreecams',
              showHideTransition: 'fade',
              icon: 'error'
            });
          }
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $('.preloader').fadeOut('slow', function() {});
        $.toast({
            heading: 'Error',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
      }
    });
});

$('#getFirstMoney').on('click', function(){
  // AJAX запрос
  $.ajax({
    url         : '/superuser/ajaxgetfirstmoney',
    type        : 'POST',
//    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){
      // ОК
      if( typeof respond.error === 'undefined' ){
        $.toast({
            heading: 'Success',
            text: 'Данные загружены',
            showHideTransition: 'slide',
            icon: 'success'
          });
      }
      // error
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      $.toast({
            heading: 'Error',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
    }
  });
});


}
