if ($("#thirdtab").length){
  //hide add btn
  if ($('#addit_photo_12_wrap').css('display') === 'block'){
    $(".add-photo-block").css("display", "none");
  }

  //show or hide block
$(".slide-block-img").click(function() {
  $(".main_photo_desk").toggle( function() {});
  $(this).toggleClass("active");
});

$(".slide-block-dop-img").click(function() {
  $(".profile-dop-photo-block__span").toggle( function() {});
  $(this).toggleClass("active");
});

$( "#firstmodeldocphoto1form-imagefile" ).change(function() {
 $("#first_model_doc_photo1").submit();
});
}