// PreciseTimer class implementation
class PreciseTimer {
    constructor() {
        this.worker = null;
        this.callbacks = new Map();
        this.nextTimerId = 1;
    }

    init() {
        const workerCode = `
            let timers = new Map();
            
            self.onmessage = function(e) {
                const { type, id, interval } = e.data;
                
                if (type === 'start') {
                    const startTime = performance.now();
                    let expectedTime = startTime;
                    
                    const timerId = setInterval(() => {
                        expectedTime += interval;
                        const drift = performance.now() - expectedTime;
                        
                        self.postMessage({
                            type: 'tick',
                            id: id,
                            drift: drift
                        });
                    }, interval);
                    
                    timers.set(id, timerId);
                }
                
                if (type === 'stop') {
                    const timerId = timers.get(id);
                    if (timerId) {
                        clearInterval(timerId);
                        timers.delete(id);
                    }
                }
            };
        `;

        const blob = new Blob([workerCode], { type: 'application/javascript' });
        this.worker = new Worker(URL.createObjectURL(blob));

        this.worker.onmessage = (e) => {
            const { type, id } = e.data;
            if (type === 'tick') {
                const callback = this.callbacks.get(id);
                if (callback) {
                    callback();
                }
            }
        };
    }

    start(interval, callback) {
        if (!this.worker) {
            this.init();
        }

        const timerId = this.nextTimerId++;
        this.callbacks.set(timerId, callback);

        this.worker.postMessage({
            type: 'start',
            id: timerId,
            interval: interval
        });

        return timerId;
    }

    stop(timerId) {
        if (this.worker && this.callbacks.has(timerId)) {
            this.worker.postMessage({
                type: 'stop',
                id: timerId
            });
            this.callbacks.delete(timerId);
        }
    }

    destroy() {
        if (this.worker) {
            this.worker.terminate();
            this.worker = null;
        }
        this.callbacks.clear();
    }
}

// Main verification page class
class VerificationPage {
    constructor() {
        this.timer = new PreciseTimer();
        this.verificationTimerId = null;
        this.checkPendingVerificationLinkintervalId = null;
        this.checkVerificationStatusintervalId = null;

        this.requestFlags = {
            isGetLinkRequestInProgress: false,
            isCheckVerificationStatusRequestInProgress: false,
            isPendingVerificationLinkRequestInProgress: false
        };

        this.elements = {
            verificationLink: $('.verification-page__verification-link'),
            linkTimer: $('.verification-page__link-timer'),
            getVerificationLinkButton: $('.verification-page__get-link-button'),
            gettingLinkMessage: $('.verification-page__getting-link-message'),
            verificationDescription: $('.verification-page__description'),
            verficationProcessMessage: $('.verification-page__verification-process-message')
        };

        this.data = {
            modelId: $('.verification-page__model_id').data('modelId'),
            verificationLinkActive: $('.verification-page__verification-link-active').data('verificationLinkActive'),
            initialTimeLeft: $('.verification-page__time-left').data('timeLeft'),
            verificationStatus: $('.verification-page__verification-status').data('verificationStatus')
        };

        this.mobileDetected = new MobileDetect(window.navigator.userAgent);

        this.texts = {
            retryLinkButtonText: 'ПРОЙТИ ВЕРИФИКАЦИЮ ПОВТОРНО',
            recivedVerificationLinkTextMobile: `
                Ссылка получена, нажмите "Пройти верификацию", чтобы начать.<br>
                После истечения таймера ссылка станет недействительной, но вы всегда сможете запросить новую ссылку.<br>
                Если вы загрузили документы, то просто ожидайте. После получения результата вам будет направлено сообщение с результатом верификации в мессенджер.`,
            recivedVerificationLinkTextDesktop: `
                Ссылка получена, вам необходимо открыть ее со смартфона, чтобы пройти верификацию. Мы отправили ссылку в чат с нами в мессенджере, чтобы вам было удобнее.<br> 
                После истечения таймера ссылка станет недействительной, но вы всегда сможете запросить новую ссылку.<br>
                Если вы загрузили документы, то просто ожидайте. После получения результата вам будет направлено сообщение с результатом верификации в мессенджер.`,
            docLoadedText: `
                <h4 style="color: green"><b> ВАШИ ДОКУМЕНТЫ ПРОХОДЯТ ПРОВЕРКУ</b></h4>
                Поздравляем, зарузка документов прошла успешно!<br>
                Процесс проверки занимает от 5 минут до 24 часов, мы пришлем сообщение в мессенджере, когда он будет завершен.<br>
                <span class="loader doc-loaded-loader"></span>`,
            docRejectedText: `
                <h4 style="color: orangered"><b>ПРОЙДИТЕ ВЕРИФИКАЦИЮ</b></h4>
                Ваши документы не приняты. Не стоит расстраиваться!<br>
                Редко у кого получается загрузить документы требуемого для верифицации качества с первого раза.
                Протрите объектив камеры, убедитесь что в помещении достаточно света,
                а на документе нет бликов и подержите смартфон неподвижно 5 секунд ,
                посте того как фото уже сделано. У вас обязательно получится!`
        };

        this.VerificationStatusEnum = Object.freeze({
            INITIAL: 'initial',
            VERIFY_LINK_WAIT: 'verify_link_wait',
            LINK_EXPIRED: 'link_expired',
            DOC_LOADED: 'doc_loaded',
            DOC_REJECTED: 'doc_rejected',
            DOC_VERIFIED: 'doc_verified',
            LINK_ACTIVE: 'link_active',
            PENDING_LINK: 'pending_link'
        });

        this.init();
    }

    init() {
        this.handleVerificationStatus(this.data.verificationStatus);
        this.bindEvents();
    }

    bindEvents() {
        this.elements.getVerificationLinkButton.on('click', () => {
            this.handleGetLinkAjaxOnClick(this.data.modelId);
        });
    }

    handleVerificationStatus(status) {
        switch (status) {
            case this.VerificationStatusEnum.DOC_REJECTED:
                this.showDocRejected();
                break;
            case this.VerificationStatusEnum.DOC_LOADED:
                this.showDocLoaded();
                break;
            case this.VerificationStatusEnum.LINK_EXPIRED:
                this.showLinkExpired();
                break;
            case this.VerificationStatusEnum.LINK_ACTIVE:
                this.showLinkActive();
                break;
            case this.VerificationStatusEnum.PENDING_LINK:
                this.showPendingLink();
                break;
            default:
                this.showDefaultState();
        }
    }

    showDocRejected() {
        this.elements.verificationDescription.html(this.texts.docRejectedText).removeClass('visually-hidden');
        this.elements.getVerificationLinkButton.text(this.texts.retryLinkButtonText).removeClass('visually-hidden');
    }

    showDocLoaded() {
        this.elements.verificationDescription.html(this.texts.docLoadedText).removeClass('visually-hidden');
        this.elements.getVerificationLinkButton.addClass('visually-hidden');
    }

    showLinkExpired() {
        this.elements.verificationDescription.removeClass('visually-hidden');
        this.elements.getVerificationLinkButton.removeClass('visually-hidden');
    }

    showLinkActive() {
        const descriptionText = this.mobileDetected.mobile() || this.mobileDetected.tablet()
            ? this.texts.recivedVerificationLinkTextMobile
            : this.texts.recivedVerificationLinkTextDesktop;

        this.elements.verificationDescription.html(descriptionText).removeClass('visually-hidden');
        this.elements.verificationLink
            .removeClass('visually-hidden')
            .attr('href', this.data.verificationLinkActive);
        this.elements.getVerificationLinkButton.addClass('visually-hidden');
        this.elements.linkTimer.removeClass('visually-hidden');

        this.startLinkExistsTimer(this.data.initialTimeLeft);
        this.startVerificationStatusCheck();
    }

    showPendingLink() {
        this.elements.gettingLinkMessage.removeClass('visually-hidden');
        this.elements.getVerificationLinkButton.addClass('visually-hidden');
        this.elements.verificationDescription.removeClass('visually-hidden');
        this.startPendingLinkCheck();
    }

    showDefaultState() {
        this.elements.verificationDescription.removeClass('visually-hidden');
        this.elements.getVerificationLinkButton.removeClass('visually-hidden');
    }

    startLinkExistsTimer(initialTimeLeft = 1230) { // 20 * 60 + 30
        if (this.verificationTimerId) {
            this.timer.stop(this.verificationTimerId);
        }

        let timeLeft = initialTimeLeft;

        this.verificationTimerId = this.timer.start(1000, () => {
            if (timeLeft <= 0) {
                this.timer.stop(this.verificationTimerId);
                location.reload();
                return;
            }

            const minutes = Math.floor(timeLeft / 60);
            const seconds = timeLeft % 60;
            this.elements.linkTimer.text(`${minutes}:${seconds.toString().padStart(2, '0')}`);
            timeLeft--;
        });
    }

    startVerificationStatusCheck() {
        this.checkVerificationStatusintervalId = setInterval(() => {
            this.checkVerificationStatus(this.data.modelId);
        }, 5000);

        setTimeout(() => {
            clearInterval(this.checkVerificationStatusintervalId);
        }, 600000);
    }

    startPendingLinkCheck() {
        this.checkPendingVerificationLinkintervalId = setInterval(() => {
            this.checkPendingVerificationLink(this.data.modelId);
        }, 5000);

        setTimeout(() => {
            clearInterval(this.checkPendingVerificationLinkintervalId);
        }, 1200000);
    }

    async handleGetLinkAjaxOnClick(modelId) {
        if (this.requestFlags.isGetLinkRequestInProgress) return;

        this.requestFlags.isGetLinkRequestInProgress = true;

        try {
            const response = await this.sendRequest('/user/ajax-get-verification-link', { modelId });

            if (response.success) {
                this.elements.gettingLinkMessage.removeClass('visually-hidden');
                this.elements.getVerificationLinkButton.addClass('visually-hidden');
                this.startPendingLinkCheck();
                this.showVerificationSuccessToast(response.message);
            } else {
                this.showVerificationErrorToast(response.message);
            }
        } catch (error) {
            this.showVerificationErrorToast(this.getErrorMessage(error));
        } finally {
            this.requestFlags.isGetLinkRequestInProgress = false;
        }
    }

    async checkVerificationStatus(modelId) {
        if (this.requestFlags.isCheckVerificationStatusRequestInProgress) return;

        this.requestFlags.isCheckVerificationStatusRequestInProgress = true;

        try {
            const response = await this.sendRequest('/user/ajax-check-verification-status', { modelId });

            if (response.success) {
                location.reload();
            }
        } catch (error) {
            this.showVerificationErrorToast(this.getErrorMessage(error));
        } finally {
            this.requestFlags.isCheckVerificationStatusRequestInProgress = false;
        }
    }

    async checkPendingVerificationLink(modelId) {
        if (this.requestFlags.isPendingVerificationLinkRequestInProgress) return;

        this.requestFlags.isPendingVerificationLinkRequestInProgress = true;

        try {
            const response = await this.sendRequest('/user/ajax-check-pending-verification-link', { modelId });

            if (response.success) {
                this.handleSuccessfulPendingLinkCheck(response);
            }
        } catch (error) {
            this.showVerificationErrorToast(this.getErrorMessage(error));
        } finally {
            this.requestFlags.isPendingVerificationLinkRequestInProgress = false;
        }
    }

    handleSuccessfulPendingLinkCheck(response) {
        clearInterval(this.checkPendingVerificationLinkintervalId);

        this.elements.verificationLink
            .removeClass('visually-hidden')
            .attr('href', response.data);
        this.elements.gettingLinkMessage.addClass('visually-hidden');

        const descriptionText = this.mobileDetected.mobile() || this.mobileDetected.tablet()
            ? this.texts.recivedVerificationLinkTextMobile
            : this.texts.recivedVerificationLinkTextDesktop;

        this.elements.verificationDescription
            .html(descriptionText)
            .removeClass('visually-hidden');

        this.elements.linkTimer.removeClass('visually-hidden');

        this.startLinkExistsTimer();
        this.startVerificationStatusCheck();
        this.showVerificationSuccessToast(response.message);
    }

    async sendRequest(url, data) {
        try {
            const response = await $.ajax({
                url,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json'
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    getErrorMessage(error) {
        const status = error.status;
        const errorMessages = {
            0: 'Нет подключения к серверу',
            404: 'Запрашиваемый URL не найден',
            500: 'Внутренняя ошибка сервера'
        };
        return errorMessages[status] || `Произошла ошибка: ${error.statusText}`;
    }

    showVerificationErrorToast(message) {
        $.toast({
            heading: 'Ошибка',
            text: message,
            showHideTransition: 'fade',
            icon: 'error'
        });
    }
}

// Инициализация при загрузке документа
$(document).ready(() => {
    new VerificationPage();
});