$("#burger-menu").click(function() {
  $(this).toggleClass("open");
  $(".nav-mobile__menu").toggleClass("open");
  $(".main").toggleClass("open");
  $(".footer").toggleClass("open");
});
jQuery(function($) {
  $(document).mouseup(function(e) {
    const block = $(".header-menu__dropmenu");
    if (!block.is(e.target) && block.has(e.target).length === 0) {
      block.removeClass("active");
    }
  });
});
$(".more-menu").click(function(e) {
  $(".header-menu__dropmenu").addClass("active");
});
