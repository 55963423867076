$(() => {
    const submitNewModelCommentBtnElement = $('.submit-new-model-comment-btn');

    function saveNewModelComment(commentValue, modelId) {
        const data = new FormData();
        data.append('comment', commentValue);
        data.append('model_id', modelId);

        $.ajax({
            url: '/manager/ajaxnewmodelcommentsave',
            type: 'POST',
            data: data,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: function(respond) {
                if (typeof respond.error === 'undefined') {
                    $.toast({
                        heading: 'Success',
                        text: 'Данные сохранены',
                        showHideTransition: 'slide',
                        icon: 'success'
                    });
                } else {
                    console.log('ОШИБКА: ' + respond.error);
                }
            },
            error: function(jqXHR, status) {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
                $.toast({
                    heading: '',
                    text: 'Произошла ошибка',
                    showHideTransition: 'fade',
                    icon: 'error'
                });
            }
        });
    }

    submitNewModelCommentBtnElement.on('click', function() {
        const inputValue = $(this).prev().val().replace(/"/g, '\\"');
        const modelId = $(this).next().data('comment_model-id');

        saveNewModelComment(inputValue, modelId);
    });
});
