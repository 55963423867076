$(document).ready(function () {
  const createDispatchTaskOpenModalBtn = $('.create-dispatch-task__open-modal-btn');
  const createDispatchTaskModal = $('.create-task-dispatch-modal');
  const closeDispatchTaskModal = $('.create-dispatch-task-modal__close-btn');

  const createMessageDispatchTaskBtn = $('.week-dispatch-messenger__create-task-btn');
  const updateMessageTaskBtn = $('.week-dispatch-messenger__update-task-btn');

  const createEmailDispatchCampaignBtn = $(".week-dispatch-email-page__campaign-create-btn");
  const updateEmailDispatchTaskBtn = $('.week-dispatch-email__update-task-btn');
  const testMessengerDispatchTaskBtn = $('.week-dispatch-messenger__test-task-btn');

  createDispatchTaskOpenModalBtn.on('click', function () {
    createDispatchTaskModal.css('display', 'flex')
  });

  closeDispatchTaskModal.on('click', function () {
    createDispatchTaskModal.hide()
  });

  createEmailDispatchCampaignBtn.on('click', function (evt) {
    evt.preventDefault();
    const campaignSubject = $('#week-dispatch-email__campaign-subject-create').val();
    const campaignTemplate = $('#week-dispatch-email__campaign-template-create').val();
    const selectedSegment = $('#week-dispatch-email__campaign-segment-create').val();
    const campaignName = $('#week-dispatch-email__campaign-name-create').val();
    const addressBookName = $('#week-dispatch-email__address-book-name-create').val();
    const campaignSendTime = $('#week-dispatch-email__campaign-datetime-create').val();

    handleCreateEmailCampaignDispatchAjaxOnClick(
        campaignSubject,
        campaignTemplate,
        selectedSegment,
        campaignName,
        addressBookName,
        campaignSendTime
    );
  });

  updateEmailDispatchTaskBtn.on('click', function(evt) {
    evt.preventDefault();
    const dbCampaignId = $(this).closest('tr').find('.week-dispatch-email__campaign-db-id').data('dbCampaignId');
    const campaignId = $(this).closest('tr').find('.week-dispatch-email__campaign-id').data('campaignId');
    const campaignSubject = $(this).closest('tr').find('.week-dispatch-email__campaign-subject-update').val();
    const campaignTemplate = $(this).closest('tr').find('.week-dispatch-email__campaign-template-update').val();
    const campaignAddressBook = $(this).closest('tr').find('.week-dispatch-email__campaign-address-book-update').val();
    const campaignName = $(this).closest('tr').find('.week-dispatch-email__campaign-address-book-name-update').val();
    const campaignSendTime = $(this).closest('tr').find('.week-dispatch-email__send-at-update-input').val();

    handleUpdateEmailDispatchCampaignAjaxOnClick(
      dbCampaignId,
      campaignId,
      campaignSubject,
      campaignTemplate,
      campaignAddressBook,
      campaignName,
      campaignSendTime
    );
  });

  createMessageDispatchTaskBtn.on('click', function (evt) {
    evt.preventDefault();
    const segment1Message = $('#week-dispatch-messenger__message-1-create').val();
    const segment1ImageUrl = $('#week-dispatch-messenger__image-url-1-create').val();

    const segment2Message = $('#week-dispatch-messenger__message-2-create').val();
    const segment2ImageUrl = $('#week-dispatch-messenger__image-url-2-create').val();

    const segment3Message = $('#week-dispatch-messenger__message-3-create').val();
    const segment3ImageUrl = $('#week-dispatch-messenger__image-url-3-create').val();

    const segment4Message = $('#week-dispatch-messenger__message-4-create').val();
    const segment4ImageUrl = $('#week-dispatch-messenger__image-url-4-create').val();

    const sendAt = $('#week-dispatch-messenger__datetime-create').val();

    handleCreateDispatchTaskMessageAjaxOnClick(
      segment1Message,
      segment1ImageUrl,
      segment2Message,
      segment2ImageUrl,
      segment3Message,
      segment3ImageUrl,
      segment4Message,
      segment4ImageUrl,
      sendAt
    );
  });

  updateMessageTaskBtn.on('click', function (evt) {
    evt.preventDefault();
    const taskId = $(this).closest('tr').find('.week-dispatch-messenger__task-id').data('taskId');

    const segment1Message = $(this).closest('tr').find('.week-dispatch-messenger__message-1-update').val();
    const segment1ImageUrl = $(this).closest('tr').find('.week-dispatch-messenger__image-url-1-update').val();

    const segment2Message = $(this).closest('tr').find('.week-dispatch-messenger__message-2-update').val();
    const segment2ImageUrl = $(this).closest('tr').find('.week-dispatch-messenger__image-url-2-update').val();

    const segment3Message = $(this).closest('tr').find('.week-dispatch-messenger__message-3-update').val();
    const segment3ImageUrl = $(this).closest('tr').find('.week-dispatch-messenger__image-url-3-update').val();

    const segment4Message = $(this).closest('tr').find('.week-dispatch-messenger__message-4-update').val();
    const segment4ImageUrl = $(this).closest('tr').find('.week-dispatch-messenger__image-url-4-update').val();

    const dateTimeSend = $(this).closest('tr').find('.week-dispatch-messenger__send-at-update-input').val();

    handleUpdateMessageAjaxOnClick(
      taskId,
      segment1Message,
      segment1ImageUrl,
      segment2Message,
      segment2ImageUrl,
      segment3Message,
      segment3ImageUrl,
      segment4Message,
      segment4ImageUrl,
      dateTimeSend
    );
  });

  testMessengerDispatchTaskBtn.on('click', function (evt) {
    evt.preventDefault();
    const taskId = $(this).closest('tr').find('.week-dispatch-messenger__task-id').data('taskId');
    const saleBotId = $(this).closest('tr').find('.week-dispatch-messenger__test-salebot-id-input').val();
    const segmentNumber = $(this).closest('tr').find('.week-dispatch-messenger__test-segment-number-input').val();

    handleTestMessengerDispatchTaskAjaxOnClick(taskId, saleBotId, segmentNumber);
  })

  function handleCreateEmailCampaignDispatchAjaxOnClick(
    subject,
    template,
    segmentNumber,
    campaignName,
    addressBookName,
    sendTime
  ) {
    const jsonData = {
      subject: subject,
      template: template,
      segmentNumber: segmentNumber,
      campaignName: campaignName,
      addressBookName: addressBookName,
      sendTime: sendTime
    };

    $.ajax({
      url: '/adminka/dispatch/ajax-create-email-campaign',
      type: 'POST',
      data: JSON.stringify(jsonData),
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: 'application/json; charset=utf-8',
      success: function (response) {
        if (response.success) {
          showDispatchSuccessToast(response.message);
        } else {
          showDispatchErrorToast(response.error);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        const status = jqXHR.status;
        const errorMessages = {
          0: 'Нет подключения к серверу',
          404: 'Запрашиваемый URL не найден',
          500: 'Внутренняя ошибка сервера'
        };

        const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
        showDispatchErrorToast(message);
      }
    });
  }

  function handleUpdateEmailDispatchCampaignAjaxOnClick(
    dbCampaignId,
    campaignId,
    subject,
    template,
    addressBook,
    name,
    sendTime
  ) {
    const jsonData = {
      dbCampaignId: dbCampaignId,
      campaignId: campaignId,
      subject: subject,
      template: template,
      addressBook: addressBook,
      name: name,
      sendTime: sendTime
    };

    $.ajax({
        url: '/adminka/dispatch/ajax-update-email-campaign',
        type: 'POST',
        data: JSON.stringify(jsonData),
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: 'application/json; charset=utf-8',
      success: function (response) {
        if (response.success) {
          showDispatchSuccessToast(response.message);
        } else {
          showDispatchErrorToast(response.error);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        const status = jqXHR.status;
        const errorMessages = {
          0: 'Нет подключения к серверу',
          404: 'Запрашиваемый URL не найден',
          500: 'Внутренняя ошибка сервера'
        };

        const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
        showDispatchErrorToast(message);
      }
    });
  }

  function handleCreateDispatchTaskMessageAjaxOnClick (
    segment1Message,
    segment1ImageUrl,
    segment2Message,
    segment2ImageUrl,
    segment3Message,
    segment3ImageUrl,
    segment4Message,
    segment4ImageUrl,
    sendAt
  ) {
    const data = {
      segment1Message: segment1Message,
      segment1ImageUrl: segment1ImageUrl,
      segment2Message: segment2Message,
      segment2ImageUrl: segment2ImageUrl,
      segment3Message: segment3Message,
      segment3ImageUrl: segment3ImageUrl,
      segment4Message: segment4Message,
      segment4ImageUrl: segment4ImageUrl,
      sendAt: sendAt
    };

    $.ajax({
      url: '/adminka/dispatch/ajax-create-messenger-dispatch-task',
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: function (respond) {
        if (typeof respond.error === 'undefined') {
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          })
        } else {
          console.log('ОШИБКА: ' + respond.error)
        }
      },
      error: function (jqXHR, status) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR)
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        })
      }
    });
  }

  function handleUpdateMessageAjaxOnClick(
    taskId,
    segment1Message,
    segment1ImageUrl,
    segment2Message,
    segment2ImageUrl,
    segment3Message,
    segment3ImageUrl,
    segment4Message,
    segment4ImageUrl,
    sendAt
  ) {
    const data = {
      taskId: taskId,
      segment1Message: segment1Message,
      segment1ImageUrl: segment1ImageUrl,
      segment2Message: segment2Message,
      segment2ImageUrl: segment2ImageUrl,
      segment3Message: segment3Message,
      segment3ImageUrl: segment3ImageUrl,
      segment4Message: segment4Message,
      segment4ImageUrl: segment4ImageUrl,
      sendAt: sendAt
    };

    $.ajax({
      url: '/adminka/dispatch/ajax-update-messenger-dispatch-task',
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: function (respond) {
        if (typeof respond.error === 'undefined') {
          $.toast({
            heading: 'Success',
            text: 'Данные сохранены',
            showHideTransition: 'slide',
            icon: 'success'
          });
        } else {
          console.log('ОШИБКА: ' + respond.error);
        }
      },
      error: function (jqXHR, status) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        $.toast({
          heading: '',
          text: 'Произошла ошибка',
          showHideTransition: 'fade',
          icon: 'error'
        });
      }
    });
  }

  function handleTestMessengerDispatchTaskAjaxOnClick(taskId, saleBotId, segmentNumber) {
    const data = {
      taskId: taskId,
      saleBotId: saleBotId,
      segmentNumber: segmentNumber
    };

    $.ajax({
      url: '/adminka/dispatch/ajax-test-messenger-dispatch-task',
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: function (response) {
        if (response.success) {
          showDispatchSuccessToast('Данные сохранены');
        } else {
          showDispatchErrorToast(response.error);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        const status = jqXHR.status;
        const errorMessages = {
          0: 'Нет подключения к серверу',
          404: 'Запрашиваемый URL не найден',
          500: 'Внутренняя ошибка сервера'
        };

        const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
        showDispatchErrorToast(message);
      }
    });
  }

  function showDispatchSuccessToast(message) {
    $.toast({
      heading: 'Успешно',
      text: message,
      showHideTransition: 'slide',
      icon: 'success'
    });
  }

  function showDispatchErrorToast(message) {
    $.toast({
      heading: 'Ошибка',
      text: message,
      showHideTransition: 'fade',
      icon: 'error'
    });
  }

});