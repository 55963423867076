if ($('#modelHome').length) {
  $('#homeMenuItem').addClass('current-page');

  $('#mainPageSlider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    mobileFirst: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false
      }
    }]
  });

  $('#mainPageSliderNews').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
  });

  $('#mainPageSliderNewsMobile').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
  });

$('.ajax_create_new_task').click(function () {
  let promoId = $(this).data('promoid');
  $(".preloader").fadeIn();
  var data = new FormData();
  data.append( 'promoId', promoId );
    $.ajax({
      url         : '/user/ajaxcreatenewpromotask',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          $('.preloader').fadeOut('slow', function() {});
          $.toast({
            heading: 'Success',
            text: 'Заявка отправлена, менеджер свяжется с вами',
            showHideTransition: 'slide',
            icon: 'success'
          });
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
        console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
        $('.preloader').fadeOut('slow', function() {});
        $.toast({
            heading: 'Error',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
      }
    });
});

  $('.main-page__top-line-name').each(function(){
    const name = $(this).text();
    const blockFirstLetter = $(this).parent().find('.first-letter-name');
    const firstLetterOfName = name.charAt(0);
    blockFirstLetter.text(firstLetterOfName);
  });

  $('.slick-news-div').click(function(){
    const htmlModal = $(this).find('.slick-news-modal').html();
    $('.main-page__news-modal').empty();
    $('.main-page__news-modal').append('<div class="cross close-modal"></div>');
    $('.main-page__news-modal').append(htmlModal);
    $('.main-page__news-modal').show();
  });

  $('.slick-slide .btn').click(function(){
    $(this).next().show();
  });

  $('.close-bonus-modal').click(function(){
    $('.main-page__bonus-modal').hide();
  });

  $("body").on("click", ".close-modal", function(){
    $(this).parent().hide();
  });

  $('.close-message').click(function(){
    $(this).parent().hide();
  });

  function changeViewDate(bockPayInfoDate){

    const payInfoDate = $(bockPayInfoDate).text();
    const payInfoDateArr = payInfoDate.split('-');
    let payInfoDateDay = '';
    switch (payInfoDateArr[1]) {
      case '01' : payInfoDateDay = 'января'
               break;
      case '02' : payInfoDateDay = 'февраля'
               break;

      case '03' : payInfoDateDay = 'марта'
               break;

      case '04' : payInfoDateDay = 'апреля'
               break;

      case '05' : payInfoDateDay = 'мая'
               break;

      case '06' : payInfoDateDay = 'июня'
               break;

      case '07' : payInfoDateDay = 'июля'
               break;

      case '08' : payInfoDateDay = 'августа'
               break;

      case '09' : payInfoDateDay = 'сентября'
               break;

      case '10' : payInfoDateDay = 'октября'
                break;

      case '11' : payInfoDateDay = 'ноября'
                break;

      case '12' : payInfoDateDay = 'декабря'
                break;

    }
    const newDate = payInfoDateArr[0] + ' ' + payInfoDateDay;

    $(bockPayInfoDate).text(newDate)
  }

  changeViewDate($('.main-page__finance-pay-date-first'));
  changeViewDate($('.main-page__finance-pay-date-second'));
  changeViewDate($('.main-page__finance-pay-date-third'));

  const widthProgressbar = $('.get_percent_for_progress_bar_here').text() + '%';
  //console.log(widthProgressbar);
  //$('.main-page__scale-pink').css('width', widthProgressbar);


  $('.ajax_send_work_days').click(function() {
    let monday = false;
    let tuesday = false;
    let wednesday = false;
    let thursday = false;
    let friday = false;
    let saturday = false;
    let sunday = false;
    let notWork = false;

    if ($('#workDays-monday').is(':checked')) {
      monday = true;
    }else {
      monday = false;
    }

    if ($('#workDays-tuesday').is(':checked')) {
      tuesday = true;
    }else {
      tuesday = false;
    }

    if ($('#workDays-wednesday').is(':checked')) {
      wednesday = true;
    }else {
      wednesday = false;
    }

    if ($('#workDays-thursday').is(':checked')) {
      thursday = true;
    }else {
      thursday = false;
    }

    if ($('#workDays-friday').is(':checked')) {
      friday = true;
    }else {
      friday = false;
    }

    if ($('#workDays-saturday').is(':checked')) {
      saturday = true;
    }else {
      saturday = false;
    }

    if ($('#workDays-sunday').is(':checked')) {
      sunday = true;
    }else {
      sunday = false;
    }

    if (monday == false && tuesday == false && wednesday == false && thursday == false && friday == false && saturday == false && sunday == false ) {
      $('#workDays-notwork').prop('checked', true);
      $('#workDays-notwork-styler').addClass('checked');
      $('#show_me_if_dont_work').show();
    }

    if ($('#workDays-notwork').is(':checked')) {
      notWork = true;
    }else {
      notWork = false;
    }

    let reasonNotWork = $('#reason_not_work').val();
    if (notWork ==true && reasonNotWork =='') {
      $.toast({
            heading: '',
            text: 'Укажите причину',
            showHideTransition: 'fade',
            icon: 'error'
        });
      $('.error_message_test').show();
      $('#reason_not_work').css({
        border: '2px solid #db046b',
      });
      return false;
    }

    //console.log(monday, tuesday, wednesday, thursday, friday, saturday, sunday, notWork, reasonNotWork);
    var data = new FormData();
    data.append( 'monday', monday );
    data.append( 'tuesday', tuesday );
    data.append( 'wednesday', wednesday );
    data.append( 'thursday', thursday );
    data.append( 'friday', friday );
    data.append( 'saturday', saturday );
    data.append( 'sunday', sunday );
    data.append( 'notWork', notWork );
    data.append( 'reasonNotWork', reasonNotWork );
      $.ajax({
        url         : '/user/ajaxsavemodelworkdays',
        type        : 'POST', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
            $('.preloader').fadeOut('slow', function() {});
            $('.show-parse-window').hide();
            $.toast({
              heading: 'Success',
              text: 'Данные отправлены',
              showHideTransition: 'slide',
              icon: 'success'
          });
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log(jqXHR);
          $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
        }
    });

  });

  $('#workDays-notwork').change(function () {
    if ($(this).is(':checked')) {
      $('#show_me_if_dont_work').show();
    }else {
      $('#show_me_if_dont_work').hide();
    }
  });

  $('#ajax_send_work_days_close_true').click(function() {
    let close = 1;
    var data = new FormData();
    data.append( 'close', close );
    $.ajax({
        url         : '/user/ajaxsavemodelworkdaysclosetrue',
        type        : 'POST', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
            $('.preloader').fadeOut('slow', function() {});
            $('.show-parse-window').hide();
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log(jqXHR);
          $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
        });
        }
    });
  });

  $(document).mouseup(function (e) {
    var container = $(".modal-container");
    if (container.has(e.target).length === 0){
        container.hide();
        $('.overlay-popup').css('display', 'none');
    }
  });

  $('.available-bonus').on('click', function() {
    $('.bonus-modal').show();
  });

  $('.close-modal').on('click', function() {
    $('.modal-box').hide();
  });
}

$('.manager-main__constuctor-checkbox').click(() => {
  if ($('#workDays-notwork').is(':checked')) {
    $('#workDays-monday-styler').removeClass("checked");
    $('#workDays-tuesday-styler').removeClass("checked");
    $('#workDays-wednesday-styler').removeClass("checked");
    $('#workDays-thursday-styler').removeClass("checked");
    $('#workDays-friday-styler').removeClass("checked");
    $('#workDays-saturday-styler').removeClass("checked");
    $('#workDays-sunday-styler').removeClass("checked");
  }
})


$('.show_window_button').click(function() {
  let id = $(this).attr('name');
  console.log(id);
  $('#show_window_id_'+id).show();
});

$('.btn.close_modal_test').click(function() {
	$('.modal-test-message').hide();
});

$(document).ready(function () {
  // Сохраняем значение бонуса при загрузке страницы
  const bonusText = $('.available-bonus').clone();

  $(".urgent-payment-home").on("change", function () {
    const earnings = $('.model_earnings');
    const checkboxId = window.innerWidth < 768 ? '#urgent-payment-mobile' : '#urgent-payment-desktop';
    const baseValue = parseFloat(earnings.data('value'));

    let balance;

    if ($(checkboxId).is(':checked')) {
      balance = (baseValue + parseFloat($('#currentEarnings').data('value'))).toFixed(2);
    } else {
      balance = baseValue.toFixed(2);
    }

    // Если баланс равен нулю, показываем просто "0$"
    if (balance == 0) {
      earnings.text('0$');
    } else {
      earnings.text(balance + '$');
    }

    // Восстанавливаем бонус после обновления текста баланса
    if (bonusText.length > 0) {
      earnings.append(bonusText);
    }
  });
});
