if ($("#superuser-singlemodel").length){
  $("#constuctor-pay").change(function(){
    if ($("#constuctor-pay").prop("checked")){
      $(".superuser-singlemodel__single-pay-show").show();
    }else{
      $(".superuser-singlemodel__single-pay-show").hide();
    }
  });
  $("#constuctor-kpi").change(function(){
    if ($("#constuctor-kpi").prop("checked")){
      $(".superuser-singlemodel__single-kpi-show").show();
    }else{
      $(".superuser-singlemodel__single-kpi-show").hide();
    }
  });
  $("#constuctor-online").change(function(){
    if ($("#constuctor-online").prop("checked")){
      $(".superuser-singlemodel__single-online-show").show();
    }else{
      $(".superuser-singlemodel__single-online-show").hide();
    }
  });
  $(".constuctor-btn").click(function(){
    $(".manager-model__single-constructor-block").show();
  });
  $(".close-constructor-block").click(function(){
    $(".manager-model__single-constructor-block").hide();
  });


  function showBeganIntervalDate() {
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const beganOfPeriod = $("#period-select-date-styler .sel").text();
    const beganIntervalDay = beganOfPeriod.split('-');
    const beganIntervalMonth = beganOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth +  "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    } else {
      $(".began-period").text("c " + beganIntervalDay[0] + ' ' + beganIntervalMonth + ' ' + beganIntervalYear + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
      $(".began-period").css({'display': 'block', 'margin-bottom': '15px'});
    }
  }

  function showEndIntervalDate(){
    const beganIntervalYear = $('#select-period-year-styler .jq-selectbox__select-text').text();
    const endIntervalYear = $('#select-period-year-second-styler .jq-selectbox__select-text').text();

    const endOfPeriod = $("#period-select-date-second-styler .sel").text();
    const endIntervalDay = endOfPeriod.split('-');
    const endIntervalMonth = endOfPeriod.slice(5);

    if (beganIntervalYear === endIntervalYear) {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth);
    } else {
      $(".end-period").text("по " + endIntervalDay[1].slice(0, 2) + ' ' + endIntervalMonth + ' ' + endIntervalYear);
    }
  }

  $("#period-select-date").change(function(){
    if ($("#period-inteval").prop("checked")){
      showBeganIntervalDate();
    } else {
      const beganOfPeriod = $("#period-select-date-styler .sel").text();
      $(".began-period").text(beganOfPeriod + "\u00A0");
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text(beganOfPeriod );
    }
  });

  $("#period-select-date-second").change(function(){
    showEndIntervalDate();
  });

  $('.superuser-common__period-select-year').change(function(){
    showBeganIntervalDate();
    showEndIntervalDate();
  });


  $("#period-inteval").change(function(){
    if ($("#period-inteval").prop("checked")){
      $(".manager-main__period-select-second").css("display", "flex");
      $(".manager-main__period-select").css("height", "140px");
      $(".end-period").css("display", "inline-block");
      showBeganIntervalDate();
      showEndIntervalDate();
    }else {
      $(".manager-main__period-select-second").css("display", "none");
      $(".manager-main__period-select").css("height", "81px");
      $(".end-period").css("display", "none");
    }
  });

  //plan show
  $(".manager-main__plan-btn").click(function(){
    $(".show-modal .modal-box").toggle();
  });

  $('#superuserModel').addClass('current-page');

  $("#tabs").tabs();
  $("#tabs").tabs({
    active: 0
  });
  $(".approve").click(function(){
    const thisApprove = $(this);
    const thisNoApprove = thisApprove.parent().find(".no-approve");
    thisApprove.addClass("disabled");
    thisNoApprove.removeClass("disabled");
    thisApprove.removeClass("active");
    thisNoApprove.addClass("active");
  });
  $(".no-approve").click(function(){
    const thisNoApprove = $(this);
    const thisApprove = thisNoApprove.parent().find(".approve");
    thisNoApprove.addClass("disabled");
    thisApprove.removeClass("disabled");
    thisNoApprove.removeClass("active");
    thisApprove.addClass("active");
  });
  $(".superuser-singlemodel__single-chat-icon").click(function(){
    $(".chat-messenger-block").toggle();
    $(".chat-messenger-svg").toggle();
    $(".chat-close-svg").toggle();
  });
  $(".work-with-sites__lk-status-btn").click(function(){
    const thisLink = $(this);
    const thisStatus = $(this).parent().parent().find(".work-with-sites__lk-status");
    if (thisLink.text() === "Активировать"){
      thisLink.text("Деактивировать");
      thisStatus.text("Активен");
    }else{
      thisLink.text("Активировать");
      thisStatus.text("Неактивен");
    }
  });
  (function(){
    const statusBtn = $(".work-with-sites__lk-status").parent().find("work-with-sites__lk-status-btn");
    if ($(".work-with-sites__lk-status").text() === "Неактивен"){
      statusBtn.text("Активировать");
    }else statusBtn.text("Дективировать");
  })();
  function getSrc(btn){
    const photoImg = btn.parent().find("img");
    const src = photoImg.prop('src');
    btn.prop("href", src);
  };
  $(".superuser-singlemodel__single-download-photo").click(function(){
    const downloadBtn = $(this);
    getSrc(downloadBtn);
  });
  $(".make-big-photo").click(function(){
    const bigPhotoBtn = $(this);
    getSrc(bigPhotoBtn);
  });
  $(document).ready(function() {
    $(".make-big-photo").fancybox({
      padding : 0,
        helpers: {
          overlay: {
            locked: false
          }
        }
    });
  });
  const constructorInput = $('.manager-model__single-constructor-block input');
  constructorInput.change(function(){
    let counter = 0;
    constructorInput.each(function(){
      if ($(this).prop('checked')){
        counter++;
      }
      if (counter < 2) {
        $('.manager-model__single-constructor-block .jq-checkbox.checked').addClass('disabled');
      } else $('.manager-model__single-constructor-block .jq-checkbox').removeClass('disabled');
    })
  });
}
