if ($("#modelTest").length) {

  function testStart(){
    $(".question_number_0").show();
    $(".question_number_0").addClass("current_question");

    var arrayAnswers = [];
    var arrayQuestionIds = [];

    var i = 16;
//    $(".time_info").text(15);
    var timerId = setInterval(function() {
      $(".set_answer").click(function() {
        i = 16;
	$(".time_info").text(15);
	progress(15);
      });
    //  --i;
//             $(".time_info").text(i);
//	      progress(i);
      if(i == 0) {
	i = 16;
        console.log("Time close");
        var num = $(".current_question").attr("name");
        nextQuestion = Number(num) + 1;
        arrayAnswers[num] = "error";
        arrayQuestionIds[num] = "error";
        $(".question_number_" + num).hide();
        $(".question_number_" + num).removeClass("current_question");
        $(".question_number_" + nextQuestion).show();
        $(".question_number_" + nextQuestion).addClass("current_question");

	if (arrayAnswers.length == 10) {
        	console.log(arrayAnswers);
	        testResult();
        }
        //i = 16;
//	$(".time_info").text(15);
//	progress(15);
      }
       --i;
       $(".time_info").text(i);
        progress(i);

    }, 1500);

    // через 5 сек остановить повторы
    setTimeout(function() {
      clearInterval(timerId);
    }, 242000);

    $(".set_answer").click(function() {
      var num = $(this).attr("name");
      var nextQuestion = Number(num) + 1;

      var questionId = $(this).parent(".question_id").attr("name");
      var answer = $("input[name=answer_" + num + "]:checked").val();
      $(".question_number_" + num).hide();
      $(".question_number_" + num).removeClass("current_question");
      $(".question_number_" + nextQuestion).show();
      $(".question_number_" + nextQuestion).addClass("current_question");
      arrayAnswers[num] = answer;
      arrayQuestionIds[num] = questionId;
      if (arrayAnswers.length == 10) {
        console.log(arrayAnswers);
	testResult();
      }
    });


 function testResult(){
        $('#modelTest').hide();
        var answers = JSON.stringify(arrayAnswers);
        var ids = JSON.stringify(arrayQuestionIds);
        var data = new FormData();
        data.append("answers", answers);
        data.append("ids", ids);
        var type = $('.ajax_start_model_test').attr("name");
        data.append("type", type);
        $.ajax({
          url: "/user/ajaxchecktestresults",
          type: "POST",
          data: data,
          cache: false,
          dataType: "json",
          // отключаем обработку передаваемых данных, пусть передаются как есть
          processData: false,
          // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
          contentType: false,
          // функция успешного ответа сервера
          success: function(respond, status, jqXHR) {
            if (typeof respond.error === "undefined") {
              console.log(respond);
              $('.test-timer').hide();
              $(".modal-label").text("Тест успешно пройден");
              $(".show_me_after_test").show();
              $('.ajax_start_model_test').hide();
              $('.model-study__test-begin').show();
              $('.model-study__test-text').text('Вы успешно закончили обучение');

            }
            // ошибка
            else {
              console.log("ОШИБКА: " + respond.error);
            }
          },
          // функция ошибки ответа сервера
          error: function(jqXHR, status, errorThrown) {
            console.log(jqXHR);
            $('.test-timer').hide();
            $(".modal-label").text("Тест пройден неверно");
            $(".show_me_after_test").show();
            $('.ajax_start_model_test').hide();
            $('.model-study__test-begin').show();
            $('.model-study__test-text').text('Попробуйте пройти тест завтра');

          }
        });
 //     }
}

  }

  function pageReload() {
    window.location.reload();
  }


/*   $(".go_redirect_study").click(function() {
    window.location.replace("/user/study");
  }); */

  $('#lessonTest .btn-primary').click(function(){
    testStart();
    $('.model-study__test-begin').hide();
    $('#modelTest').show();
    $('#modelTest').parent().css({'align-items' : 'flex-start', 'padding-top' : '103px'});
  })
  //progressbar

  function progress(i) {
	if (i == 15){
		$('#progressBar').animate({ width: "0%" }, 0);
	}else {
	    $('#progressBar').animate({
	      width: (15-i)*6.67 + "%",
	    }, 1000);
	}
  }

  $('.test-option__block input').change(function(){
    $('.test-option__block input').parent().parent().removeClass('active-answer');
    $(this).parent().parent().addClass('active-answer');
  })

  $('.test-option__block').click(function(){
    $('.jq-radio').removeClass('checked');
    $('.jq-radio input', this).prop('checked', true);
    $('.jq-radio', this).addClass('checked');
    $('.test-option__block').removeClass('active-answer');
    $(this).addClass('active-answer');
  })


  $('.ajax_start_model_test').click(function () {
    var data = new FormData();
    data.append( 'type', $(this).attr("name") );
    $.ajax({
       url: "/user/modelstartnewtest",
       type: "POST",
       data: data,
       cache: false,
       dataType: "json",
       // отключаем обработку передаваемых данных, пусть передаются как есть
       processData: false,
       // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
       contentType: false,
       // функция успешного ответа сервера
       success: function(respond, status, jqXHR) {
         if (typeof respond.error === "undefined") {
           console.log(respond);
         }
         // ошибка
         else {
           console.log("ОШИБКА: " + respond.error);
         }
       },
       // функция ошибки ответа сервера
       error: function(jqXHR, status, errorThrown) {
         console.log(jqXHR);
       }
     });
  });

}
