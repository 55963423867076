if ($("#modelfinance").length){
  //check finance radio
  $("#style-data__schedule").change(function() {
    if ($("#style-data__schedule").prop("checked")){
      $(".finance__chart").css("display", "block");
      $(".finance__table").css("display", "none");
      $(".finance__level").css("display", "none");
    }
  });
  $("#style-data__table").change(function() {
    if ($("#style-data__table").prop("checked")){
      $(".finance__chart").css("display", "none");
      $(".finance__table").css("display", "block");
      $(".finance__level").css("display", "none");
    }
  });

  $("#style-data__level").change(function() {
     if ($("#style-data__level").prop("checked")){
      $(".finance__chart").css("display", "none");
      $(".finance__table").css("display", "none");
      $(".finance__level").css("display", "block");
    }
  });

  $('#financeMenuItem').addClass('current-page');

  $(".prepayment-apply").click(function(){
    $(".preloader").fadeIn();
    let sum = $('input[name=prepayment-summ]').val();
    if (!sum) {
      $.toast({
        heading: '',
        text: 'Укажите сумму выплаты',
        showHideTransition: 'fade',
        icon: 'error'
      });
      $('.preloader').fadeOut('slow', function() {});

      return false;
    } else if (sum < 15) {
      $.toast({
        heading: '',
        text: 'Сумма досрочной выплаты не может быть меньше 40$',
        showHideTransition: 'fade',
        icon: 'error'
      });
      $('.preloader').fadeOut('slow', function() {});

      return false;
    }
    let data = new FormData();
    data.append( 'sum', sum );
    data.append( 'from', 'modelfinance' );
    data.append('urgentPaymentCheckboxStatus', $("#urgent-payment").is(':checked'));
    $.ajax({
        url         : '/user/ajaxorderearlypayment',
        type        : 'POST', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){

          if( typeof respond.error === 'undefined' ){
            $('.preloader').fadeOut('slow', function() {});
            $.toast({
            	heading: 'Success',
            	text: 'Досрочная выплата успешно заказана',
            	showHideTransition: 'slide',
            	icon: 'success'
        	});

          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
            $('.preloader').fadeOut('slow', function() {});
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log(jqXHR);
          $('.preloader').fadeOut('slow', function() {});
          $.toast({
            heading: '',
            text: 'Сумма досрочной выплаты превышает сумму двух ближайших плановых выплат',
            showHideTransition: 'fade',
            icon: 'error'
        });
        }
    });

  });

  $("#urgent-payment").on("change", function () {
    $("input[name=prepayment-summ]").keyup();

    let earnings = $('.model_earnings');

    if ($("#urgent-payment").is(':checked')) {
      let balance = Number(earnings.data('value') + $('#currentEarnings').data('value')).toFixed(2);
      earnings.text(balance + '$');
    } else {
      earnings.text(earnings.data('value') + '$');
    }
  });

  $("input[name=prepayment-summ]").on('keyup',function() {
	let data = new FormData();
    data.append( 'sum', $(this).val() );
    data.append( 'from', 'modelfinance' );
    data.append('urgentPaymentCheckboxStatus', $("#urgent-payment").is(':checked'));

    $.ajax({
      url         : '/user/ajaxorderearlypaymentcommission',
      type        : 'POST', // важно!
      data        : data,
      cache       : false,
      dataType    : 'json',
      // отключаем обработку передаваемых данных, пусть передаются как есть
      processData : false,
      // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      contentType : false,
      // функция успешного ответа сервера
      success     : function( respond, status, jqXHR ){

        if( typeof respond.error === 'undefined' ){
          $('.preloader').fadeOut('slow', function() {});
//		console.log(respond);
      let com = $('.commission_label');
      if(respond.result){com.html('Комиссия платежной системы составит </br>' + respond.com + '$');}
      else {com.html('');}
        }
        // ошибка
        else {
          console.log('ОШИБКА: ' + respond.error );
      $('.commission_label').html('');
        }
      },
      // функция ошибки ответа сервера
      error: function( jqXHR, status, errorThrown ){
      $('.commission_label').html('');
            console.log(jqXHR);
          }
    });
  });

  $(".finance__pay-history").click(function(){
    $(".history-pay").show();
  });

  $("#show_modal_change_pay").click(function(e){
    e.preventDefault();
    $('#modelfinance').css('height', '1350px')
    $("#modal_window_change_pay").show();
    let walletValue = $('.for_wallet input').attr('value');
    $('#ajax_wallet_number').val(walletValue);
    $(".outline-card.checked").parent().find("img").addClass("active-pay");
  });

  $("#button_more_money").click(() => {
    $("#modal_window_more_money").show();
  });

  let modelLvl = 0;
  let modelCef = 0;
  document.addEventListener('DOMContentLoaded', function() {
    // Ваш код здесь, например:
    $("#ajaxGetFinanceData").click(function(){
      generateTable();
    });
  });

  function generateTable(retryCount = 0) {
    var monthDay = $("#finance-period option:selected").attr('name');
    var year = $("#finance-year option:selected").val();
    var array = monthDay.split('.');
    from = year + '-' + array[0] + '-' + array[1];
    to = year + '-' + array[0] + '-' + array[2];
    var data = new FormData();
    data.append('from', from);
    data.append('to', to);
    $.ajax({
      url: '/user/ajaxgetdatafinance',
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: function (respond, status, jqXHR) {
        console.log(respond);
        if (respond == 'error') {
          $.toast({
            heading: '',
            text: 'За данный период нет данных',
            showHideTransition: 'fade',
            icon: 'error'
          });
          createVoidTable();
          changePayLvlColor(0);
          changelevel(1, 1);
          if ($('.finance__chart-pay').length) { showCefInLegend(); }
        } else if (typeof respond.error === 'undefined') {
          createTh();
          setdata(respond);
          hideEmptyTr();
          calculateSum();
          calculateDaySum(respond);
          calculateHours(respond.summary);
          changelevel(respond.summary.commission, respond.summary.totalStreamSeconds); // предполагаем, что уровень зависит от комиссии и общего времени
          if ($('.finance__chart-pay').length) { showCefInLegend(); }
          $(".style-data__schedule").trigger('click');
        }
        else {
          console.log('ОШИБКА: ' + respond.error);
          createTh();
        }
      },
      error: function (jqXHR, status, errorThrown) {
        console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);

        // Проверяем, является ли ошибка 400 и не превышен ли лимит попыток
        if (jqXHR.status === 400 && retryCount < 2) {
          console.log('Получен ответ 400, пробуем повторный запрос...');
          setTimeout(() => {
            generateTable(retryCount + 1);
          }, 500); // Пауза 1 секунда перед повторной попыткой
          return;
        }

        $.toast({
          heading: '',
          text: 'За данный период нет данных',
          showHideTransition: 'fade',
          icon: 'error'
        });
        createVoidTable();
        changePayLvlColor(0);
        changelevel(1, 1);
        if ($('.finance__chart-pay').length) { showCefInLegend(); }
      }
    });
  }

  function changelevel(level, time){
	let info='<div class="help-block-example"><img class="help-block-example__img" src="../web/public/img/info.svg"><div class="popup-help">Ваш уровень выплат за выбранный период</div></div>';
	$(".level-info .help-block-example").remove();
	if (level == 1 && time == 1) $(".level1_1 .level-info").append(info);
	if (level == 1 && time == 2) $(".level1_2 .level-info").append(info);
	if (level == 1 && time == 3) $(".level1_3 .level-info").append(info);
	if (level == 1 && time == 4) $(".level1_4 .level-info").append(info);
	if (level == 1 && time == 5) $(".level1_5 .level-info").append(info);
	if (level == 2 && time == 1) $(".level2_1 .level-info").append(info);
	if (level == 2 && time == 2) $(".level2_2 .level-info").append(info);
	if (level == 2 && time == 3) $(".level2_3 .level-info").append(info);
	if (level == 2 && time == 4) $(".level2_4 .level-info").append(info);
	if (level == 2 && time == 5) $(".level2_5 .level-info").append(info);
	if (level == 3 && time == 1) $(".level3_1 .level-info").append(info);
	if (level == 3 && time == 2) $(".level3_2 .level-info").append(info);
	if (level == 3 && time == 3) $(".level3_3 .level-info").append(info);
	if (level == 3 && time == 4) $(".level3_4 .level-info").append(info);
	if (level == 3 && time == 5) $(".level3_5 .level-info").append(info);
  }

  function createTh() {
    const month = $("#finance-period-styler .jq-selectbox__select-text").text();
    const dayArr = month.split('-');
    const tableThead = $(".finance__table-thead");
    const tableTheadTh = $(".finance__table-thead th");
    tableTheadTh.remove();
    tableThead.append("<th>Дата</th>");
    for (let i = parseInt(dayArr[0]); i <= parseInt(dayArr[1]); i++) {
      tableThead.append(`<th>${i}</th>`);
    }
    tableThead.append("<th>Итого</th>");
  }

  function createVoidTable() {
    createTh();
    const month = $("#finance-period-styler .jq-selectbox__select-text").text();
    const dayArr = month.split('-');

    $('.finance__table-table tbody tr').each(function () {
      $('td', this).each(function (elem) {
        if (elem !== 0) {
          $(this).remove();
        }
      })
    })
    for (var l = 1; l <= 14; l++) {
      for (let f = parseInt(dayArr[0]); f <= parseInt(dayArr[1]) + 1; f++) {
        $('#site_id_' + l).append("<td>-</td>");
      }
    }

    $('.finance__table-table tfoot tr').each(function () {
      $('td', this).each(function (elem) {
        if (elem !== 0) {
          $(this).remove();
        }
      })
    })
    for (let f = parseInt(dayArr[0]); f <= parseInt(dayArr[1]) + 1; f++) {
      $('#day_hours').append("<td>-</td>");
    }
    for (let f = parseInt(dayArr[0]); f <= parseInt(dayArr[1]) + 1; f++) {
      $('#day_result').append("<td>-</td>");
    }

    changePayLvlColor();
    $(".style-data__schedule").trigger('click');
  }

  function setdata(respond) {
    const month = $("#finance-period-styler .jq-selectbox__select-text").text();
    const dayArr = month.split('-');
    const daysInPeriod = parseInt(dayArr[1]) - parseInt(dayArr[0]) + 1;
    const startDay = parseInt(dayArr[0]); // Добавлено: день начала периода
    $("#myTable tbody tr td").remove();

    // Создаем объект для отслеживания наличия данных по каждой площадке
    let siteDataExists = {};
    for (let l = 1; l <= 14; l++) {
      siteDataExists[l] = false;
    }

    // Создаем пустые ячейки для каждого дня в периоде
    for (let l = 1; l <= 14; l++) {
      for (let f = 0; f <= daysInPeriod; f++) {
        $('#site_id_' + l).append("<td>-</td>");
      }
      $('#site_id_' + l).append("<td>-</td>"); // Добавляем пустую ячейку для итоговой суммы
    }

    // Заполняем таблицу данными из siteEarnings
    for (let i = 0; i < respond.siteEarnings.length; i++) {
      const siteEarning = respond.siteEarnings[i];
      const date = siteEarning.date;
      const day = parseInt(date.split('-')[2]);

      // Исправленный расчет dayIndex
      const dayIndex = day - startDay + 1; // +1, так как первая ячейка - это название сайта

      const siteId = siteEarning.siteId;
      const earnings = siteEarning.earnings;

      $(`#site_id_${siteId} td:nth-child(${dayIndex + 1})`).text((Math.round(earnings * 100) / 100).toFixed(2));
      siteDataExists[siteId] = true; // Отмечаем, что для данной площадки есть данные
    }

    // Проставляем названия сайтов и скрываем строки без данных
    for (let i = 1; i <= 14; i++) {
      $(`#site_id_${i} td:first-child`).text(getSiteNameById(i));
      if (!siteDataExists[i]) {
        $(`#site_id_${i}`).hide(); // Скрываем строку, если для площадки нет данных
      } else {
        $(`#site_id_${i}`).show(); // Показываем строку, если для площадки есть данные
      }
    }
  }

  function getSiteNameById(siteId) {
    switch (siteId) {
      case 1: return 'JAS';
      case 2: return 'CHA';
      case 3: return 'MYF';
      case 4: return 'BON';
      case 5: return 'IML';
      case 6: return 'STR';
      case 7: return 'CAM';
      case 8: return 'MYF2';
      case 9: return 'SOD';
      case 10: return 'CHR';
      case 11: return 'STM';
      case 12: return 'XLC';
      case 13: return 'F4F';
      case 14: return 'CON';
      default: return '';
    }
  }

  function calculateSum() {
    for (var j = 1; j <= 14; j++) {
      var sum = 0;
      $('#site_id_' + j + ' td').each(function (elem) {
        if (elem !== 0 && elem !== $('#site_id_' + j + ' td').length) {
          let numberInTd = parseFloat($(this).text());
          if (!isNaN(numberInTd)) {
            sum += numberInTd;
          }
        }
      });
      $('#site_id_' + j + ' td:last-child').text((Math.round(sum * 100) / 100).toFixed(2));
    }
  }

  function calculateDaySum(respond) {
    const month = $("#finance-period-styler .jq-selectbox__select-text").text();
    const dayArr = month.split('-');
    const dayResultTr = $("#day_result");
    const daysInPeriod = parseInt(dayArr[1]) - parseInt(dayArr[0]) + 1;
    let totalEarningsForPeriod = 0; // Добавляем переменную для подсчета общего заработка за период

    // Очистка строки результатов
    dayResultTr.empty().append('<td>Заработок за день</td>');

    // Создаем объект для агрегации заработка по дням
    let dailyEarnings = {};
    for (let day = parseInt(dayArr[0]); day <= parseInt(dayArr[1]); day++) {
      dailyEarnings[day] = 0;
    }

    // Суммируем заработок по дням из siteEarnings
    respond.siteEarnings.forEach(siteEarning => {
      const day = parseInt(siteEarning.date.split('-')[2]);
      dailyEarnings[day] += siteEarning.earnings;
    });

    // Заполнение данных по дням
    for (let day = parseInt(dayArr[0]); day <= parseInt(dayArr[1]); day++) {
      const earningsForDay = dailyEarnings[day];
      const formattedValue = earningsForDay === 0 ? "-" : (Math.round(earningsForDay * 100) / 100).toFixed(2);
      dayResultTr.append(`<td>${formattedValue}</td>`);
      totalEarningsForPeriod += earningsForDay; // Добавляем заработок за день к общему заработку
    }

    // Добавление итоговой ячейки
    const totalValue = (Math.round(totalEarningsForPeriod * 100) / 100).toFixed(2);
    dayResultTr.append(`<td>${totalValue}</td>`);

    if (modelLvl === '1') {
      modelLvl = $('#table_pay_level_to span:last-child').attr('class');
    }

    return modelLvl;
  }
  function calculateHours(summaryData) {
    const month = $("#finance-period-styler .jq-selectbox__select-text").text();
    const dayArr = month.split('-');
    const dayHoursTr = $("#day_hours");
    const intervalName = $('#finance-period option:selected').attr('name');
    const intervalArr = intervalName.split('.');
    const intervalYear = $('#finance-year option:selected').text();
    const days = intervalArr[1] + '-' + intervalArr[0] + '-' + intervalYear;
    const limit = intervalArr[2] - intervalArr[1];

    $("#day_hours td").remove();
    dayHoursTr.append('<td>Время онлайн*</td>');

    let sum_day_all = 0;
    for (let f = 0; f <= limit; f++) {
      let date = moment(days, 'DD-MM-YYYY').add(f, 'days');
      let dateYYYYMMDD = moment(date).format('YYYY-MM-DD'); // Формат даты как в streamTimeByDays
      let date2 = moment(date).format('DD_MM');
      let time = summaryData.streamTimeByDays[dateYYYYMMDD] || 0; // Используем формат YYYY-MM-DD для ключа
      let t = String(Math.trunc(time / 3600)).padStart(2, "0") + ':' + String(Math.trunc((time % 3600) / 60)).padStart(2, "0"); // Переводим секунды в часы и минуты

      if (summaryData.streamTimeByDays[dateYYYYMMDD]) {
        sum_day_all += time;
        dayHoursTr.append("<td class='" + date2 + "'>" + t + "</td>");
      } else {
        dayHoursTr.append("<td class='" + date2 + "'>-</td>");
      }
    }

    let t_all = 0;
    if (sum_day_all != 0) {
      t_all = String(Math.trunc(sum_day_all / 3600)).padStart(2, "0") + ':' + String(Math.trunc((sum_day_all % 3600) / 60)).padStart(2, "0"); // Переводим секунды в часы и минуты
    }
    dayHoursTr.append("<td class='hours_sum_all'>" + t_all + "</td>");
  }

  function hideEmptyTr() {
    for (var j = 1; j <= 14; j++) {
      var rowIsEmpty = true; // Предполагаем, что строка пустая
      $('#site_id_' + j + ' td').each(function(elem) {
        if (elem !== 0 && $(this).text() !== '-') { // Проверяем, что это не первый столбец (название сайта) и что значение не равно "-"
          rowIsEmpty = false; // Если найдено значение, строка не пустая
          return false; // Прерываем цикл each
        }
      });

      if (rowIsEmpty) {
        $('#site_id_' + j).hide();
      } else {
        $('#site_id_' + j).show();
      }
    }
  }

  function multipleForCef() {
    modelCef = $('#table_pay_level_model_cef .' + modelLvl).text();
    for (var j = 1; j <= 14; j++) {
      $('#site_id_'+j+ ' td').each(function(elem){
        if ( elem !== 0){
          let numberInTd = $(this).text();
          const numberAfterRound = Math.round((numberInTd * modelCef) * 100) / 100;
          $(this).text(numberAfterRound);
        }
      });
    }
    $('#day_result td').each(function(elem){
      if ( elem !== 0){
        let numberInTd = $(this).text();
        const numberAfterRound = Math.round((numberInTd * modelCef) * 100) / 100;
        $(this).text(numberAfterRound);
      }
    });
    $('#day_result td:last-child').append('$');
  }

  function changePayLvlColor(level) {
    const payTitleElem = $('.finance__pay-title');
    payTitleElem.removeClass('violet');
    payTitleElem.removeClass('yellow');
    payTitleElem.removeClass('pink-color');
    payTitleElem.removeClass('green');
    payTitleElem.removeClass('orange');
    console.log(level);
    switch (level) {
      case 0: payTitleElem.addClass('violet');
                          break;
      case '1': payTitleElem.addClass('violet');
                          break;
      case '2': payTitleElem.addClass('yellow');
                          break;
      case '3': payTitleElem.addClass('pink-color');
                          break;
      case '4': payTitleElem.addClass('green');
                          break;
      case '5': payTitleElem.addClass('orange');
                          break;
    }
    //console.log(modelLvl)
  }
  // генерирование таблицы при загрузке
  $(document).ready(setTimeout(function(){
    generateTable();
  }, 1500));
  $(document).ready(setTimeout(function(){
    $(".style-data__schedule").trigger('click');
  }, 1050))

  function showCefInLegend() {
    const legendli = $('.0-legend li');
    $('.0-legend li div').remove();
    for (let i = 0; i <= legendli.length; i++){
      $('#table_pay_level_model_cef span').each(function(elem){
        if (i === elem){
          const li = legendli[i];
          const classLi = $(this).attr('class');
          const div = document.createElement('div');
          div.innerHTML = $(this).text() * 100 + ' %';
          div.className = classLi;
          li.appendChild(div);
        }
      });
    }
  }

  $("#ajax_send_new_wallet_number").click(function(e){
    const currency = $('input[name="FirsttabForm[currency]"]:checked').val();
    const paymentSystem = $('input[name="FirsttabForm[payment_system]"]:checked').val();
    const firstname = $('input[name="card_first_name"]').val();
    const lastname = $('input[name="card_last_name"]').val();
    const walletNumber = $('input[name="payment_system_number"]').val();
    const paymentPhone = $('input[name="payment_phone"]').val();

    const phoneValidationResult = formatAndValidatePhoneNumber(paymentPhone);
    if (!phoneValidationResult.isValid) {
      showModelFinanceErrorToast(phoneValidationResult.message);
      return;
    }

    const data = {
      walletNumber: walletNumber,
      currency: currency,
      paymentSystem: paymentSystem,
      firstname: firstname,
      lastname: lastname,
      paymentPhone: phoneValidationResult.formatted
    };

    $.ajax({
      url: '/user/ajaxchangemodelpay',
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      cache: false,
      success: function (response) {
        if (response.success) {
          showModelFinanceSuccessToast(response.message);
          $("#modal_window_change_pay").hide();
          location.reload();
        } else {
          showModelFinanceErrorToast(response.message);
        }
      },
      error: function (jqXHR, textStatus) {
      const status = jqXHR.status;
      const errorMessages = {
        0: 'Нет подключения к серверу',
        404: 'Запрашиваемый URL не найден',
        500: 'Внутренняя ошибка сервера'
      };

      const message = errorMessages[status] || 'Произошла ошибка: ' + textStatus;
      showModelFinanceErrorToast(message);
      }
    });
  });

  function showModelFinanceSuccessToast(message) {
    $.toast({
      heading: 'Успех',
      text: message,
      showHideTransition: 'slide',
      icon: 'success'
    });
  }

  function showModelFinanceErrorToast(message) {
    $.toast({
      heading: 'Ошибка',
      text: message,
      showHideTransition: 'fade',
      icon: 'error'
    });
  }

  function formatAndValidatePhoneNumber(phoneNumber) {
    if (phoneNumber === undefined) {
      return {
        isValid: true,
        formatted: String(phoneNumber)
      };
    }

    const cleaned = phoneNumber.replace(/\D/g, '');

    if (cleaned.length !== 11) {
      return {
        isValid: false,
        message: 'Неверная длина номера телефона. Номер должен содержать 11 цифр.'
      };
    }

    if (cleaned.charAt(0) !== '7') {
      return {
        isValid: false,
        message: 'Номер телефона должен начинаться с "7".'
      };
    }

    return {
      isValid: true,
      formatted: cleaned
    };
  }

  const paymentInput = $("#ajax_wallet_number");
  const paymentInputOtherCard = $('.other-card');
  const paymentInputVisa = $('.visa-number');
  $('.visa-number').mask('9999-9999-9999-9999');

  $('#radiocard').on('click', function(){
    $('.for_card').hide();
    $("#secondtabform-payment_system_numberds").prop('required',false);
    $('.for_wallet').show();
    $("#secondtabform-payment_system_number").prop('required',true);
    paymentInput.addClass("visa-number");
    paymentInput.removeClass("other-card");
    paymentInput.prop("placeholder", "");
    paymentInput.val("");
    $('.visa-number').unmask();
    $(".pay_system img").removeClass("active-pay");
    $(this).parent().parent().find("img").addClass("active-pay");
    $('.model_click_card').css('display', 'block');
  });

  $('#radioyandex').on('click', function(){
    $('.for_card').hide();
    $("#secondtabform-payment_system_number_card").prop('required',false);
    $('.for_wallet').show();
    $("#secondtabform-payment_system_number").prop('required',true);
    paymentInput.removeClass("visa-number");
    paymentInput.addClass("other-card");
    $('.other-card').mask('9999999999999');
    paymentInput.prop("placeholder", "4100143322850");
    paymentInput.val("");
    $(".pay_system img").removeClass("active-pay");
    $(this).parent().parent().find("img").addClass("active-pay");
    $('.model_click_card').css('display', 'none');
  });

  $('#radioqiwi').on('click', function(){
    $('.for_card').hide();
    $("#secondtabform-payment_system_number_card").prop('required',false);
    $('.for_wallet').show();
    $("#secondtabform-payment_system_number").prop('required',true);
    paymentInput.removeClass("visa-number");
    paymentInput.addClass("other-card");
    $('.other-card').unmask();
    paymentInput.prop("placeholder", "");
    paymentInput.val("");
    $(".pay_system img").removeClass("active-pay");
    $(this).parent().parent().find("img").addClass("active-pay");
    $('.model_click_card').css('display', 'none');
  });

  $('#radiowebMoney').on('click', function(){
    $('.for_card').hide();
    $("#secondtabform-payment_system_number_card").prop('required',false);
    $('.for_wallet').show();
    $("#secondtabform-payment_system_number").prop('required',true);
    paymentInput.removeClass("visa-number");
    paymentInput.addClass("other-card");
    $('.other-card').mask('a999999999999');
    paymentInput.prop("placeholder", "Z291143207289");
    paymentInput.val("");
    $(".pay_system img").removeClass("active-pay");
    $(this).parent().parent().find("img").addClass("active-pay");
    $('.model_click_card').css('display', 'none');
  });
  if ($("#secondtabform-email").prop('readonly')){
    $(".field-secondtabform-email label").addClass('active_label');
  }
  $(document).ready(setTimeout(function(){
    $(".outline-card.checked").parent().find("img").addClass("active-pay");
    if ($("#radiocard").prop('checked')){
      paymentInput.addClass("visa-number");
      setTimeout(function(){
        $('.visa-number').unmask();
        paymentInput.prop("placeholder", "");
      },100)
      $('.model_click_card').css('display', 'block');
    }
    if ($("#radioyandex").prop('checked')){
      paymentInput.addClass("other-card");
      setTimeout(function(){
        $('.other-card').mask('9999999999999');
        paymentInputOtherCard.prop("placeholder", "4100143322850");
      },100)
    }
    if ($("#radioqiwi").prop('checked')){
      paymentInput.addClass("other-card");
      setTimeout(function(){
        paymentInputOtherCard.unmask();
        paymentInputOtherCard.prop("placeholder", "");
      }, 100)
    }
    if ($("#radiowebMoney").prop('checked')){
      paymentInput.addClass("other-card");
      setTimeout(function(){
        paymentInputOtherCard.mask('A-Z999999999999');
        paymentInputOtherCard.prop("placeholder", "Z291143207289");
      }, 100)
    }
  }, 300));

  if($("#radioyandex").prop('checked') || $("#radioqiwi").prop('checked') || $("#radiowebMoney").prop('checked') || $("#radiocard").prop('checked')){
    $(".for_wallet").show();
  }

  function hideFieldPay() {
    $(".for_card").hide();
    $(".for_wallet").hide();
  }

  $("#radiorub").change(function() {
    showPayRub();
    hideFieldPay();
    if($("#radioyandex").prop('checked') || $("#radioqiwi").prop('checked') || $("#radiocard").prop('checked')){
      $(".for_wallet").show();
    }
  });

  $("#radiousd").change(function() {
    showPayUsd();
    hideFieldPay();
    if($("#radiowebMoney").prop('checked') || $("#radiocard").prop('checked')){
      $(".for_wallet").show();
    }
  });

  showPayUsd();

  showPayRub();

  function showPayUsd() {
    if ($("#radiousd").prop('checked'))
    {
      $(".web_money").show();
      $(".pay_visa").show();
      $(".yandex_money").hide();
      $(".pay_qivi").hide();
    }
  };

  function showPayRub() {
    if ($("#radiorub").prop('checked'))
    {
      $(".web_money").hide();
      $(".pay_visa").show();
      $(".yandex_money").show();
      $(".pay_qivi").show();
    }
  };

  $('.model-finance__close-modal').click(function(){
    $('#modal_window_change_pay').hide();
    location.reload();
  });

  //изменение отображения даты

  function changeViewDate(bockPayInfoDate){

    const payInfoDate = $(bockPayInfoDate).text();
    const payInfoDateArr = payInfoDate.split('-');
    let payInfoDateDay = '';
    switch (payInfoDateArr[1]) {
      case '01' : payInfoDateDay = 'января'
               break;
      case '02' : payInfoDateDay = 'февраля'
               break;

      case '03' : payInfoDateDay = 'марта'
               break;

      case '04' : payInfoDateDay = 'апреля'
               break;

      case '05' : payInfoDateDay = 'мая'
               break;

      case '06' : payInfoDateDay = 'июня'
               break;

      case '07' : payInfoDateDay = 'июля'
               break;

      case '08' : payInfoDateDay = 'августа'
               break;

      case '09' : payInfoDateDay = 'сентября'
               break;

      case '10' : payInfoDateDay = 'октября'
                break;

      case '11' : payInfoDateDay = 'ноября'
                break;

      case '12' : payInfoDateDay = 'декабря'
                break;

    }
    const newDate = payInfoDateArr[0] + ' ' + payInfoDateDay;

    $(bockPayInfoDate).text(newDate)
  }

  changeViewDate($('.pay-info-date-first'));
  changeViewDate($('.pay-info-date-second'));
  $(document).mouseup(function (e) {
    var container = $(".modal-container");
    if (container.has(e.target).length === 0){
        container.hide();
    }
  });

  $('.available-bonus').on('click', function() {
    $('.bonus-modal').show();
  });

  $('.close-modal').on('click', function() {
    $('.modal-box').hide();
  });

  $(document).ready(function () {
    // Сохраняем значение бонуса при загрузке страницы
    const bonusText = $('.available-bonus').clone();

    $("#urgent-payment").on("change", function () {
      let earnings = $('.model_earnings');
      const baseValue = parseFloat(earnings.data('value'));

      let balance;

      if ($("#urgent-payment").is(':checked')) {
        balance = (baseValue + parseFloat($('#currentEarnings').data('value'))).toFixed(2);
      } else {
        balance = baseValue.toFixed(2);
      }

      // Если баланс равен нулю, показываем просто "0$"
      if (balance == 0) {
        earnings.text('0$');
      } else {
        earnings.text(balance + '$');
      }

      // Восстанавливаем бонус после обновления текста баланса
      if (bonusText.length > 0) {
        earnings.append(bonusText);
      }
    });
  });
}
