(function() {
  const registerSecondStepSelector = "#register_second_step";
  const sendPasswordButtonSelector = "#ajax_send_new_pwd";
  const passwordUrl = "/user/ajaxsendpasswordagain";
  const countdownLabelSelector = ".lbl-cntdown-sec";
  const afterSendButtonSelector = ".after_send_again_button";
  const toastSettings = { heading: '', text: 'Ошибка отправки данных', showHideTransition: 'fade', icon: 'error' };
  const timerDuration = 300000; // 5 minutes in milliseconds
  const firstLoginButton = document.querySelector('.first-login-button');

  if (firstLoginButton) {
    firstLoginButton.addEventListener('click', function() {
      window.dataLayer.push({'event': 'first_login'});
    });
  }

  if ($(registerSecondStepSelector).length) {
    $(sendPasswordButtonSelector).click(sendPassword);

    function sendPassword(e) {
      e.preventDefault();
      let isReady = $(this).data('ready');
      if (!isReady) {
        return false;
      } else {
        var data = $(this).serialize();
        $.ajax({
          url: passwordUrl,
          type: 'POST',
          data: data,
          success: handleSuccess,
          error: handleError
        });
      }
    }

    function handleSuccess(res) {
      $(afterSendButtonSelector).show();
      $(sendPasswordButtonSelector).attr('disabled', true).data('ready', false);
      $(countdownLabelSelector).show();
      startTimer();
    }

    function handleError() {
      $.toast(toastSettings);
    }

    function startTimer() {
      var endTime = Date.now() + timerDuration;
      var timerId = setInterval(updateTimer, 1000);

      function updateTimer() {
        var remaining = endTime - Date.now();
        if (remaining <= 0) {
          clearInterval(timerId);
          $(sendPasswordButtonSelector).attr('disabled', false).data('ready', 'true');
          $(countdownLabelSelector).hide();
        } else {
          var minutes = Math.floor(remaining / 60000);
          var seconds = Math.floor((remaining % 60000) / 1000);
          $(countdownLabelSelector).text(minutes + ':' + (seconds < 10 ? '0' : '') + seconds);
        }
      }
    }

    startTimer();
  }
})();
