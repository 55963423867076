if ($("#information").length){

  $('#informationItem').addClass('current-page');

  $("#tabsReference").tabs();

  $("#tabsReference").tabs({
    active: 0
  });

  $('.block-with-dropdown').click(function(){
    $('.block-p', this).toggle();
    $('h3:after', this).css('transform', 'rotate(180deg)');
  });

  $('.help-information__tab').click(function() {
  	$('.help-information__tabs').scrollTop(0);
  });

  $('.show_all_punkts').click(function() {
    $('.mobile-menu-punkts').show();
  });

  $('.js-close-popup').click(function(){
    $('.mobile-menu-punkts').hide();
  });

  $('.mobile-show-window').on('click',function(e){
    e.preventDefault();
    let id = $(this).attr('href');
    $('.help-information__fill-content').hide();
    $(id).show();
    $('.mobile-menu-punkts').hide();
  });

  $(document).ready(function () {
    $.get({
      url: '/user/ajaxgetrclogin',
      success: function (respond) {
        console.log(respond);
        $('#chat-login').append(' - ' + respond);
      }
    });
  })
}