if ($(".news-update").length){



  $('.ajax_set_news_photo').change(function() {
    files = this.files;
    position = $(this).prop('name');
    //console.log($(this));
    if( typeof files == 'undefined' ) return;
    var data = new FormData();

    $.each( files, function( key, value ){
      data.append( key, value );
    });

    var id = window.location.search.replace( '?id=', '');
    data.append( 'id', id );
    $.ajax({
    url         : '/admin/news/ajaxsetnewsphoto',
    type        : 'POST', // важно!
    data        : data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        console.log(respond);


      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

    });
  });



}