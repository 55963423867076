// show date for leap-year
/*(function() {
  $("#finance-year").change(function(){
    showDateForLeapYear();
  })
  showDateForLeapYear();
})();
*/
function showDateForLeapYear() {
  let result = $("#finance-year").val();
  if(result !== undefined){
 	 let leap = moment(result+'-01-01').isLeapYear();
//	console.log(leap);
	   if(leap){
	//  if ((result === "2020") || (result === "2024")){
//	console.log(true);
	    $(".finance-period li:nth-child(4)").css("display", "none");
	    $(".finance-period li:nth-child(5)").css("display", "block");
	  }else{
//	console.log(false);
	    $(".finance-period li:nth-child(4)").css("display", "block");
	    $(".finance-period li:nth-child(5)").css("display", "none");
	  }
   }
}

/*(function() {
  $("#select-period-year").change(function(){
    showPeriodForLeapYear();
  })
  $("#select-period-year-second").change(function(){
    showSecondPeriodForLeapYear();
  })
  showPeriodForLeapYear();
})();
*/
function showPeriodForLeapYear() {
  let result = $("#select-period-year").val();
  if(result !== undefined){
	  let leap = moment(result+'-01-01').isLeapYear();
//	console.log(leap);
	   if(leap){
//	console.log(true);
	//  if (($result === "2020") || ($result === "2024")){
	//    $(".manager-main__period-select-date li:nth-child(4)").css("display", "none");
	//    $(".manager-main__period-select-date li:nth-child(5)").css("display", "block");
	      $(".first-period li:nth-child(4)").css("display", "none");
	      $(".first-period li:nth-child(5)").css("display", "block");
	  }else{
//	console.log(false);
	//    $(".manager-main__period-select-date li:nth-child(4)").css("display", "block");
	//    $(".manager-main__period-select-date li:nth-child(5)").css("display", "none");
	      $(".first-period li:nth-child(4)").css("display", "block");
	      $(".first-period li:nth-child(5)").css("display", "none");
	  }
   }
}

function showSecondPeriodForLeapYear() {
  let result = $("#select-period-year-second").val();
 if(result !== undefined){
	  let leap = moment(result+'-01-01').isLeapYear();
//	console.log(leap);
	   if(leap){
//	console.log(true);
	//    if (($result === "2020") || ($result === "2024")){
	      $(".second-period li:nth-child(4)").css("display", "none");
	      $(".second-period li:nth-child(5)").css("display", "block");
	    }else{
//	console.log(false);
	      $(".second-period li:nth-child(4)").css("display", "block");
	      $(".second-period li:nth-child(5)").css("display", "none");
	    }
 }
}


function showDatePlanForLeapYear() {
  let result = $("#select-period-year-second-plan").val();
 if(result !== undefined){
          let leap = moment(result+'-01-01').isLeapYear();
//      console.log(leap);
           if(leap){
//      console.log(true);
        //    if (($result === "2020") || ($result === "2024")){
              $(".plan-period li:nth-child(4)").css("display", "none");
              $(".plan-period li:nth-child(5)").css("display", "block");
            }else{
//      console.log(false);
              $(".plan-period li:nth-child(4)").css("display", "block");
              $(".plan-period li:nth-child(5)").css("display", "none");
            }
 }
}


//curent date
const date = new Date();
const currentDay = date.getDate();
let currentMonth = date.getMonth() + 1;
const currentYear = date.getFullYear();
let lastDayCurrentMonth = (new Date(currentYear, currentMonth, 0)).getDate();
let periodDay = 0;
if (currentDay <= 15){
  periodDay = '01';
  lastDayCurrentMonth = 15;
}else periodDay = 16;

if (currentMonth < 10 ){
  currentMonth = "0" + currentMonth;
}

const periodDate = currentMonth + "." + periodDay + "." + lastDayCurrentMonth;

$(document).ready(setTimeout(function() {

// show date for leap-year
(function() {
  $("#finance-year").change(function(){
    showDateForLeapYear();
  })
  showDateForLeapYear();
})();

(function() {
  $("#select-period-year").change(function(){
    showPeriodForLeapYear();
  })
  $("#select-period-year-second").change(function(){
    showSecondPeriodForLeapYear();
  })
  showPeriodForLeapYear();
  showSecondPeriodForLeapYear();
})();

(function(){
  $('#select-period-year-second-plan').change(function() {
      showDatePlanForLeapYear();
  });
  showDatePlanForLeapYear();
})();


/*  $(".select-year select option").each(function(){
    if ($(this).text() === currentYear.toString()){
      $(this).prop("selected", true);
      return false;
    }
  });

  $('#select-period-year-second-plan option').each(function() {
    if ($(this).text() === currentYear.toString()){
      $(this).prop("selected", true);
      return false;
    }
  });

  $(".select-year li").each(function(){
    $(this).removeClass('selected sel');
    if ($(this).text() === currentYear.toString()){
      $(".select-year .jq-selectbox__select-text").text(currentYear);
      $(this).addClass('selected sel');
//      showPeriodForLeapYear();
  //    showDateForLeapYear()
      return false;
    }
  });

  $(".select-year-second select option").each(function(){
    if ($(this).text() === currentYear.toString()){
      $(this).prop("selected", true);
      return false;
    }
  });

  $(".select-year-second li").each(function(){
    $(this).removeClass('selected sel');
    if ($(this).text() === currentYear.toString()){
     $(".select-year-second .jq-selectbox__select-text").text(currentYear);
      $(this).addClass('selected sel');
    //  showSecondPeriodForLeapYear();
      return false;
    }
  });

  $(".select-year-plan select option").each(function(){
    console.log('cur',currentYear.toString());
    console.log('this',$(this).text());
    if ($(this).text() === currentYear.toString()){
      $(this).prop("selected", true);
      return false;
    }
  });

  $(".select-year-plan li").each(function(){
    $(this).removeClass('selected sel');
    if ($(this).text() === currentYear.toString()){
      $(".select-year-plan .jq-selectbox__select-text").text(currentYear);
      $(this).addClass('selected sel');
      return false;
    }
  });
*/

   $(".select_set_current_date option").each(function(){
     const dateNow = $(this).attr("name");
//     if (dateNow.slice(0, 5) === periodDate){
     if (dateNow === periodDate){
       $("#period-select-date-plan-styler .jq-selectbox__select-text").text($(this).text());
       $(".began-period").text($(this).text());
       $(this).attr('selected', true);
       return false;
     }
   });


  $(".select_set_current_date option").each(function(){
     const dateNow = $(this).attr("name");
//     if (dateNow.slice(0, 5) === periodDate){
     if (dateNow === periodDate){
       $("#period-select-date-plan-singlemodel-page-styler .jq-selectbox__select-text").text($(this).text());
       $(".began-period").text($(this).text());
       $(this).attr('selected', true);
       return false;
     }
   });

  $("#period-select-date option").each(function(){
    const dateNow = $(this).attr("name");
//    if (dateNow.slice(0, 5) === periodDate){
     if (dateNow === periodDate){
	      $("#period-select-date-styler .jq-selectbox__select-text").text($(this).text());
	      $(".began-period").text($(this).text());
	      $(this).attr('selected', true);
	      return false;
    }
  });

  $("#period-select-date-styler .jq-selectbox__dropdown ul li").removeClass('selected sel');
  $("#period-select-date-styler .jq-selectbox__dropdown ul li").each(function(){
    const selectDate = $("#period-select-date-styler .jq-selectbox__select-text");
    if (selectDate.text() === $(this).text()){
      $(this).addClass('selected sel');
    }
  })



  $("#finance-period option").each(function(){
    const dateNow = $(this).attr("name");
//    if (dateNow.slice(0, 5) === periodDate){
     if (dateNow === periodDate){
      $("#finance-period-styler .jq-selectbox__select-text").text($(this).text());
      $(".began-period").text($(this).text());
      $(this).attr('selected', true);
      return false;
    }
  });
  $("#finance-period-styler .jq-selectbox__dropdown ul li").removeClass('selected sel');
  $("#finance-period-styler .jq-selectbox__dropdown ul li").each(function(){
    const selectDate = $("#finance-period-styler .jq-selectbox__select-text");
    if (selectDate.text() === $(this).text()){
      $(this).addClass('selected sel');
    }
  })
  $("#period-select-date-plan option").each(function(){
    if ($(this).attr("name") === periodDate){
      $("#period-select-date-plan-styler .jq-selectbox__select-text").text($(this).text());
      return false;
    }
  })

}, 800));
