//web-photo
var video;
var webcamStream;
var canvas, ctx;
var curPhoto;

stopMediaTracks = function() {
  try {
    webcamStream.getVideoTracks()[0].stop();
    $('#snapshot').hide();
    $('#applyCam').show();
  } catch(err) {
    console.log(err);
  }
}
startWebCam = function() {
  navigator.getUserMedia = ( navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia);
  $('#webcam-photo').show();
  $('#webcam-photo-can').hide();
  $('#applyCam').hide();
  $('#snapshot').show();
  $('#saveSnapshot').hide();
  if (navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia (
    {
      video: true,
      audio: false
    })
      .then(function (stream) {
        video = document.querySelector('video')
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          video.src = window.URL.createObjectURL(stream);
        }
        webcamStream = stream;
        
        video.onloadedmetadata = function(e) {
          video.play();
        };
      })
      .catch(function (e) {
        logError(e.name + ": " + e.message);
      });

    } else {
      console.log("getUserMedia not supported");
    }
}
$('.js-web-photo').click(function() {
  curPhoto = $(this);
});

$('#applyCam').click(startWebCam);

$('#snapshot').click(function() {
  canvas = document.getElementById("webcam-photo-can");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  stopMediaTracks();
  $('#webcam-photo').hide();
  $('#webcam-photo-can').show();
  $('#saveSnapshot').show();
  $('#snapshot').hide();
});
$('#saveSnapshot').click(function() {
  $('.modal-snapshot').hide();
  var img = canvas.toDataURL('image/jpg');
  img = img.replace('image/png', "image/octet-stream");
  //console.log(img);
  curPhoto.parent().children('div:nth-child(2)').hide();
  curPhoto.parent().find('.single_photo').find('img').attr('src', img);
  curPhoto.parent().find(".filebutton_ajax_send_image").hide();
  curPhoto.parent().find(".single_photo").show();
  stopMediaTracks();

  var photo_position = curPhoto.parent().find(".single_photo").children().children().prop('id');

  var data = new FormData();
  //console.log(test);

  data.append( 'url', img );
  data.append( 'position', photo_position );
  //console.log(data);

    $.ajax({
    url         : '/user/webcamajaxloadphoto',
    type        : 'POST',
    data 		: data,
    cache       : false,
    dataType    : 'json',
    // отключаем обработку передаваемых данных, пусть передаются как есть
    processData : false,
    // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
    contentType : false,
    // функция успешного ответа сервера
    success     : function( respond, status, jqXHR ){

      if( typeof respond.error === 'undefined' ){
        //console.log(respond);
        curPhoto.parent().find('.single_photo').find('img').attr('src', img);
      }
      // ошибка
      else {
        console.log('ОШИБКА: ' + respond.error );
      }
    },
    // функция ошибки ответа сервера
    error: function( jqXHR, status, errorThrown ){
      console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
    }

  });

});
//web-photo end