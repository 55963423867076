if ($("#admin-sattelit_articles").length){
	$('.ajax_remove_article').click(function() {
		let id = $(this).parent().parent().data('article');
		let data = new FormData();
    	data.append( 'id', id );
		$.ajax({
      	url         : '/admin/default/ajaxremovearticle',
      	type        : 'POST',
      	data        : data,
      	cache       : false,
      	dataType    : 'json',
      	// отключаем обработку передаваемых данных, пусть передаются как есть
      	processData : false,
      	// отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
      	contentType : false,
      	// функция успешного ответа сервера
      	success     : function( respond, status, jqXHR ){
	
      	  if( typeof respond.error === 'undefined' ){
      	      $.toast({
        		  heading: 'Success',
        		  text: 'Статья удалена',
        		  showHideTransition: 'slide',
        		  icon: 'success'
        		});
      	  }
      	  // ошибка
      	  else {
      	    console.log('ОШИБКА: ' + respond.error );
      	  }
      	},
      	// функция ошибки ответа сервера
      	error: function( jqXHR, status, errorThrown ){
      	  console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
      	   $.toast({
        	  heading: '',
        	  text: 'Произошла ошибка',
        	  showHideTransition: 'fade',
        	  icon: 'error'
        	});
      	}
	
      	});
	});



  $('.ajax_create_theme').click(function(){
    let newTheme = $('.ajax_theme_value').val();
    let data = new FormData();
    data.append( 'newTheme', newTheme );
    $.ajax({
        url         : '/admin/default/ajaxcreatetheme',
        type        : 'POST',
        data        : data,
        cache       : false,
        dataType    : 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData : false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType : false,
        // функция успешного ответа сервера
        success     : function( respond, status, jqXHR ){
  
          if( typeof respond.error === 'undefined' ){
              $.toast({
              heading: 'Success',
              text: 'Тема добавлена',
              showHideTransition: 'slide',
              icon: 'success'
            });
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.error );
          }
        },
        // функция ошибки ответа сервера
        error: function( jqXHR, status, errorThrown ){
          console.log( 'ОШИБКА AJAX запроса: ' + status, jqXHR );
           $.toast({
            heading: '',
            text: 'Произошла ошибка',
            showHideTransition: 'fade',
            icon: 'error'
          });
        }
  
        });
  })


                                      
}