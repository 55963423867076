if ($("#secondtab").length){

// Функция для киви потому что укр номер на 1 длиннее российского и маска сбрасывает значение в инпуте
function setValQiwi(num){
  console.log(num);
  if (num[1]==7 && num.length == 12) {
    $('.qiwi-num').val(num);
  }
}

$(document).on('focusout','.qiwi-num',function(e){
 let num = $(this).val();
 setTimeout(setValQiwi, 50, num);
});


  //payment system field mask
    const paymentInput = $(".field-secondtabform-payment_system_number input");
    const paymentInputOtherCard = $('.other-card');
    const paymentInputVisa = $('.visa-number');

    $('#radiocard').on('click', function(){
      $('.model_click_card').show();
      $('.payment_description').hide();
      $('.card_description').show();
      $('.for_card').hide();
      $("#secondtabform-payment_system_numberds").prop('required',false);
      $('.for_wallet').show();
      $("#secondtabform-payment_system_number").prop('required',true);
      paymentInput.addClass("visa-number");
      paymentInput.removeClass("other-card");
      paymentInput.prop("placeholder", "");
      paymentInput.val("");
      $('.visa-number').unmask();
      $(".pay_system img").removeClass("active-pay");
      $(this).parent().parent().find("img").addClass("active-pay");
    });

    $('#radioyandex').on('click', function(){
      $('.model_click_card').hide();
      $('.payment_description').hide();
      $('.yandex_description').show();
      $('.for_card').hide();
      $("#secondtabform-payment_system_number_card").prop('required',false);
      $('.for_wallet').show();
      $("#secondtabform-payment_system_number").prop('required',true);
      paymentInput.removeClass("visa-number");
      paymentInput.addClass("other-card");
      $('.other-card').mask('9999999999999', {"placeholder": ""});
      paymentInput.prop("placeholder", "4100143322850");
      paymentInput.val("");
      $(".pay_system img").removeClass("active-pay");
      $(this).parent().parent().find("img").addClass("active-pay");
    });

    $('#radioqiwi').on('click', function(){
      $('.model_click_card').hide();
      $('.payment_description').hide();
      $('.qiwi_description').show();
      $('.for_card').hide();
      $("#secondtabform-payment_system_number_card").prop('required',false);
      $('.for_wallet').show();
      $("#secondtabform-payment_system_number").prop('required',true);
      paymentInput.removeClass("visa-number");
      paymentInput.addClass("qiwi-num");
      $('.qiwi-num').mask('+999999999999', {clearIfNotMatch: false, "placeholder": ""});
      //$('.qiwi-num').mask('+999999999999', {clearIfNotMatch: false});
      paymentInput.prop("placeholder", "+79168885577");
      paymentInput.val("");
      $(".pay_system img").removeClass("active-pay");
      $(this).parent().parent().find("img").addClass("active-pay");
      $(".for_wallet").show();
    });

    $('#radiowebMoney').on('click', function(){
      $('.model_click_card').hide();
      $('.payment_description').hide();
      $('.webmoney_description').show();
      $('.for_card').hide();
      $("#secondtabform-payment_system_number_card").prop('required',false);
      $('.for_wallet').show();
      $("#secondtabform-payment_system_number").prop('required',true);
      paymentInput.removeClass("visa-number");
      paymentInput.addClass("other-card");
      $('.other-card').mask('a999999999999', {"placeholder": ""});
      paymentInput.prop("placeholder", "Z291143207289");
      paymentInput.val("");
      $(".pay_system img").removeClass("active-pay");
      $(this).parent().parent().find("img").addClass("active-pay");
      $(".for_wallet").show();

    });
  //show payment system and field
  // if ($("#secondtab"))
  function showPayUsd() {
    if ($("#radiousd").prop('checked'))
    {
      $(".web_money").show();
      $(".pay_visa").show();
      $(".yandex_money").hide();
      $(".pay_qivi").hide();
    }
  };

  function showPayRub() {
    if ($("#radiorub").prop('checked'))
    {
      $(".web_money").hide();
      $(".pay_visa").show();
      $(".yandex_money").show();
      $(".pay_qivi").show();
    }
  };

  function hideFieldPay() {
    $(".for_card").hide();
    $(".for_wallet").hide();
  }

  $("#radiorub").change(function() {
    showPayRub();
    $(".for_wallet").show();
  });

  $("#radiousd").change(function() {
    showPayUsd();
    $(".pay_system .outline-card.checked input").trigger('click');
    $(".for_wallet").show();
  });

  showPayUsd();

  showPayRub();

  $("#radiousd").click(function() {
    hideFieldPay();
  });

  $("#radiorub").click(function() {
    hideFieldPay();
  });

  if($("#radioyandex").prop('checked') || $("#radioqiwi").prop('checked') || $("#radiowebMoney").prop('checked') || $("#radiocard").prop('checked')){
    $(".for_wallet").show();
  }

  if ($("#secondtabform-email").prop('readonly')){
    $(".field-secondtabform-email label").addClass('active_label');
  }
  $(document).ready(setTimeout(function(){
    $(".outline-card.checked").parent().find("img").addClass("active-pay");
    if ($("#radiocard").prop('checked')){
      paymentInput.addClass("visa-number");
      $('.card_description').show();
      setTimeout(function(){
        $('.visa-number').unmask();
        paymentInput.prop("placeholder", "");
      },100)
      $('.model_click_card').css('display', 'block');
    }
    if ($("#radioyandex").prop('checked')){
      paymentInput.addClass("other-card");
      $('.yandex_description').show();
      setTimeout(function(){
        $('.other-card').mask('9999999999999');
        paymentInput.prop("placeholder", "4100143322850");
      },100)
    }
    if ($("#radioqiwi").prop('checked')){
      paymentInput.addClass("other-card");
      $('.qiwi_description').show();
      setTimeout(function(){
        paymentInputOtherCard.unmask();
      }, 100)
    }
    if ($("#radiowebMoney").prop('checked')){
      paymentInput.addClass("other-card");
      $('.webmoney_description').show();
      setTimeout(function(){
        paymentInputOtherCard.mask('A-Z999999999999');
        paymentInput.prop("placeholder", "Z291143207289");
      }, 100)
    }
  }, 300));

$('#secondtabform-payment_system_number').keyup(function() {
  if ($(this).val().length >=2) {
    if ($(this).val()[0] == '+' && $(this).val()[1] == '7') {
      if ($(this).val().length >= 13) {
        $(this).val($(this).val().substr(0, 12));
      }
    }
  }
});

}