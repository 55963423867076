if ($("#sattelit-articles").length){
	getArticles(0);
	getThemes();

	function getThemes(){
		let data = new FormData();
    	data.append( 'start', 'test' );
		$.ajax({
        	url         : '/user/ajaxgetallthemes',
        	type        : 'POST', // важно!
        	data        : data,
        	cache       : false,
        	dataType    : 'json',
        	// отключаем обработку передаваемых данных, пусть передаются как есть
        	processData : false,
        	// отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        	contentType : false,
        	// функция успешного ответа сервера
        	success     : function( respond, status, jqXHR ){
	
        	  if( typeof respond.error === 'undefined' ){
        	    //console.log(respond);
        	    renderThemes(respond);

        	  }
        	  // ошибка
        	  else {
        	    console.log('ОШИБКА: ' + respond.error );
        	  }
        	},
        	// функция ошибки ответа сервера
        	error: function( jqXHR, status, errorThrown ){
        	  console.log(jqXHR);
        }
    	});
	}

	function getArticles(start){
		const count = 4;
		let theme = $('.checked_theme').data('id');
		let data = new FormData();
    	data.append( 'start', start );
    	data.append( 'stop', count );
    	data.append( 'theme', theme );
    	let button = start + 4;
    	$('.show_more_articles').data('start', button);
    	$.ajax({
        	url         : '/user/ajaxgetfourarticles',
        	type        : 'POST', // важно!
        	data        : data,
        	cache       : false,
        	dataType    : 'json',
        	// отключаем обработку передаваемых данных, пусть передаются как есть
        	processData : false,
        	// отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        	contentType : false,
        	// функция успешного ответа сервера
        	success     : function( respond, status, jqXHR ){
	
        	  if( typeof respond.error === 'undefined' ){
        	    //console.log(respond);
        	    renderArticles(respond);

        	  }
        	  // ошибка
        	  else {
        	    console.log('ОШИБКА: ' + respond.error );
        	  }
        	},
        	// функция ошибки ответа сервера
        	error: function( jqXHR, status, errorThrown ){
        	  console.log(jqXHR);
        }
    	});
	}

	function renderThemes(respond) {
		for (var i = 0; i < respond.length; i++) {
			$('.sattelit-articles__filter_wrap').append(
				'<div class="sattelit-articles__single-filter ajax_filter_sattelit_articles" data-id ="'+respond[i].id+'">'+respond[i].theme+'</div>'
			);

		}
	}

	function renderArticles(respond){
		$('.sattelit-home__more-news-count').text(respond[0].rest_count);
		if (respond[0].rest_count == 0) {
			$('.show_more_articles').attr('disabled', true);
		}
		for (var i = 0; i < respond.length; i++) {
			$('.sattelit-articles__all-articles-wrap').append(
					'<div class="sattelit-articles__single-article">\
                        <div class="sattelit-articles__single-article-img-wrap">\
                            <img src="/'+respond[i].photo_url+'" alt="">\
                        </div>\
                        <div class="sattelit-articles__single-article-content-wrap">\
                            <h3>'+respond[i].title+'</h3>\
                            <p>'+respond[i].short_desc+'</p>\
                            <button class="sattelit-home__button-read-white"><a href= "/landing/article.php?id='+respond[i].id+'">Читать</a></button>\
                            <div class="sattelit-articles__single-article-date">20 Dec</div>\
                        </div>\
                    </div>'
				);
		}
	}

	$('.show_more_articles').click(function() {
		let start = $(this).data('start');
		getArticles(start);
	});

	$(document).on('click','.ajax_filter_sattelit_articles',function(e){
		$('.ajax_filter_sattelit_articles').css('background-color', '#fff');
		$('.ajax_filter_sattelit_articles').removeClass('checked_theme');
		$('.sattelit-articles__all-articles-wrap').html('');
		$(this).css('background-color', '#db046b');
		let themeId = $(this).data('id');
		$('.sattelit-articles__title-wrap').html('');
		$('.sattelit-articles__title-wrap').append(
				'<div class="sattelit-articles__title">Главная</div>\
                 <div class="sattelit-articles__title">Статьи</div>\
                 <div class="sattelit-articles__title">'+$(this).text()+'</div>'
			);
		$(this).addClass('checked_theme');
		getArticles(0);
	});

    $('.sattelit-articles__mobile-filter-button').click(function() {
        $('.sattelit-articles__filter_wrap').show();
    });
    $('.close-filter').click(function() {
        $('.sattelit-articles__filter_wrap').hide();
    });
}